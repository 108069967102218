<div class="flexRow mt-5">
  <div class="col-12" id="players">
    <!-- Small Color Circles  -->
    <div class="d-flex justify-content-between interactions">
      <h6><span class="interaction-number">935 </span> Total Interactions</h6>
      <div class="d-flex gap-3">
        <div class="circle-color-container d-flex gap-1">
          <div class="circle-color videos"></div>
          <span>Videos</span>
        </div>
        <div class="circle-color-container d-flex gap-1">
          <div class="circle-color views"></div>
          <span>Views</span>
        </div>
        <div class="circle-color-container d-flex gap-1">
          <div class="circle-color comments"></div>
          <span>Comments</span>
        </div>
        <div class="circle-color-container d-flex gap-1">
          <div class="circle-color likes"></div>
          <span>Likes</span>
        </div>
        <div class="circle-color-container d-flex gap-1">
          <div class="circle-color shares"></div>
          <span>Shares</span>
        </div>
      </div>
    </div>
    <!-- Player Chart  -->
    <canvas
      baseChart
      class="chart"
      [data]="barChartData"
      [options]="barChartOptions"
      [type]="barChartType"
    >
    </canvas>
  </div>
</div>
