import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartData, ChartType } from 'chart.js';

import { DashBordEndPointsService } from 'src/app/services/dash-bord-end-points.service';

@Component({
  selector: 'app-attribute-progress',
  templateUrl: './attribute-progress.component.html',
  styleUrls: ['./attribute-progress.component.scss'],
})
export class AttributeProgressComponent implements OnInit {
  @Input() from: any;
  @Input() to: any;
  @Input() teamId: any;
  @Input() userID: any;
  @Input() type: any;
  @Input() ArrayOfData: any;
  tableData: any;
  chartData: any;

  constructor(private _DashBordEndPointsService: DashBordEndPointsService) {}

  ngOnInit(): void {
    this._DashBordEndPointsService
      .getCollectivePlayerEvaluationAttributes(
        this.userID,
        this.type,
        this.from,
        this.to
      )
      .subscribe((res: any) => {
        this.tableData = res.results;
      });

    this._DashBordEndPointsService
      .getEvaluationAttributesCharts(this.userID, this.type, this.from, this.to)
      .subscribe((res: any) => {
        this.chartData = res;
      });
  }

  mapData(data: [any], chartName: any) {
    let temp = [1];
    temp.pop();
    data.map((e) => {
      temp.push(Math.trunc(e.value));
    });
    this.tableData.map((e: any) => {
      if (e.evaluation_attribute.name == chartName) {
        temp.push(Math.trunc(e.rate));
      }
    });
    return temp;
  }

  enhanceChartData(data: any) {
    if (data.evaluations.length == 1) {
      console.log(data, 'dataOld');

      let startTempDate = new Date(
        this.from.substring(0, 4),
        this.from.substring(5, 7),
        this.from.substring(8, 10)
      );
      let tempdata = data.evaluations.unshift({
        date: startTempDate.toJSON(),
        value: data.evaluations[0].value,
      });
      console.log(tempdata, 'tempdata');
      console.log(this.from, 'from');
    }
    return data;
  }

  shouldPageBreak(index: number): boolean {
    // Assuming you want a page break after every 3 graphs
    return (index + 1) % 9 === 0;
  }
}
