import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-period-selection-bar',
  templateUrl: './period-selection-bar.component.html',
  styleUrls: ['./period-selection-bar.component.scss']
})
export class PeriodSelectionBarComponent implements OnInit {
  @Input() periods: any = [
    { text: '1 Year', isActive: false, value: "year" },
    { text: '3 Months', isActive: true, value: "three_month" },
    { text: '1 Month', isActive: false, value: "month" },
    { text: '1 Week', isActive: false, value: "week" },
  ]
  @Input() isUserChart: Boolean = false;

  @Output('periodChange') periodChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
    this.periodChange.emit("three_month");
  }

  activate(index: number) {
    this.periods = this.periods.map((item: any) => { return { ...item, isActive: false } })
    this.periods[index].isActive = true;
    this.periodChange.emit(this.periods[index]["value"])
  }

}
