<h2 class="mb-5 ms-4 top-achievers-header-text">
  Top achievers / players / Player Stats /
  <span>
    {{ tableData[0].collective_attribute_set[0].evaluation_attribute.name }}
  </span>
</h2>
<div *ngFor="let record of tableData; let i = index">
  <!-- display goalkeeper only in goalkeeper attr -->
  <div
    *ngIf="catId == 5 && record.primary_position == 'GK'"
    class="row card flex-row shadow mb-2"
  >
    <div class="col-3 p-0 m-0 overflow">
      <div class="indexing">{{ i + 1 }}</div>
      <div *ngIf="record.picture">
        <img style="object-fit: contain" [src]="record.picture" />
      </div>
      <div *ngIf="!record.picture">
        <img style="object-fit: contain" src="/assets/images/playeruser.jpg" />
      </div>
    </div>
    <div class="col-4">
      <h1>{{ record.username }}</h1>
      <h2 class="strike-text">{{ record.academy }}</h2>
      <h2 class="selection-text">{{ record.team }}</h2>
      <div class="line"></div>
      <div class="d-flex flex-row gap-4">
        <div>
          <h6 class="position-text">Position</h6>
          <p class="age-text">
            {{ record.primary_position }}
          </p>
        </div>
        <div>
          <h6 class="position-text">Age</h6>
          <p class="age-text">{{ record.age ? record.age : "10" }}</p>
        </div>
      </div>
      <div class="line"></div>
      <p class="mt-3 top-achiever-text">
        Top achievers / player / Stats /
        {{ tableData[0].collective_attribute_set[0].evaluation_attribute.name }}
      </p>
      <div class="d-flex flex-row gap-2 pb-4">
        <img
          *ngIf="record.collective_attribute_set[0].evaluation_attribute.name"
          [src]="
            'assets/icons/' +
            record.collective_attribute_set[0].evaluation_attribute.name +
            '.png'
          "
          style="width: 53px"
          alt="Passing"
        />

        <div class="d-flex align-items-center gap-2">
          <span class="number-count-text">
            {{ record.collective_attribute_set[0].rate }}
          </span>
          <span class="stats-text">
            {{ record.collective_attribute_set[0].evaluation_attribute.name }}
          </span>
        </div>
      </div>
    </div>
    <div class="col-5" *ngIf="record.primary_position">
      <img
        style="object-fit: contain"
        class=""
        [src]="'assets/images/' + record.primary_position + '.svg'"
        alt="{{ record.primary_position }}"
      />
    </div>
    <div class="col-5" *ngIf="!record.primary_position">
      <img
        style="object-fit: contain"
        class=""
        src="assets/images/stadium.png"
        alt="stadium"
      />
    </div>
  </div>

  <!-- display all payers except goal keepers -->
  <div
    *ngIf="catId != 5 && record.primary_position != 'GK'"
    class="row card flex-row shadow mb-2"
  >
    <div class="col-3 p-0 m-0 overflow">
      <div class="indexing">{{ i + 1 }}</div>
      <div *ngIf="record.picture">
        <img style="object-fit: contain" [src]="record.picture" />
      </div>
      <div *ngIf="!record.picture">
        <img style="object-fit: contain" src="/assets/images/playeruser.jpg" />
      </div>
    </div>
    <div class="col-4">
      <h1>{{ record.username }}</h1>
      <h2 class="strike-text">{{ record.academy }}</h2>
      <h2 class="selection-text">{{ record.team }}</h2>
      <div class="line"></div>
      <div class="d-flex flex-row gap-4">
        <div>
          <h6 class="position-text">Position</h6>
          <p class="age-text">
            {{ record.primary_position }}
          </p>
        </div>
        <div>
          <h6 class="position-text">Age</h6>
          <p class="age-text">{{ record.age ? record.age : "10" }}</p>
        </div>
      </div>
      <div class="line"></div>
      <p class="mt-3 top-achiever-text">
        Top achievers / player / Stats /
        {{ tableData[0].collective_attribute_set[0].evaluation_attribute.name }}
      </p>
      <div class="d-flex flex-row gap-2 pb-4">
        <img
          *ngIf="record.collective_attribute_set[0].evaluation_attribute.name"
          [src]="
            'assets/icons/' +
            record.collective_attribute_set[0].evaluation_attribute.name +
            '.png'
          "
          style="width: 53px"
          alt="Passing"
        />

        <div class="d-flex align-items-center gap-2">
          <span class="number-count-text">
            {{ record.collective_attribute_set[0].rate }}
          </span>
          <span class="stats-text">
            {{ record.collective_attribute_set[0].evaluation_attribute.name }}
          </span>
        </div>
      </div>
    </div>
    <div class="col-5" *ngIf="record.primary_position">
      <img
        style="object-fit: contain"
        class=""
        [src]="'assets/images/' + record.primary_position + '.svg'"
        alt="{{ record.primary_position }}"
      />
    </div>
    <div class="col-5" *ngIf="!record.primary_position">
      <img
        style="object-fit: contain"
        class=""
        src="assets/images/stadium.png"
        alt="stadium"
      />
    </div>
  </div>
</div>
