<!-- <div class="row justify-content-around mb-3 printView">
  <img [src]="academyLogo" style="width: 100px" />
  <img src="assets/images/scoutxLogoTran.png" style="width: 200px" />
</div> -->

<app-report-popover
  class="form"
  (newItemEvent)="reportProp($event)"
  *ngIf="!reportTypePreview"
>
</app-report-popover>
<!--  -->
<div class="main bg-white" *ngIf="reportTypePreview">
  <div class="notPrintView row mb-5 mx-3 notPrintView">
    <img
      src="assets/icons/backArrow.png"
      (click)="back()"
      class="align-self-center pointer"
      style="width: 50px"
    />
    <img
      src="assets/icons/printIcon.png"
      (click)="print()"
      style="width: 50px; margin-left: auto"
      class="pointer"
    />
  </div>
  <!-- <div class="notPrintView row mb-5 mx-3">
    <img
      src="assets/images/scoutxLogoTran.png"
      class="align-self-center pointer"
      style="width: 220px"
    />
    <img
      src="assets/icons/strikeLogo.png"
      style="margin-left: auto; width: 125px"
      class="pointer"
    />
  </div> -->
  <div class="row" *ngIf="ArrayOfData[0] != 'Academy report'">
    <h6 class="page-title mb-3" *ngIf="ArrayOfData[0] == 'Performance'">
      Player Reports/{{ ArrayOfData[0] }}/{{ ArrayOfData[4]
      }}{{
        ArrayOfData[4] == "Attribute progress"
          ? "/" + attributes[ArrayOfData[12]]
          : ""
      }}
    </h6>
    <h6 class="page-title mb-3" *ngIf="ArrayOfData[0] == 'Attendance'">
      Player Reports/{{ ArrayOfData[0] }}/{{ ArrayOfData[2] }}
    </h6>
    <div class="col-3">
      <img src="assets/images/playeruser.jpg" *ngIf="!reportPlayer?.picture" />
      <img [src]="reportPlayer?.picture" *ngIf="reportPlayer?.picture" />
    </div>
    <div class="col-4 player-info-data">
      <h1>{{ reportPlayer?.first_name }} {{ reportPlayer?.last_name }}</h1>
      <h4 *ngIf="reportPlayer.academy?.name">
        {{ reportPlayer.academy?.name }}
      </h4>
      <h6>{{ reportPlayer?.team_obj?.name }}</h6>
      <div
        class="line"
        *ngIf="reportPlayer.primary_position || reportPlayer.age"
      ></div>
      <div class="d-flex flex-row gap-4 my-1">
        <div>
          <p class="position-text">Position</p>
          <h6 class="position-field">
            {{
              reportPlayer.primary_position
                ? reportPlayer.primary_position
                : "-"
            }}
          </h6>
        </div>
        <div>
          <p class="position-text">Age</p>
          <h6 class="position-field">
            {{ reportPlayer.age ? reportPlayer.age : "-" }}
          </h6>
        </div>
      </div>
      <div class="line"></div>

      <p class="report-text mt-3" *ngIf="ArrayOfData?.[0] == 'Attendance'">
        {{ ArrayOfData[0] }} Report - {{ ArrayOfData[2] }} Statistics
      </p>
      <p class="report-text mt-3" *ngIf="ArrayOfData?.[0] == 'Performance'">
        Performance Report - {{ attributes[ArrayOfData[12]] }}
        {{ArrayOfData?.[4]}}
      </p>
      <div class="date d-flex flex-row gap-3">
        <div>
          <h6 class="date-text">
            <span class="orange">From</span>
            {{ ArrayOfData[6] }}
          </h6>
        </div>
        <div>
          <h6 class="date-text">
            <span class="orange">To</span>
            {{ ArrayOfData[7] }}
          </h6>
        </div>
      </div>

      <!-- <h1>{{ reportPlayer.username }}</h1>
      <h4>{{ reportPlayer.academy.name }}</h4>
      <h3>Team</h3>
      <h4>{{ reportPlayer.team_obj.name }}</h4>
      <h3>Position</h3>
      <h4>{{ reportPlayer.primary_position }}</h4>
      <h3>Age</h3>
      <h5>{{ reportPlayer.age }} yrs</h5> -->
    </div>

    <div class="col-5">
      <!-- <h4>
        {{ ArrayOfData[1] }} Report<span
          *ngIf="ArrayOfData[1] == 'Performance'"
        >
          - {{ ArrayOfData[3] }}</span
        >
      </h4>
      <h3>From</h3>
      <h6>
        <span>{{ ArrayOfData[5] }}</span>
      </h6>
      <h3>To</h3>
      <h6>
        <span>{{ ArrayOfData[6] }} </span>
      </h6> -->
      <img
        src="assets/images/{{ reportPlayer.primary_position }}.svg"
        alt="{{ reportPlayer.primary_position }}"
      />
      <img
        style="object-fit: contain"
        class=""
        src="assets/images/stadium.png"
        alt="stadium"
        *ngIf="reportPlayer.primary_position == null"
      />
    </div>
  </div>

  <!-- Players Reports/Performance/ Matches Statistics -->
  <app-player-report-match-statistics
    *ngIf="ArrayOfData[4] == 'Matches Statistics'"
    [position]="reportPlayer.primary_position"
    [from]="ArrayOfData[6]"
    [to]="ArrayOfData[7]"
    [teamId]="ArrayOfData[8]"
    [userID]="ArrayOfData[9]"
    [tournament]="ArrayOfData[13]"
  ></app-player-report-match-statistics>

  <!-- Players Reports/Performance/ Coaches Feedback -->

  <app-attendance
    *ngIf="ArrayOfData[0] == 'Attendance'"
    [attendanceType]="ArrayOfData[2]"
    [from]="ArrayOfData[6]"
    [to]="ArrayOfData[7]"
    [teamId]="ArrayOfData[8]"
    [userID]="ArrayOfData[9]"
    [tournament]="ArrayOfData[13]"
  ></app-attendance>

  <!-- <app-match-statistics
    *ngIf="ArrayOfData[4] == 'Matches Statistics'"
    [position]="reportPlayer.primary_position"
    [from]="ArrayOfData[6]"
    [to]="ArrayOfData[7]"
    [teamId]="ArrayOfData[8]"
    [userID]="ArrayOfData[9]"
  ></app-match-statistics> -->

  <app-attribute-progress
    *ngIf="ArrayOfData[4] == 'Attribute progress'"
    [type]="ArrayOfData[12]"
    [from]="ArrayOfData[6]"
    [to]="ArrayOfData[7]"
    [teamId]="ArrayOfData[8]"
    [userID]="ArrayOfData[9]"
    [ArrayOfData]="ArrayOfData"
  ></app-attribute-progress>

  <app-coaches-feedback
    *ngIf="
      ArrayOfData[4] == 'Coaches Feedback' && ArrayOfData[0] == 'Performance'
    "
    [from]="ArrayOfData[6]"
    [to]="ArrayOfData[7]"
    [userID]="ArrayOfData[9]"
  ></app-coaches-feedback>

  <app-academy-users-players
    *ngIf="
      ArrayOfData[0] == 'Academy report' &&
      ArrayOfData[1] == 'Academy users' &&
      ArrayOfData[2] == 'players'
    "
    [gender]="ArrayOfData[4]"
    [teamID]="ArrayOfData[5]"
  >
  </app-academy-users-players>

  <app-academy-users-parents
    *ngIf="
      ArrayOfData[0] == 'Academy report' &&
      ArrayOfData[1] == 'Academy users' &&
      ArrayOfData[2] == 'Parents'
    "
    [teamID]="ArrayOfData[5]"
  ></app-academy-users-parents>

  <app-academy-coaches
    *ngIf="
      ArrayOfData[0] == 'Academy report' && ArrayOfData[1] == 'Academy coaches'
    "
    [coachID]="ArrayOfData[6]"
  ></app-academy-coaches>

  <app-academy-teams
    *ngIf="
      ArrayOfData[0] == 'Academy report' && ArrayOfData[1] == 'Academy teams'
    "
    [type]="ArrayOfData[7]"
    [team]="ArrayOfData[5]"
  >
  </app-academy-teams>

  <app-stats-achivers
    *ngIf="
      ArrayOfData[0] == 'Academy report' &&
      ArrayOfData[1] == 'Achievers' &&
      ArrayOfData[8] == 'players' &&
      ArrayOfData[9] == 'stats'
    "
    [stats]="ArrayOfData[10]"
    [team]="ArrayOfData[5]"
  ></app-stats-achivers>

  <app-rate-achivers
    *ngIf="
      ArrayOfData[0] == 'Academy report' &&
      ArrayOfData[1] == 'Achievers' &&
      ArrayOfData[8] == 'players' &&
      ArrayOfData[9] == 'rating'
    "
    [team]="ArrayOfData[5]"
  ></app-rate-achivers>

  <app-social-achivers
    *ngIf="
      ArrayOfData[0] == 'Academy report' &&
      ArrayOfData[1] == 'Achievers' &&
      ArrayOfData[8] == 'players' &&
      ArrayOfData[9] == 'social'
    "
    [social]="ArrayOfData[11]"
    [team]="ArrayOfData[5]"
  ></app-social-achivers>

  <app-player-attr-achievers
    *ngIf="
      ArrayOfData[0] == 'Academy report' &&
      ArrayOfData[1] == 'Achievers' &&
      ArrayOfData[8] == 'players' &&
      ArrayOfData[9] == 'attributes'
    "
    [catId]="ArrayOfData[13]"
    [attrID]="ArrayOfData[14]"
    [team]="ArrayOfData[5]"
  ></app-player-attr-achievers>

  <app-coaches-achievers
    *ngIf="
      ArrayOfData[0] == 'Academy report' &&
      ArrayOfData[1] == 'Achievers' &&
      ArrayOfData[8] == 'coaches'
    "
    [type]="ArrayOfData[12]"
  ></app-coaches-achievers>
</div>
