<div>
  <app-breadcrumb
    [breadcrumbItems]="breadcrumb"
    title="Individual Player Reports - {{ ReportType }}"
  ></app-breadcrumb>
</div>
<div class="report bg-white px-5">
  <div class="">
    <h1 class="mt-5 mb-4">Report type</h1>
    <mat-radio-group aria-label="Select an option" [(ngModel)]="ReportType">
      <mat-radio-button value="Attendance">Attendance</mat-radio-button>
      <mat-radio-button value="Performance">Performance</mat-radio-button>
    </mat-radio-group>
  </div>

  <div *ngIf="ReportType == 'Attendance'" class="playerRep">
    <div
      *ngIf="ReportDetails == 'Attendance'"
      class="mt-5 d-flex flex-column gap-4"
    >
      <h1>Attendance Category*</h1>
      <select
        name="attendanceCategory"
        id="attendanceCategory"
        [(ngModel)]="attendanceCategory"
        class="mt-2"
      >
        <option value="Select" disabled selected>Select</option>
        <option value="match">Match</option>
        <option value="training">Training</option>
      </select>

      <select
        *ngIf="attendanceCategory == 'match'"
        name="attendanceCategoryTwo"
        id="attendanceCategoryTwo"
        [(ngModel)]="selectedTournaments"
        class="mt-1"
      >
        <option value="" selected>All Tournament</option>
        <option
          *ngFor="let tournaments of allTournaments"
          [value]="tournaments.id"
        >
          {{ tournaments?.name }}
        </option>
      </select>
    </div>

    <div *ngIf="ReportDetails == 'Performance'">
      <h1>Development area*</h1>
      <select name="cars" id="cars" [(ngModel)]="developmentArea">
        <option value="" disabled selected>Select</option>
        <option value="Attribute progress">Attribute progress</option>
        <option value="Coaches Feedback">Coaches Feedback</option>
        <option value="Matches Statistics">Matches Statistics</option>
      </select>
    </div>

    <div class="date mt-5">
      <h1>Select Date*</h1>
      <div class="d-flex w-50 gap-5 mt-4">
        <div
          class="w-50 d-flex justify-content-center h-100 gap-2 align-items-center"
        >
          <p>From</p>
          <input placeholder="from" type="date" [(ngModel)]="fromTime" />
        </div>
        <div
          class="w-50 d-flex justify-content-center h-100 gap-2 align-items-center"
        >
          <p>To</p>
          <input placeholder="to" type="date" [(ngModel)]="toTime" />
        </div>
      </div>
    </div>

    <div class="mt-5">
      <h1>Select team*</h1>
      <select
        name="Team"
        id="Team"
        [(ngModel)]="selectedTeam"
        (change)="importPlayers()"
        class="mt-3"
      >
        <option value="" disabled selected>Team name</option>
        <option *ngFor="let team of teams" [value]="team.id">
          {{ team?.name }}
        </option>
      </select>
    </div>

    <div *ngIf="selectedTeam" class="mt-5">
      <h1>Select Player*</h1>
      <select
        name="player"
        id="player"
        [(ngModel)]="selectedPlayer"
        (change)="importAttributeCategory()"
      >
        <option value="" disabled selected>Player name</option>
        <option
          *ngFor="let player of players"
          [value]="player.id + ',' + player.primary_position"
        >
          {{ player?.username }}
        </option>
      </select>
    </div>

    <div
      *ngIf="
        developmentArea == 'Attribute progress' &&
        ReportDetails == 'Performance' &&
        selectedPlayer
      "
    >
      <h1>Development Area*</h1>
      <select
        name="attributeCategory"
        id="attributeCategory"
        [(ngModel)]="attributeCategory"
      >
        <option value="" disabled selected>Select</option>
        <option
          [value]="category.id"
          *ngFor="let category of evaluationAttributeCategories"
        >
          {{ category.name }}
        </option>
      </select>
      <div *ngIf="attributeCategory">
        <h1>Select Tournament</h1>

        <select
          name="attributeCategory"
          id="attributeCategory"
          [(ngModel)]="attributeCategory"
        >
          <option value="" disabled selected>Select</option>
          <option
            [value]="category.id"
            *ngFor="let category of evaluationAttributeCategories"
          >
            {{ category.name }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div *ngIf="ReportType == 'Performance'" class="playerRep">
    <div class="mt-5">
      <h1>Development Area*</h1>
      <select name="cars" id="cars" class="mt-2" [(ngModel)]="developmentArea">
        <option value="Select" disabled selected>Select</option>
        <option value="Attribute progress">Attribute progress</option>
        <option value="Coaches Feedback">Coaches Feedback</option>
        <option value="Matches Statistics">Matches Statistics</option>
      </select>
    </div>

    <div class="mt-5" *ngIf="developmentArea == 'Attribute progress'">
      <h1>Select Attributes</h1>

      <select name="stats" id="stats" [(ngModel)]="achieveSelection">
        <option value="Select" disabled selected>Select</option>
        <!-- <option value="all">All</option> -->
        <option
          *ngFor="let attr of achieverSelectionAttributes"
          [value]="attr.id"
        >
          {{ attr.name }}
        </option>
      </select>
    </div>

    <div
      *ngIf="developmentArea == 'Matches Statistics'"
      class="mt-5 d-flex flex-column gap-4"
    >
      <h1>Select Tournament*</h1>
      <select
        name="attendanceCategory"
        id="attendanceCategory"
        class="mt-2"
        [(ngModel)]="selectedTournaments"
      >
        <option value="" selected>All Tournament</option>
        <option
          *ngFor="let tournaments of allTournaments"
          [value]="tournaments.id"
        >
          {{ tournaments?.name }}
        </option>
      </select>
    </div>

    <div class="date mt-5">
      <h1>Select dates*</h1>
      <div class="d-flex w-50 gap-5 mt-4">
        <div
          class="w-50 d-flex justify-content-center h-100 gap-2 align-items-center"
        >
          <p>From</p>
          <input placeholder="from" type="date" [(ngModel)]="fromTime" />
        </div>
        <div
          class="w-50 d-flex justify-content-center h-100 gap-2 align-items-center"
        >
          <p>To</p>
          <input placeholder="to" type="date" [(ngModel)]="toTime" />
        </div>
      </div>
    </div>

    <div class="mt-5">
      <h1>Select team*</h1>
      <select
        name="Team"
        id="Team"
        [(ngModel)]="selectedTeam"
        (change)="importPlayers()"
        class="mt-3"
      >
        <option value="" disabled selected>Team name</option>
        <option *ngFor="let team of teams" [value]="team.id">
          {{ team?.name }}
        </option>
      </select>
    </div>

    <div *ngIf="selectedTeam" class="mt-5">
      <h1>Select Player*</h1>
      <select
        name="player"
        id="player"
        [(ngModel)]="selectedPlayer"
        (change)="importAttributeCategory()"
        class="mt-2"
      >
        <option value="" disabled selected>Player name</option>
        <option
          *ngFor="let player of players"
          [value]="player.id + ',' + player.primary_position"
        >
          {{ player?.username }}
        </option>
      </select>
    </div>

    <div
      *ngIf="
        developmentArea == 'Attribute progress' &&
        ReportDetails == 'Performance' &&
        selectedPlayer
      "
    >
      <h1>Attribute category*</h1>
      <select
        name="attributeCategory"
        id="attributeCategory"
        [(ngModel)]="attributeCategory"
      >
        <option value="" disabled selected>Select</option>
        <option
          [value]="category.id"
          *ngFor="let category of evaluationAttributeCategories"
        >
          {{ category.name }}
        </option>
      </select>
    </div>
  </div>

  <div class="mt-4">
    <button (click)="viewReport()">View report</button>
  </div>

  <div class="px-5 mt-5" id="error">
    <p>Warning</p>
    <p>Please fill the required fields marked with an *</p>
  </div>
</div>
