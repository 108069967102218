<div class="row mb-4 justify-content-around">
  <div class="row mb-4 col-12">
    <h6>
      <!-- {{ academyName }}  elmfrod yb2a kda (Academy Reports/Teams/ Trainings) -->
      Academy Reports/Teams/
      <span *ngIf="team"> {{ dataTable[0].team_name + "/" }} </span>
      <span> {{ type == "training" ? "Training" : "Matches" }} </span>
    </h6>
  </div>

  <div *ngIf="team" class="col-3">
    <h4>
      <span class="green">{{ dataTable[0].team_name }}</span>
    </h4>
    <h5>Coach name :{{ dataTable[0].coach_name }}</h5>
  </div>

  <div
    class="card d-flex justify-content-between align-items-center flex-row px-4"
    *ngIf="team && type != 'training'"
  >
    <div>
      <h1>{{ dataTable[0].number_of_players }}</h1>
      <h2>Number of players</h2>
    </div>
    <div>
      <img
        src="assets/icons/blackClock.png"
        alt="blackClock"
        style="width: 77px"
      />
    </div>
  </div>

  <div
    class="card col-4 d-flex justify-content-between align-items-center flex-row px-4"
    *ngIf="team"
  >
    <div>
      <h1>
        <span class="">{{
          type == "training" ? avgTrainingRate : avgMatchgRate
        }}</span>
      </h1>
      <h2><span class="">Coach rate</span></h2>
    </div>
    <div>
      <img
        src="assets/icons/coachRate.png"
        alt="coachRate"
        style="width: 77px"
      />
    </div>
  </div>

  <div
    class="card mt-4 col-4 matches-played d-flex justify-content-between align-items-center flex-row px-4"
    *ngIf="team && type != 'training'"
  >
    <div>
      <h1>
        <span class="">{{ matchesNumber }}</span>
      </h1>
      <h2><span class="">Matches Played</span></h2>
    </div>
    <div>
      <img
        src="assets/icons/Matches Played.png"
        alt="Matches Played"
        style="width: 60px"
      />
    </div>
  </div>

  <div
    class="col-8 mt-4 matches-played row flex-row px-4 justify-content-between pr-1 pl-2"
    *ngIf="team && type != 'training'"
  >
    <div
      class="card col-4 matches-played d-flex justify-content-between align-items-center flex-row px-4 custom-width"
    >
      <div>
        <h1>
          <span class="">{{ numberOfWins }}</span>
        </h1>
        <h2><span class="">Wins</span></h2>
      </div>
      <div>
        <img
          src="assets/icons/Matches Played.png"
          alt="Matches Played"
          style="width: 60px"
        />
      </div>
    </div>

    <div
      class="card col-4 matches-played d-flex justify-content-between align-items-center flex-row px-4 custom-width"
    >
      <div>
        <h1>
          <span class="">{{ numberOfLoses }}</span>
        </h1>
        <h2><span class="">Losses</span></h2>
      </div>
      <div>
        <img
          src="assets/icons/Matches Played.png"
          alt="Matches Played"
          style="width: 60px"
        />
      </div>
    </div>

    <div
      class="card col-4 matches-played d-flex justify-content-between align-items-center flex-row px-4 custom-width"
    >
      <div>
        <h1>
          <span class="">{{ numberOfDraws }}</span>
        </h1>
        <h2><span class="">Draws</span></h2>
      </div>
      <div>
        <img
          src="assets/icons/Matches Played.png"
          alt="Matches Played"
          style="width: 60px"
        />
      </div>
    </div>
  </div>

  <div
    class="card uniqBackGround d-flex justify-content-between align-items-center flex-row px-4 col-4"
    *ngIf="type == 'training' && !team"
  >
    <div>
      <h1>{{ type == "training" ? trainigsNumber : matchesNumber }}</h1>
      <h2>
        {{ type == "training" ? "Number of trainings" : "Number of matches" }}
      </h2>
    </div>
    <div>
      <img
        src="assets/icons/blackClock.png"
        alt="blackClock"
        style="width: 77px"
      />
    </div>
  </div>
  <div
    class="card uniqBackGround d-flex justify-content-between align-items-center flex-row px-4 col-4"
    *ngIf="team && type == 'training'"
  >
    <div>
      <h1>{{ type == "training" ? trainigsNumber : matchesNumber }}</h1>
      <h2>
        {{ type == "training" ? "Trainings" : "Matches played" }}
      </h2>
    </div>
    <div>
      <img
        src="assets/icons/blackClock.png"
        alt="blackClock"
        style="width: 77px"
      />
    </div>
  </div>

  <div class="card" *ngIf="!team">
    <div
      class="d-flex justify-content-between align-items-center flex-row px-4"
    >
      <div>
        <h1>{{ boysTeams }}</h1>
        <h2>Boys Team</h2>
      </div>
      <div>
        <img
          src="assets/icons/boysTeam.png"
          alt="blackClock"
          style="width: 77px"
        />
      </div>
    </div>
  </div>

  <div
    class="card d-flex justify-content-between align-items-center flex-row px-4"
    *ngIf="!team"
  >
    <div>
      <h1>{{ girlsTeams }}</h1>
      <h2>Girls Team</h2>
    </div>

    <div>
      <img
        src="assets/icons/girlsTeam.png"
        alt="blackClock"
        style="width: 77px"
      />
    </div>
  </div>
  <div
    class="card uniqBackGround d-flex justify-content-between align-items-center flex-row px-4 col-4"
    *ngIf="type != 'training' && !team"
  >
    <div>
      <h1>{{ type == "training" ? trainigsNumber : matchesNumber }}</h1>
      <h2>
        {{ type == "training" ? "Number of trainings" : "No. of matches" }}
      </h2>
    </div>
    <div>
      <img
        src="assets/icons/blackClock.png"
        alt="blackClock"
        style="width: 77px"
      />
    </div>
  </div>
</div>

<div class="row" *ngIf="team">
  <table>
    <tr>
      <th>Date</th>
      <th *ngIf="!team">Team name</th>
      <th *ngIf="!team">Gender</th>
      <th *ngIf="!team">Coach</th>
      <th *ngIf="!team">No. of players</th>
      <th *ngIf="type != 'training'">Opponent</th>
      <th *ngIf="type != 'training'">Tournament</th>
      <th *ngIf="type != 'training'">Result</th>
      <th *ngIf="type != 'training'">Score</th>
      <th *ngIf="type == 'training'">Location</th>
      <th>Coach rating</th>
      <th *ngIf="type == 'training'">Attendance</th>
    </tr>
    <tr *ngFor="let record of dataTable" class="printDesign">
      <td class="date">{{ record.date }}</td>
      <td class="teamName" *ngIf="!team">{{ record.team_name }}</td>
      <td class="teamName" *ngIf="!team">{{ record.team_gender }}</td>
      <td class="teamName" *ngIf="!team">{{ record.coach_name }}</td>
      <td class="teamName" *ngIf="!team">{{ record.number_of_players }}</td>
      <td class="opponTeam" *ngIf="type != 'training'">
        {{ record.opponent_team_name }}
      </td>
      <td class="score" *ngIf="type != 'training'">
        {{ record.tournament_name ? record.tournament_name : "-" }}
      </td>
      <td class="score" *ngIf="type != 'training'">
        <div
          class="loss"
          *ngIf="record.opponent_team_score > record.team_score"
        >
          <p class="text-white text-center text-capitalize">loss</p>
        </div>
        <div
          class="draw"
          *ngIf="record.opponent_team_score == record.team_score"
        >
          <p class="text-white text-center text-capitalize">draw</p>
        </div>
        <div class="win" *ngIf="record.opponent_team_score < record.team_score">
          <p class="text-white text-center text-capitalize">win</p>
        </div>
      </td>
      <td class="score" *ngIf="type != 'training'">
        {{ record.team_score }} - {{ record.opponent_team_score }}
      </td>
      <td class="location" *ngIf="type == 'training'">
        {{ record.location ? record.location : "-" }}
      </td>

      <td class="eval">
        {{
          (type == "training" ? record.rating : record.evaluation_score) || "-"
        }}
      </td>
      <td class="percent" *ngIf="type == 'training'">
        {{ record.attendance_percentage }} %
      </td>
    </tr>
  </table>
</div>
<!-- All Teams -->
<div class="row" *ngIf="!team">
  <table>
    <tr>
      <th>Team name</th>
      <th>Gender</th>
      <th>Coach</th>
      <th>No. of players</th>
      <th *ngIf="type != 'training'">W/D/L</th>
      <th *ngIf="type == 'training'">No. of Trainings</th>
      <th *ngIf="type != 'training'">No. of Matches</th>
      <th>Coach rating</th>
    </tr>
    <tr *ngFor="let team of allTeams" class="printDesign">
      <td class="score green">{{ team.name }}</td>
      <td class="teamName">{{ team.gender }}</td>
      <td class="teamName">{{ team.coach_name }}</td>
      <td class="teamName green">{{ team.number_of_players }}</td>
      <td class="teamName" *ngIf="type != 'training'">
        <span class="shadeOfGreen">{{ team.number_of_wins }} </span>
        /{{ team.number_of_draws }}/
        <span class="red">{{ team.number_of_loses }}</span>
      </td>
      <td class="teamName green" *ngIf="type == 'training'">
        {{ team.number_of_trainings }}
      </td>
      <td class="teamName green" *ngIf="type != 'training'">
        {{ team.number_of_matches }}
      </td>
      <td class="eval">
        {{
          (type == "training"
            ? team.training_avg_coach_rate
            : team.match_avg_coach_rate) || "-"
        }}
      </td>
    </tr>
  </table>
</div>
