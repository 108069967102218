import { Component, OnInit } from '@angular/core';
import { DashBordEndPointsService } from 'src/app/services/dash-bord-end-points.service';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss'],
})
export class StaffComponent implements OnInit {
  constructor(private _stats: DashBordEndPointsService) {}

  breadcrumb = [
    { label: 'Dashboard', path: '', active: false },
    { label: 'Academy', path: '#', active: false },
    { label: 'Staff', path: '/staff', active: true },
  ];

  staffArr: any;
  ngOnInit(): void {
    this._stats.getAcademyCoaches('').subscribe((res: any) => {
      this.staffArr = res.results;
    });
  }
}
