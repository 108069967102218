import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { MobileEndPointsService } from 'src/app/services/mobileEndPoints/mobile-end-points.service';

@Component({
  selector: 'app-report-popover',
  templateUrl: './report-popover.component.html',
  styleUrls: ['./report-popover.component.scss'],
})
export class ReportPopoverComponent implements OnInit {
  breadcrumb = [
    { label: 'Dashboard', path: '', active: false },
    { label: 'Reports', path: '#', active: false },
    { label: 'View Reports', path: '/staff', active: true },
  ];

  selectedAttributes = 'all';
  coachesReport = 'Inst';
  social = 'posts';
  attributes = '1';
  stats = 'matchPlayed';
  playersReport = 'stats';
  selectAchievers = 'players';
  teamActivites = 'training';
  allCoaches: any = [];
  selectCoaches = 'all';
  playerGender = '';
  fromTime = '';
  toTime = '';
  ReportType = 'Attendance';
  academyReportType = 'Academy users';
  userType = 'players';
  playerFilter = 'all';
  teams: any = [];
  players: any = [];
  evaluationAttributeCategories: any = [];
  // achievers case
  achieversAttr: any = [];
  achieveSelection: any = 'Select';
  achieverSelectionAttributes: any;
  playerAttribute: any = this.achieveSelection;
  achieverSelectionAttr: any = [];
  // done achievers
  ReportDetails: any = 'Attendance';
  selectedTeam = '';
  developmentArea = 'Select';
  selectedPlayer = '';
  attendanceCategory = 'Select';
  attendanceCategoryTwo = '';
  attendanceTournament = 'select Tournament';
  attributeCategory = '1';
  allTournaments: any = [];
  selectedTournaments = '';
  @Output() newItemEvent = new EventEmitter<string>();
  constructor(private _MobileEndPointsService: MobileEndPointsService) {}

  ngOnInit(): void {
    this._MobileEndPointsService.getAllTournaments().subscribe((res: any) => {
      this.allTournaments = res.results;
    });
    this._MobileEndPointsService.getCoaches().subscribe((res: any) => {
      this.allCoaches = res.results;
    });
    this._MobileEndPointsService.getTeams().subscribe((res: any) => {
      this.teams = res.results;
    });
    this._MobileEndPointsService
      .getAllEvaluationAttribute()
      .subscribe((res: any) => {
        console.log(res);
        this.achieverSelectionAttributes = res;
        this.achieveSelection = res[0].id;
        this.selectSpacificAttr();
      });
    this.importPlayers();
    this.importAttributeCategory();
  }

  selectSpacificAttr() {
    this.achieversAttr.map((e: any) => {
      if (e.id == +this.attributes) {
        this.achieverSelectionAttr = e.evaluationattribute_set;
      }
    });
    this.selectedAttributes = this.achieverSelectionAttr[0].id;
  }

  viewReport() {
    if (
      this.toTime &&
      this.fromTime &&
      this.selectedTeam &&
      this.selectedPlayer
    ) {
      this.newItemEvent.emit(
        [
          this.ReportType,
          this.ReportDetails,
          this.attendanceCategory,
          this.attendanceTournament,
          this.developmentArea,
          this.attributeCategory,
          this.fromTime,
          this.toTime,
          this.selectedTeam,
          this.selectedPlayer.split(',')[0],
          this.teams[0].academy_logo,
          this.playerAttribute,
          this.achieveSelection,
          this.selectedTournaments,
        ].toString()
      );
    }
    // else if (this.ReportType == 'Academy report') {
    //   this.newItemEvent.emit(
    //     [
    //       this.ReportType,
    //       this.academyReportType,
    //       this.userType,
    //       this.playerFilter,
    //       this.playerGender,
    //       this.selectedTeam,
    //       this.selectCoaches,
    //       this.teamActivites,
    //       this.selectAchievers,
    //       this.playersReport,
    //       this.stats,
    //       this.social,
    //       this.coachesReport,
    //       this.attributes,
    //       this.selectedAttributes,
    //     ].toString()
    //   );
    // }
  }

  importPlayers() {
    this._MobileEndPointsService
      .getTeamPlayers(+this.selectedTeam)
      .subscribe((res: any) => {
        this.players = res.results;
        // res.results => is an empty array from the backend
      });
  }

  importAttributeCategory() {
    let playerPosition = this.selectedPlayer.split(',')[1];
    if (
      this.selectedPlayer &&
      !['null', '', ' '].includes(playerPosition) &&
      typeof playerPosition !== 'undefined'
    )
      this._MobileEndPointsService
        .getEvaluationAttributeCategories(this.selectedPlayer.split(',')[1])
        .subscribe((res: any) => {
          this.evaluationAttributeCategories = res;
        });
  }
}
