import { Component, Input, OnInit } from '@angular/core';
import { DashBordEndPointsService } from 'src/app/services/dash-bord-end-points.service';

@Component({
  selector: 'app-academy-coaches',
  templateUrl: './academy-coaches.component.html',
  styleUrls: ['./academy-coaches.component.scss'],
})
export class AcademyCoachesComponent implements OnInit {
  @Input() selectCoaches!: string;
  @Input() coachID: any = '';
  coachData: any;
  coachNumber: any;
  academyName = localStorage.getItem('academyName');

  constructor(private _DashBordEndPointsService: DashBordEndPointsService) {}

  ngOnInit(): void {
    this._DashBordEndPointsService
      .getAcademyCoaches(this.coachID == 'all' ? '' : this.coachID)
      .subscribe((res: any) => {
        this.coachNumber = res.count;
        this.coachData = res.results;
        console.log(this.coachNumber);
        console.log(this.coachData);
      });
  }
}
