import { Component, Input, OnInit } from '@angular/core';
import { Academy } from 'src/app/Models/Academy';

@Component({
  selector: 'app-academy-card',
  templateUrl: './academy-card.component.html',
  styleUrls: ['./academy-card.component.scss']
})

export class AcademyCardComponent implements OnInit {

  @Input() academy!: Academy
  @Input() isColored: boolean = false
  constructor() { }

  ngOnInit(): void {
  }

}
