import { Component, Input, OnInit } from '@angular/core';
import { DashBordEndPointsService } from 'src/app/services/dash-bord-end-points.service';

@Component({
  selector: 'app-academy-users-players',
  templateUrl: './academy-users-players.component.html',
  styleUrls: ['./academy-users-players.component.scss'],
})
export class AcademyUsersPlayersComponent implements OnInit {
  @Input() playerFilter: string = '';
  @Input() gender: any = '';
  @Input() teamID: any = '';
  academyName = localStorage.getItem('academyName');
  playreData: any;
  filterNumber: any;
  totalNumber: any;
  constructor(private _DashBordEndPointsService: DashBordEndPointsService) {}

  ngOnInit(): void {
    console.log(this.gender);

    this._DashBordEndPointsService
      .getAcademyUsersPlayer(this.gender, this.teamID)
      .subscribe((res: any) => {
        this.filterNumber = res.count;
        this.playreData = res.results;
      });

    this._DashBordEndPointsService
      .getAcademyUsersPlayer('', '')
      .subscribe((res: any) => {
        this.totalNumber = res.count;
      });
  }
}
