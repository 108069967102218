<!-- <div> -->

<!-- <div class="d-flex mt-5">
    <app-side-bar *ngIf="!isLoginRoute"></app-side-bar>

  </div> -->
<mat-drawer-container class="d-flex justify-content-between" autosize>
  <mat-drawer
    #drawer
    class="example-sidenav printView"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [opened]="mobileQuery.matches || isLoginRoute ? false : true"
  >
    <app-side-bar *ngIf="!isLoginRoute"></app-side-bar>
  </mat-drawer>
  <mat-icon
    *ngIf="mobileQuery.matches && !isLoginRoute"
    (click)="drawer.toggle()"
    class="printView"
    style="
      border: 1px solid black;
      padding: 10px;
      width: 50px;
      height: 50px;
      border-radius: 5px;
      font-size: 30px;
      box-shadow: 0 0 7px #80808024;
      background: white;
      margin: 16px;
    "
    >sort</mat-icon
  >

  <div>
    <app-navbar *ngIf="!isLoginRoute"></app-navbar>

    <section
      class="flex-fill"
      [ngClass]="!isLoginRoute ? 'px-5 page-content' : ''"
    >
      <router-outlet></router-outlet>
    </section>
  </div>
  <div style="flex: 1; height: 100%"></div>
  <app-footer></app-footer>
</mat-drawer-container>
<!-- </div> -->
