import { Component, Input, OnInit } from '@angular/core';
// import {
//   ChartDataSets,
//   ChartOptions,
//   ChartPluginsOptions,
//   ChartType,
// } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit {
  // Colors
  fontColor: string = '#ED6623';
  lightFontColor: string = '#ED66236C';
  fontFamily: string = 'circular-bold';

  //Input Data
  @Input() isUserChart: Boolean = false;

  @Input() select: any = [
    { text: 'Coaches', isActive: false, value: 'coachuser' },
    { text: 'Players', isActive: true, value: 'playeruser' },
    { text: 'Parents', isActive: false, value: 'parentuser' },
  ];

  // @Input() title!: String;
  // @Input() Icon!: string;
  // @Input() xAxisTitle: string = 'Days';
  // @Input() barChartLabels: Label[] = [
  //   '2006',
  //   '2007',
  //   '2008',
  //   '2009',
  //   '2010',
  //   '2011',
  //   '',
  // ];
  // @Input() data: number[] = [100, 200, 300, 400];
  // barChartData: ChartDataSets[] = [
  //   {
  //     backgroundColor: '#FFFFFF',
  //     hoverBackgroundColor: this.fontColor,
  //   },
  // ];

  //Important consts
  total!: number;
  realMaxValue!: number;
  virtualMinValue!: number;

  //Data label Plugin options
  // dataLabelPluginOptions: ChartPluginsOptions = {
  //   fill: false,
  //   legend: {
  //     display: true,
  //   },
  //   datalabels: {
  //     offset: 0,
  //     padding: 0,
  //     align: (context: any) => {
  //       let value = context.dataset.data[context.dataIndex];
  //       return value > this.virtualMinValue ? 'center' : 'end';
  //     },
  //     anchor: (context: any) => {
  //       let value = context.dataset.data[context.dataIndex];
  //       return value > this.virtualMinValue ? 'center' : 'end';
  //     },
  //     color: (context: any) => {
  //       let value = context.dataset.data[context.dataIndex];
  //       return value > this.virtualMinValue ? this.fontColor : this.fontColor;
  //     },
  //     font: (context: any) => {
  //       var width = context.chart.width;
  //       var size = Math.round(width / 32);
  //       return {
  //         size: size,
  //         family: this.fontFamily,
  //         padding: 5,
  //       };
  //     },
  //   },
  // };

  stepSize: number = 1;
  // Chart Options
  // barChartType: ChartType = 'line';
  // barChartLegend = false;
  // barChartPlugins = [DataLabelsPlugin];
  // barChartOptions: ChartOptions = {};

  constructor() {}

  ngOnInit(): void {
    // this.barChartData[0].data = this.data;
    // // this.total = this.data.reduce((total, item) => total + item);
    // this.realMaxValue = Math.max(...this.data) + 5;
    // this.virtualMinValue = Math.min(...this.data);
    // let stepSizeCalculation = Math.ceil(
    //   (this.realMaxValue + this.virtualMinValue) / this.data.length
    // );
    // this.stepSize = stepSizeCalculation;
    // this.barChartOptions = {
    //   responsive: true,
    //   tooltips: { enabled: false },
    //   hover: { mode: undefined },
    //   scales: {
    //     xAxes: [
    //       {
    //         ticks: {
    //           fontSize: 18,
    //           fontColor: this.fontColor,
    //           backdropColor: this.fontColor,
    //           fontFamily: this.fontFamily,
    //           padding: 10,
    //         },
    //         gridLines: {
    //           color: 'transparent',
    //           display: true,
    //           drawBorder: false,
    //           zeroLineColor: '#000',
    //           zeroLineWidth: 1,
    //           drawTicks: false,
    //         },
    //       },
    //     ],
    //     yAxes: [
    //       {
    //         // display: 'none',
    //         ticks: {
    //           fontColor: this.fontColor,
    //           fontSize: 18,
    //           max: this.realMaxValue,
    //           stepSize: this.stepSize,
    //           padding: 10,
    //           fontFamily: this.fontFamily,
    //           suggestedMin: this.virtualMinValue - 5,
    //         },
    //         gridLines: {
    //           color: 'transparent',
    //           display: true,
    //           drawBorder: false,
    //           zeroLineColor: '#000',
    //           zeroLineWidth: 1,
    //           drawTicks: false,
    //         },
    //       },
    //     ],
    //   },
    //   plugins: this.dataLabelPluginOptions,
    // };
  }
}
