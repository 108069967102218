<div
  style="display: block; background-color: #fff"
  class="chart shadow-sm border rounded"
>
  <!-- Main Title  -->
  <!-- <div class="d-flex justify-content-between align-items-start">
    <div class="titleSection">
      <h1 class="m-0">{{ total }}</h1>
      <small>{{ title }}</small>
    </div>
    <div>
      <app-icon [name]="Icon"></app-icon>
    </div>
  </div> -->
  <div class="position-relative my-4">
    <!-- Chart -->
    <!-- <div class="position-relative my-4 m-auto p-3 chartCanvas">
      <canvas
        baseChart
        [options]="options"
        [data]="data"
        [colors]="donutColors"
        [labels]="doughnutChartLabels"
        [chartType]="doughnutChartType"
      >
      </canvas>
    </div> -->

    <!-- <div
      *ngFor="let item of data; let i = index"
      class="labelBox"
      [ngClass]="
        i === 0 ? 'topRight' : i === 1 ? leftCornerOrRight : 'bottomLeft'
      "
      [ngStyle]="{ color: donutColors[0].backgroundColor[i] }"
    >
      <h1>{{ item }}</h1>
      <h5>{{ (item / total) * 100 | number: "1.0-0" }}%</h5>
      <h6>{{ doughnutChartLabels[i] }}</h6>
    </div> -->
  </div>

  <!-- <section
    *ngIf="selectors"
    class="d-flex text-center align-content-center justify-content-center justify-content-md-start w-100"
    style="position: absolute; bottom: 0; left: 0"
  >
    <div class="periodBar shadow-sm chartBarSelect">
      <button
        (click)="activate(i)"
        *ngFor="let period of select; let i = index"
        [ngClass]="period.isActive ? 'active' : ''"
      >
        {{ period.text }}
      </button>
    </div>
  </section> -->
</div>
