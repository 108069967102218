<div class="row mt-2 g-4">
  <div class="col-md-3 px-1" *ngFor="let data of userData">
    <div class="player-card bg-white p-4">
      <div class="image-circle">
        <img
          *ngIf="data.user_type === 'playeruser'"
          src="{{
            data.picture ? data.picture : 'assets/images/playeruser.jpg'
          }}"
          alt="playerImage"
          style="width: 100px; height: 100px; border-radius: 50%"
        />
        <img
          *ngIf="data.user_type === 'coachuser'"
          src="{{
            data.picture ? data.picture : 'assets/images/coachuser.jpg'
          }}"
          alt="playerImage"
          style="width: 100px; height: 100px; border-radius: 50%"
        />
        <img
          *ngIf="data.user_type === 'parentuser'"
          src="{{
            data.picture ? data.picture : 'assets/images/adminuser.jpg'
          }}"
          alt="playerImage"
          style="width: 100px; height: 100px; border-radius: 50%"
        />
      </div>
      <h4>{{ data.username }}</h4>
      <h6>
        {{
          data.user_type === "playeruser"
            ? "Player"
            : data.user_type === "parentuser"
            ? "Parent"
            : data.user_type === "coachuser"
            ? "Coach"
            : "-"
        }}
      </h6>
      <div class="d-flex justify-content-between">
        <div class="d-flex gap-1 align-items-center">
          <p class="m-0">{{ data.total_posts_count }}</p>
          <img
            src="assets/icons/plus.png"
            alt="plus"
            style="width: 12px; height: 12px"
          />
        </div>
        <div class="d-flex gap-1 align-items-center">
          <p class="m-0">{{ data.post_reactions_count }}</p>
          <img
            src="assets/icons/greenHeart.png"
            alt="greenHeart"
            style="width: 12px; height: 12px"
          />
        </div>
        <div class="d-flex gap-1 align-items-center">
          <p class="m-0">{{ data.comments_count }}</p>
          <img
            src="assets/icons/greenComment.png"
            alt="greenComment"
            style="width: 12px; height: 12px"
          />
        </div>
        <!-- <div class="d-flex gap-1 align-items-center">
          <p class="m-0">250</p>
          <img
            src="assets/icons/greenShare.png"
            alt="greenShare"
            style="width: 12px; height: 12px"
          />
        </div> -->
      </div>
    </div>
  </div>
</div>
