import { Component, OnInit } from '@angular/core';
import { DashBordEndPointsService } from 'src/app/services/dash-bord-end-points.service';
import { MobileEndPointsService } from 'src/app/services/mobileEndPoints/mobile-end-points.service';

@Component({
  selector: 'app-player-payment',
  templateUrl: './player-payment.component.html',
  styleUrls: ['./player-payment.component.scss'],
})
export class PlayerPaymentComponent implements OnInit {
  breadcrumb = [
    { label: 'Dashboard', path: '', active: false },
    { label: 'Academy Activities', path: '#', active: false },
    { label: 'Player Payments', path: '/staff', active: true },
  ];
  selectedYear = '2024';
  selectedMonth: any;
  allTeams: any;
  selectedTeam = '';
  userPayment: any;
  usePaymentSummary: any;
  allMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  constructor(
    private _appEndPoints: MobileEndPointsService,
    private _dashboard: DashBordEndPointsService
  ) {
    let currentDate = new Date();
    this.selectedYear = currentDate.getFullYear() + '';
    this.selectedMonth = currentDate.getMonth() + 1 + '';

    this._appEndPoints.getTeams().subscribe((res: any) => {
      this.allTeams = res.results;
    });
    this.filter();
  }

  ngOnInit(): void {}

  filter() {
    this._dashboard
      .getUserPayment(this.selectedTeam, this.selectedYear, this.selectedMonth)
      .subscribe((res: any) => {
        this.userPayment = res.results;
      });
    this._dashboard
      .getUserPaymentSummary(
        this.selectedTeam,
        this.selectedYear,
        this.selectedMonth
      )
      .subscribe((res: any) => {
        this.usePaymentSummary = res;
      });
  }
}
