import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent implements OnInit {
  @Input() name: string = '';
  @Input() number: number = 0;
  @Input() type: any = '';
  @Input() views: number = 0;
  @Input() pics: string = '';
  @Input() firstName: string = ''
  @Input() lastName: string = '';
  userImage: string = ""

  constructor() { }

  ngOnInit(): void {
    switch (this.type) {
      case "playeruser":
        this.userImage = "assets/images/playeruser.jpg"
        break;
      case "coachuser":
        this.userImage = "assets/images/coachuser.jpg"
        break;
      case "adminuser":
        this.userImage = "assets/images/adminuser.jpg"
        break
      case "parentuser":
        this.userImage = "assets/images/parentuser.jpg"
        break
      default:
        this.userImage = "assets/images/playeruser.jpg"
        break;
    }

  }
}
