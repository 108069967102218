import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServicesService } from 'src/app/services/AuthServices/auth-services.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  constructor(
    private authService: AuthServicesService,
    private router: Router
  ) {}
  isActive(route: string): boolean {
    return this.router.isActive(route, true);
  }

  userData!: any;

  getAdminData() {
    this.authService
      .GetAdminDetails()
      .toPromise()
      .then((userData: any) => {
        this.userData = userData;
        localStorage.setItem('academyName', userData.academies[0].name); // saving user token
        localStorage.setItem('academy', userData.academies[0].id); // saving user token
        localStorage.setItem('academylogo', userData?.adminuser?.academy?.logo); // saving academy logo
        localStorage.setItem('username', userData?.username); // saving academy logo
      })
      .catch((err: any) => {
        // console.log(err);
      });
  }

  ngOnInit(): void {
    this.getAdminData();
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
