import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-license-report-card',
  templateUrl: './license-report-card.component.html',
  styleUrls: ['./license-report-card.component.scss']
})
export class LicenseReportCardComponent implements OnInit {

  @Input() clubLogo!: string;
  @Input() clubName!: string;
  @Input() endDate: Date = new Date();
  @Input() isActive:boolean = true;

  @Input() isMyBill:boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
