<div class="users">
  <div class="row">
    <div class="col-md-5">
      <div class="mb-3">
        <app-period-selection-bar (periodChange)="periodChangeHandler($event)"></app-period-selection-bar>
      </div>
    </div>
    <div class="col-md-7">
      <div class="row">

        <div class="col-md-4 text-center statNav" (click)="scrollTO('TopViewedVideosDiv')">
          Top Viewed Videos
          <div class="dot m-auto hidden"></div>
        </div>

        <div class="col-md-4 text-center statNav" (click)="scrollTO('TopViewedProfilesDiv')">
          Top Visited Profiles
          <div class="dot m-auto hidden"></div>
        </div>

        <div class="col-md-4 text-center statNav" (click)="scrollTO('TopInteractiveUsersDiv')">
          Top Interactive Users
          <div class="dot m-auto hidden"></div>
        </div>

      </div>
    </div>
  </div>

  <div class="py-3" id="TopViewedVideosDiv">
    <h1 class="m-0 mt-5">
      <div class="dot"></div>
      Top Viewed Videos
    </h1>
    <div class="row">
      <div class="col-md-4 py-3" *ngFor="let item of video; let x = index">
        <app-post-card [index]="x + 1" [title]="item.title" [views]="item.post_views_count_in_date_range"
          [added_by]="item.user_obj.username" [comments_count]="item.comments_count"
          [likes_count]="item.reactions_count" [video]="item.video" [tags]="item.evaluation_tags_arr"></app-post-card>
      </div>
    </div>
  </div>

  <div class="py-3" id="TopViewedProfilesDiv">
    <h1 class="m-0 mt-5">
      <div class="dot"></div>
      Top Visited Profiles
    </h1>
    <div class="row">
      <div class="col-md-3 py-3" *ngFor="let item of usersTopVisited; let x = index">
        <app-user-card [name]="item.username" [firstName]="item.first_name" [lastName]="item.last_name"
          [type]="item.user_type" [number]="x + 1" [views]="item.visits_count" [pics]="item.picture"></app-user-card>
      </div>
    </div>
  </div>

  <div class="py-3" id="TopInteractiveUsersDiv">
    <div class="row interActiveUser">
      <h1 class="m-0 mt-5">
        <div class="dot"></div>
        Top Interactive Users
      </h1>
      <app-period-selection-bar [periods]="users" [isUserChart]="true" (periodChange)="handleChange($event)">
      </app-period-selection-bar>
    </div>
    <div class="row">
      <div class="col-md-3 py-3" *ngFor="let item of usersTopInteractive; let x = index">
        <app-user-card [name]="item.username" [firstName]="item.first_name" [lastName]="item.last_name"
          [type]="item.user_type" [number]="x + 1" [views]="item.visits_count" [pics]="item.picture"></app-user-card>
      </div>
    </div>
  </div>
</div>