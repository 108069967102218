import { Component, OnInit } from '@angular/core';
import { ChartData, ChartType } from 'chart.js';

@Component({
  selector: 'app-platform-parents-chart',
  templateUrl: './platform-parents-chart.component.html',
  styleUrls: ['./platform-parents-chart.component.scss'],
})
export class PlatformParentsChartComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  public barChartData: ChartData<'bar'> = {
    labels: [
      'Jan2023',
      'Feb2023',
      'Mar2023',
      'Apr2023',
      'May2023',
      'Jun2023',
      'Jul2023',
      'Aug2023',
      'Sep2023',
      'Oct2023',
      'Nov2023',
      'Dec2023',
    ],
    datasets: [
      {
        data: [50],
        label: 'Videos',
        borderColor: 'rgba(76, 175, 80, 1)',
        backgroundColor: 'rgba(76, 175, 80, 1)',
      },
      {
        data: [60],
        label: 'Views',
        borderColor: '#E7655A',
        backgroundColor: '#E7655A',
      },
      {
        data: [70],
        label: 'Comments',
        borderColor: '#32582C',
        backgroundColor: '#32582C',
      },
      {
        data: [80],
        label: 'Likes',
        borderColor: '#ED6425',
        backgroundColor: '#ED6425',
      },
      {
        data: [90],
        label: 'Shares',
        borderColor: '#955E2A',
        backgroundColor: '#955E2A',
      },
    ],
  };

  public barChartOptions: any = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        ticks: {
          min: 0,
          stepSize: 30,
          max: 150,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public barChartType: ChartType = 'bar';
}
