<div class="my-bill">
    <div class="row">
        <div class="col-md-6">
            <app-license-report-card [isActive]="true" [isMyBill]="true"></app-license-report-card>
        </div>
    </div>

    <div class="row align-items-center mb-2">
        <div class="col-md-10">
            <app-stats-card [description]="'Active users in'" [isAnalytic]="true" [iconName]="'group_orange'"
            [iconWidth]="44" [isColoredIcon]="false" [total]="7200" [totalText]="'Users'"></app-stats-card>
        </div>
        <div class="col-md-2">
            <div class="generate">
                Generate Report
            </div>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-md-10">
            <app-stats-card [description]="'Subscription fees in'" [isAnalytic]="true" [iconName]="'invoice_orange'"
            [iconWidth]="44" [isColoredIcon]="false" [total]="7200" [totalText]="'LE'"></app-stats-card>
        </div>
    </div>

    <div class="row align-items-center py-4">
        <div class="col-md-3 renew">
            Renew your subscription
        </div>
        <div class="col-md-4">
            <button class="btn btn-block btn-primary">Pay Now</button>
        </div>
    </div>
</div>