import { Component, OnInit } from '@angular/core';
import { StatsService } from 'src/app/services/StatsServices/stats-services.service';

@Component({
  selector: 'app-users-activities',
  templateUrl: './users-activities.component.html',
  styleUrls: ['./users-activities.component.scss'],
})
export class UsersActivitiesComponent implements OnInit {
  period = 'three_month';
  video: any;
  usersTopVisited: any;
  usersTopInteractive: any;
  users: any = [
    { text: 'All', isActive: true, value: '' },
    { text: 'Coaches', isActive: false, value: 'coachuser' },
    { text: 'Player', isActive: false, value: 'playeruser' },
    { text: 'Parents', isActive: false, value: 'parentuser' },
  ];
  userType: any = '';
  constructor(private _statsServices: StatsService) {}

  ngOnInit(): void {
    this.callAllEndPoint();
  }

  periodChangeHandler(event: any) {
    this.period = event;
    this.callAllEndPoint();
  }

  typeChangeHandler(event: any) {
    this.userType = event;
    this._statsServices
      .usersTopInteractive(this.period, event)
      .subscribe((res: any) => {
        this.usersTopInteractive = res.results;
      });
  }

  callAllEndPoint() {
    this._statsServices.video(this.period).subscribe((res: any) => {
      this.video = res.results;
    });

    this._statsServices.usersTopVisited(this.period).subscribe((res: any) => {
      this.usersTopVisited = res.results;
    });

    this._statsServices
      .usersTopInteractive(this.period, this.userType)
      .subscribe((res: any) => {
        this.usersTopInteractive = res.results;
      });
  }

  scrollTO(id: string) {
    let element = document.getElementById(id);
    element?.scrollIntoView({ behavior: 'smooth' });
  }

  handleChange(event: any) {
    let userTypeChanged = event == 'three_month' ? '' : event;
    this.userType = userTypeChanged;
    this._statsServices
      .usersTopInteractive(this.period, userTypeChanged)
      .subscribe((res: any) => {
        this.usersTopInteractive = res.results;
      });
  }
}
