import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BaseChartDirective } from 'ng2-charts';

import { ChartData, ChartType } from 'chart.js';
import { StatsService } from 'src/app/services/StatsServices/stats-services.service';
import { forkJoin } from 'rxjs';
import { DashBordEndPointsService } from 'src/app/services/dash-bord-end-points.service';

@Component({
  selector: 'app-platform-statistics',
  templateUrl: './platform-statistics.component.html',
  styleUrls: ['./platform-statistics.component.scss'],
})
export class PlatformStatisticsComponent implements OnInit {
  pauseOtherVideos(index: number) {
    this.videoElements.forEach((video, i) => {
      if (i !== index) {
        video.nativeElement.pause();
      }
    });
  }

  openFullscreen(video: HTMLVideoElement) {
    if (video.requestFullscreen) {
      video.requestFullscreen();
      // } else if (video.webkitRequestFullScreen) {
      //   video.webkitRequestFullScreen();
      // } else if (video.msRequestFullscreen) {
      //   video.msRequestFullscreen();
    }
    // }
  }
  breadcrumb = [
    { label: 'Dashboard', path: '', active: false },
    { label: 'User Activities', path: '#', active: false },
    { label: 'Platform Stats', path: '/staff', active: true },
  ];
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @ViewChildren('videoElement') videoElements!: QueryList<ElementRef>;

  branches = ['master', 'main', 'Test Branch'];
  selectedYear = '2024';
  selectedChart: string = 'playeruser';
  selectedUser: string = '';
  hideComponent: boolean = true;
  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  // Integeration Variables
  allCoaches: any;
  selectedDate: any = 'three_month';
  datess: any = ['week', 'month', 'year', 'three_month'];
  allVideos: any;
  videoData: any = [];
  selectedSortBy: any = '-reactions_count';
  userType: any;
  allVideosNumber: any = 0;
  allVideosViews: any = [];
  allVideosLikes: any = [];
  allVideosComments: any = [];
  usersTopInteractive: any;
  userData: any = [];
  usersTopVisitedData: any = [];
  selectedDateTopVisited: any = '2024';
  selectedDateTopInteractive: any = '2024';
  totalInteractions = 0;
  videoStates: boolean[] = [];
  progressPercentages: any[] = [];
  showControls: boolean[] = [];
  showButton: boolean[] = [];
  selectedMonthTopVisited = 0;
  selectedMonthTopInteractive = 0;
  // Track the currently playing video element
  currentlyPlayingVideo: HTMLVideoElement | undefined = undefined;
  // Update the currentlyPlayingVideo property
  currentlyPlayingVideoIndex: number | undefined = undefined;
  // Store the currentTime for each video
  videoCurrentTimes: number[] = [];

  showChart(chartType: string) {
    this.selectedChart = chartType;
    this.setGraphData();
  }
  showUsers(userType: string): void {
    this.selectedUser = userType;
    this.topInteractiveUsers();
  }

  public barChartData: ChartData<'bar'> = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    datasets: [
      {
        data: [50],
        label: 'Videos',
        borderColor: 'rgba(76, 175, 80, 1)',
        backgroundColor: 'rgba(76, 175, 80, 1)',
      },
      {
        data: [60],
        label: 'Views',
        borderColor: '#E7655A',
        backgroundColor: '#E7655A',
      },
      {
        data: [70],
        label: 'Comments',
        borderColor: '#32582C',
        backgroundColor: '#32582C',
      },
      {
        data: [80],
        label: 'Likes',
        borderColor: '#ED6425',
        backgroundColor: '#ED6425',
      },
      {
        data: [90],
        label: 'Shares',
        borderColor: '#955E2A',
        backgroundColor: '#955E2A',
      },
    ],
  };

  public barChartOptions: any = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        ticks: {
          min: 0,
          stepSize: 30,
          max: 150,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public barChartType: ChartType = 'bar';
  postSummary: any;

  constructor(
    private _StatsService: StatsService,
    private sanitizer: DomSanitizer,
    private _dashboard: DashBordEndPointsService
  ) {
    // Initialize showControls to true for all videos

    this._dashboard.getPostSummary().subscribe((res: any) => {
      this.postSummary = res;
    });
    this.setGraphData();
  }

  // End Fetching Video Data
  ngOnInit(): void {
    // Initial data retrieval
    this.fetchVideoData();

    this.topInteractiveUsers();
    this.usersTopVisited();

    this.showControls = new Array(this.videoElements?.length).fill(true);
    this.progressPercentages = new Array(this.videoElements?.length).fill('0%');
  }
  // Integrations
  setGraphData() {
    let videos: any = [];
    let views: any = [];
    let comments: any = [];
    let likes: any = [];
    let shares: any = [];
    this.totalInteractions = 0;

    this._dashboard
      .getPostGraph(this.selectedYear, this.selectedChart)
      .subscribe((res: any) => {
        res.forEach((x: any) => {
          this.totalInteractions =
            this.totalInteractions +
            x.videos +
            x.views +
            x.comments +
            x.likes +
            x.shares;
          videos.push(x.videos);
          views.push(x.views);
          comments.push(x.comments);
          likes.push(x.likes);
          shares.push(x.shares);
        });
        this.barChartData.datasets[0].data = videos;
        this.barChartData.datasets[1].data = views;
        this.barChartData.datasets[2].data = comments;
        this.barChartData.datasets[3].data = likes;
        this.barChartData.datasets[4].data = shares;

        this.chart?.update();
      });
  }
  // Fetching Video Data

  onDateChange(): void {
    // When the option changes, fetch and display the data
    this.fetchVideoData();
  }

  sanitizeVideoUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  private fetchVideoData(): void {
    let videoDate = '';
    if (this.selectedDate == 'week') {
      videoDate = this.determineSpecificDate(7);
    } else if (this.selectedDate == 'month') {
      videoDate = this.determineSpecificDate(30);
    } else if (this.selectedDate == 'year') {
      videoDate =
        new Date(new Date().getFullYear(), 0, 1)
          .toISOString()
          .substring(0, 10) + '';
    }

    this._dashboard
      .getPlatformStatisticsVideoReport(videoDate, this.selectedSortBy)
      .subscribe((res: any) => {
        this.videoData = res.results;
      });
  }

  determineSpecificDate(duration: any) {
    const today = new Date();

    // Calculate one week ago by subtracting 7 days
    const oneWeekAgo = new Date(today);
    oneWeekAgo.setDate(today.getDate() - duration);

    return oneWeekAgo.toLocaleDateString('en-CA');
  }

  sortVideosBy(attribute: string): void {
    this.selectedSortBy = attribute;
    this.fetchVideoData();
  }

  topInteractiveUsers() {
    const today = new Date(
      this.selectedDateTopInteractive,
      this.selectedMonthTopInteractive
    );

    const oneMonthAfter = new Date(today);
    oneMonthAfter.setDate(today.getDate() + 30);

    this._dashboard
      .getTopInteractiveProfiles(
        today.toLocaleDateString('en-CA'),
        oneMonthAfter.toLocaleDateString('en-CA'),
        this.selectedUser
      )
      .subscribe((res: any) => {
        this.userData = res.results;
      });

    // this._StatsService
    //   .usersTopInteractive(this.selectedDateTopInteractive, this.selectedUser)
    //   .subscribe((res: any) => {
    //     this.userData = res.results;
    //   });
  }
  onDateChangeTopInteractive(): void {
    // When the option changes, fetch and display the data
    this.topInteractiveUsers();
  }
  usersTopVisited() {
    const today = new Date(
      this.selectedDateTopVisited,
      this.selectedMonthTopVisited
    );

    const oneMonthAfter = new Date(today);
    oneMonthAfter.setDate(today.getDate() + 30);

    this._dashboard
      .getTopVisitedProfiles(
        today.toLocaleDateString('en-CA'),
        oneMonthAfter.toLocaleDateString('en-CA')
      )
      .subscribe((res: any) => {
        this.usersTopVisitedData = res?.results;
      });
  }
  onDateChangeTopVistied(): void {
    // When the option changes, fetch and display the data
    this.usersTopVisited();
  }

  // Function to handle toggle play
  togglePlay(index: number) {
    const videos = this.videoElements.toArray();
    if (videos && videos.length > index) {
      const video: HTMLVideoElement | undefined = videos[index]?.nativeElement;
      if (video) {
        if (
          this.currentlyPlayingVideoIndex !== undefined &&
          this.currentlyPlayingVideoIndex !== index
        ) {
          // Pause the currently playing video
          const currentlyPlayingVideo: HTMLVideoElement | undefined =
            videos[this.currentlyPlayingVideoIndex]?.nativeElement;
          if (currentlyPlayingVideo) {
            currentlyPlayingVideo.pause();
            this.videoStates[this.currentlyPlayingVideoIndex] = false;
            // Store the currentTime of the currently playing video
            this.videoCurrentTimes[this.currentlyPlayingVideoIndex] =
              currentlyPlayingVideo.currentTime;

            // Show the play button for the previously playing video
            this.showControls[this.currentlyPlayingVideoIndex] = true;
          }
        }

        if (video.paused || video.ended) {
          // Set the currentTime to the stored time if available
          if (this.videoCurrentTimes[index]) {
            video.currentTime = this.videoCurrentTimes[index];
          }
          video.play();
          this.videoStates[index] = true;
          this.currentlyPlayingVideoIndex = index;

          // Check if the video ended and reset progress if restarting
          if (video.ended) {
            this.progressPercentages[index] = '0%';
          }
        } else {
          video.pause();
          this.videoStates[index] = false;
          this.currentlyPlayingVideoIndex = undefined;
          // Store the currentTime when pausing
          this.videoCurrentTimes[index] = video.currentTime;
        }
      }
    }

    // Toggle the showControls flag to control button visibility
    this.showControls[index] = true;

    if (this.videoStates[index]) {
      // If the video is playing, hide controls after 2 seconds
      setTimeout(() => {
        this.showControls[index] = false;
      }, 500);
    }
  }

  // Function to determine whether to show controls
  showPlayButton(index: number): boolean {
    return !this.videoStates[index] || this.showControls[index];
  }
  // Function to handle visibility buttons
  toggleButtonVisibility(index: number) {
    this.showButton[index] = true;
    setTimeout(() => {
      this.showButton[index] = false;
    }, 500);
  }
  // Function to handle Progess
  handleProgress(index: number) {
    const video: HTMLVideoElement =
      this.videoElements.toArray()[index].nativeElement;
    const progressPercentage = (video.currentTime / video.duration) * 100;
    this.progressPercentages[index] = progressPercentage;
  }
  percentage: any[] = [];
  onTimeUpdate(index: any) {
    const video: HTMLVideoElement =
      this.videoElements.toArray()[index].nativeElement;
    this.percentage[index] = (video.currentTime / video.duration) * 100;
  }
  scrub(event: MouseEvent, index: number) {
    const video: HTMLVideoElement =
      this.videoElements.toArray()[index].nativeElement;
    const target = event.target as HTMLElement; // Explicitly cast to HTMLElement
    const scrubTime = (event.offsetX / target.clientWidth) * video.duration;
    video.currentTime = scrubTime;
  }
  // Listen to the 'ended' event of each video element to detect when a video finishes
  ngAfterViewInit(): void {
    this.videoElements.forEach((videoElement: ElementRef, index: number) => {
      const video: HTMLVideoElement | undefined = videoElement.nativeElement;
      if (video) {
        video.addEventListener('ended', () => {
          // Video has ended, reset state
          this.videoStates[index] = false;
          this.progressPercentages[index] = '0%';
          this.showControls[index] = true;
        });
      }
    });
  }
}
