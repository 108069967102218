import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  academyName: any = '';
  academyLogo: any = '';
  userName: any = '';
  constructor() {
    this.academyName = localStorage.getItem('academyName');
    this.userName = localStorage.getItem('username');
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.academyLogo = localStorage.getItem('academylogo');
    }, 500);
  }
}
