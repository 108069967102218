<h2 class="mb-5 ms-4 top-achievers-header-text">
  Top achievers / players / Player Stats / Social /
  <span *ngIf="social == 'posts'">Posts</span>
  <span *ngIf="social == 'made'"> Interaction Made </span>
  <span *ngIf="social == 'received'"> Interaction Received</span>
</h2>
<div
  class="row card flex-row shadow mb-2"
  *ngFor="let record of tableData; let i = index"
>
  <div class="col-3 p-0 m-0 overflow">
    <div class="indexing">{{ i + 1 }}</div>
    <div *ngIf="record.picture">
      <img style="object-fit: contain" [src]="record.picture" />
    </div>
    <div *ngIf="!record.picture">
      <img style="object-fit: contain" src="/assets/images/playeruser.jpg" />
    </div>

    <!-- <img  [src]="record.picture" /> -->
    <!-- <img  [src]="record.picture" /> -->
  </div>
  <div class="col-4">
    <h1>{{ record.username }}</h1>
    <h2 class="strike-text">{{ record.academy }}</h2>
    <h2 class="selection-text">{{ record.team }}</h2>
    <div class="line"></div>
    <div class="d-flex flex-row gap-4">
      <div>
        <h6 class="position-text">Position</h6>
        <p class="age-text">
          {{ record.primary_position }}
        </p>
      </div>
      <div>
        <h6 class="position-text">Age</h6>
        <p class="age-text">{{ record.age ? record.age : "10" }}</p>
      </div>
    </div>
    <div class="line"></div>
    <p class="mt-1 top-achiever-text">
      Top achievers / player / Social /

      <span class="stats-text" *ngIf="social == 'posts'"> Posts</span>
      <span class="stats-text" *ngIf="social == 'made'">
        Interaction Made
      </span>
      <span class="stats-text" *ngIf="social == 'received'">
        Interaction Received
      </span>
    </p>
    <div class="d-flex flex-row gap-2 pb-2">
      <img
        *ngIf="social === 'assists'"
        src="assets/icons/allPlayers.png"
        style="width: 53px"
        alt="couchIcon"
      />
      <img
        *ngIf="social === 'goals'"
        src="assets/icons/goals.png"
        style="width: 53px"
        alt="couchIcon"
      />
      <img
        *ngIf="social === 'participationPercentage'"
        src="assets/icons/Participation.png"
        style="width: 53px"
        alt="couchIcon"
      />
      <img
        *ngIf="social === 'matchPlayed'"
        src="assets/icons/Matches Played.png"
        style="width: 53px"
        alt="couchIcon"
      />
      <img
        *ngIf="social === 'cleanSheets'"
        src="assets/icons/Clean Sheets.png"
        style="width: 53px"
        alt="couchIcon"
      />
      <img
        *ngIf="social === 'posts'"
        src="assets/icons/posts.png"
        style="width: 53px"
        alt="couchIcon"
      />
      <img
        *ngIf="record.posts_interactions_received_count >= 0"
        src="assets/icons/Interactions Received.png"
        style="width: 53px"
        alt="couchIcon"
      />
      <img
        *ngIf="record.posts_interactions_made_count >= 0"
        src="assets/icons/Interactions Made.png"
        style="width: 53px"
        alt="couchIcon"
      />
      <div class="d-flex align-items-center gap-1">
        <span class="number-count-text"> {{ record.posts_count }} </span>
        <span class="number-count-text">
          {{ record.posts_interactions_made_count }}
        </span>
        <span class="number-count-text">
          {{ record.posts_interactions_received_count }}
        </span>

        <span class="stats-text" *ngIf="social == 'posts'"> Posts</span>
        <span class="stats-text" *ngIf="social == 'made'">
          Interaction Made
        </span>
        <span class="stats-text" *ngIf="social == 'received'">
          Interaction Received
        </span>
      </div>
    </div>
  </div>
  <div class="col-5">
    <img
      *ngIf="!record.primary_position"
      style="object-fit: contain"
      class=""
      src="assets/images/stadium.png"
      alt="stadium"
    />
    <img
      *ngIf="record.primary_position"
      style="object-fit: contain"
      class=""
      [src]="'assets/images/' + record.primary_position + '.svg'"
      alt="stadium"
    />
  </div>
</div>
