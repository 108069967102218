import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { CalenderDayModalComponent } from 'src/app/components/calender-day-modal/calender-day-modal.component';
import { DashBordEndPointsService } from 'src/app/services/dash-bord-end-points.service';
import { MobileEndPointsService } from 'src/app/services/mobileEndPoints/mobile-end-points.service';

@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss'],
})
export class CalenderComponent implements OnInit {
  breadcrumb = [
    { label: 'Dashboard', path: '', active: false },
    { label: 'Academy', path: '#', active: false },
    { label: 'Calendar', path: '/calendar', active: true },
  ];
  ngOnInit(): void {
    this.getCalender();
  }

  getCalender() {
    this._dashboard
      .getCalender(
        this.currentYear,
        this.currentMonth + 1,
        this.selectedCoaches,
        this.selectedTeam
      )
      .subscribe((res: any) => {
        this.calenderEventPoints = res.results;
      });
  }

  pitchThisDay(day: any) {
    let dayDate: Date = day.date;
    for (let index = 0; index < this.calenderEventPoints.length; index++) {
      let isMatch = this.calenderEventPoints[index].have_match;
      let z: String = this.calenderEventPoints[index].date;
      if (
        +z.substring(8) == dayDate.getDate() &&
        isMatch &&
        day.isCurrentMonth
      ) {
        return true;
      }
    }
    return false;
  }

  clockThisDay(day: any) {
    let dayDate: Date = day.date;
    for (let index = 0; index < this.calenderEventPoints.length; index++) {
      let isTrain = this.calenderEventPoints[index].have_training;
      let z: String = this.calenderEventPoints[index].date;

      if (
        +z.substring(8) == dayDate.getDate() &&
        isTrain &&
        day.isCurrentMonth
      ) {
        return true;
      }
    }
    return false;
  }

  selectCoach() {
    this.getCalender();
  }
  selectTeam() {
    this.getCalender();
  }
  days: string[] = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];
  calenderEventPoints: any;
  currentYear: number;
  currentMonth: number;
  currentDate: Date;
  allCoaches: any;
  allTeams: any;
  selectedCoaches = '';
  selectedTeam = '';

  constructor(
    public dialog: MatDialog,
    private _appEndPoints: MobileEndPointsService,
    private _dashboard: DashBordEndPointsService
  ) {
    this._appEndPoints.getCoaches().subscribe((res: any) => {
      this.allCoaches = res.results;
    });
    this._appEndPoints.getTeams().subscribe((res: any) => {
      this.allTeams = res.results;
    });

    this.currentDate = new Date();
    this.currentYear = this.currentDate.getFullYear();
    this.currentMonth = this.currentDate.getMonth();
  }

  prevMonth() {
    if (this.currentMonth === 0) {
      this.currentYear--;
      this.currentMonth = 11;
    } else {
      this.currentMonth--;
    }
    this.updateDate();
  }

  nextMonth() {
    if (this.currentMonth === 11) {
      this.currentYear++;
      this.currentMonth = 0;
    } else {
      this.currentMonth++;
    }
    this.updateDate();
  }

  updateDate() {
    this.currentDate = new Date(this.currentYear, this.currentMonth, 1);
    this.getCalender();
  }

  getYears(): number[] {
    const currentYear = new Date().getFullYear();
    return [currentYear - 1, currentYear, currentYear + 1];
  }

  onYearChange() {
    this.currentYear = this.currentDate.getFullYear();
    this.updateDate();
  }

  getWeeks(): { date: Date; isCurrentMonth: boolean }[][] {
    const weeks: { date: Date; isCurrentMonth: boolean }[][] = [];
    const firstDayOfMonth = new Date(this.currentYear, this.currentMonth, 1);
    const lastDayOfMonth = new Date(this.currentYear, this.currentMonth + 1, 0);
    const startDate = new Date(firstDayOfMonth);
    const endDate = new Date(lastDayOfMonth);

    while (startDate.getDay() !== 0) {
      startDate.setDate(startDate.getDate() - 1);
    }

    while (endDate.getDay() !== 6) {
      endDate.setDate(endDate.getDate() + 1);
    }

    let currentWeek: { date: Date; isCurrentMonth: boolean }[] = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      currentWeek.push({
        date: new Date(currentDate),
        isCurrentMonth: currentDate.getMonth() === this.currentMonth,
      });
      if (currentDate.getDay() === 6) {
        weeks.push(currentWeek);
        currentWeek = [];
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return weeks;
  }

  clickOnDay(day: any) {
    this.dialog.open(CalenderDayModalComponent, {
      data: {
        day: day,
        team: this.selectedTeam,
        coach: this.selectedCoaches,
        isMatch: this.pitchThisDay(day),
        isTrainging: this.clockThisDay(day),
      },
    });
  }
}
