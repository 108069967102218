<div class="d-flex flex-column bg-white py-2 custom-shadow pb-4">
  <div class="text-container pt-2 d-flex justify-content-around">
    <span> {{ number ? number : "0" }} </span>
    <div
      class="logo-container logo-container d-flex justify-content-center align-items-center"
    >
      <img [src]="image" alt="" class="w-75" />
    </div>
  </div>
  <span class="desc-text">{{ text }}</span>
</div>
