<h2 class="mb-5 ms-4 top-achievers-header-text">
  Top achievers / players / Player Stats /
  <span> Rating </span>
</h2>
<div
  class="row card flex-row shadow mb-2"
  *ngFor="let record of tableData; let i = index"
>
  <div class="col-3 p-0 m-0 overflow">
    <div class="indexing">{{ i + 1 }}</div>
    <div *ngIf="record.picture">
      <img style="object-fit: contain" [src]="record.picture" />
    </div>
    <div *ngIf="!record.picture">
      <img style="object-fit: contain" src="/assets/images/playeruser.jpg" />
    </div>
  </div>
  <div class="col-4">
    <h1>{{ record.username }}</h1>
    <h2 class="strike-text">{{ record.academy }}</h2>
    <h2 class="selection-text">{{ record.team }}</h2>
    <div class="line"></div>
    <div class="d-flex flex-row gap-4">
      <div>
        <h6 class="position-text">Position</h6>
        <p class="age-text">
          {{ record.primary_position }}
        </p>
      </div>
      <div>
        <h6 class="position-text">Age</h6>
        <p class="age-text">{{ record.age ? record.age : "10" }}</p>
      </div>
    </div>
    <div class="line"></div>
    <p class="top-achiever-text">Top achievers / player / Stats / Rating</p>
    <div class="d-flex flex-row gap-2 pb-2">
      <img
        *ngIf="record.avg_rate >= 0"
        src="assets/icons/rating.png"
        style="width: 53px"
        alt="couchIcon"
      />

      <div class="d-flex align-items-center gap-1">
        <span class="number-count-text">
          {{ record?.avg_rate?.toFixed(1) }}
        </span>
        <span class="stats-text"> Rating </span>
      </div>
    </div>
  </div>
  <div class="col-5">
    <img
      style="object-fit: contain"
      class=""
      src="assets/images/stadium.png"
      alt="stadium"
      *ngIf="record.primary_position == null"
    />
    <img
      style="object-fit: contain"
      class=""
      src="assets/images/GK.svg"
      alt="stadium"
      *ngIf="record.primary_position == 'GK'"
    />
    <img
      style="object-fit: contain"
      class=""
      src="assets/images/CB.svg"
      alt="stadium"
      *ngIf="record.primary_position == 'CB'"
    />
    <img
      style="object-fit: contain"
      class=""
      src="assets/images/RB.svg"
      alt="stadium"
      *ngIf="record.primary_position == 'RB'"
    />
    <img
      style="object-fit: contain"
      class=""
      src="assets/images/LB.svg"
      alt="stadium"
      *ngIf="record.primary_position == 'LB'"
    />
    <img
      style="object-fit: contain"
      class=""
      src="assets/images/LWB.svg"
      alt="stadium"
      *ngIf="record.primary_position == 'LWB'"
    />
    <img
      style="object-fit: contain"
      class=""
      src="assets/images/RWB.svg"
      alt="stadium"
      *ngIf="record.primary_position == 'RWB'"
    />
    <img
      style="object-fit: contain"
      class=""
      src="assets/images/CM.svg"
      alt="stadium"
      *ngIf="record.primary_position == 'CM'"
    />
    <img
      style="object-fit: contain"
      class=""
      src="assets/images/CAM.svg"
      alt="stadium"
      *ngIf="record.primary_position == 'CAM'"
    />
    <img
      style="object-fit: contain"
      class=""
      src="assets/images/CDM.svg"
      alt="stadium"
      *ngIf="record.primary_position == 'CDM'"
    />

    <img
      style="object-fit: contain"
      class=""
      src="assets/images/LM.svg"
      alt="stadium"
      *ngIf="record.primary_position == 'LM'"
    />
    <img
      style="object-fit: contain"
      class=""
      src="assets/images/RM.svg"
      alt="stadium"
      *ngIf="record.primary_position == 'RM'"
    />
    <img
      style="object-fit: contain"
      class=""
      src="assets/images/RW.svg"
      alt="stadium"
      *ngIf="record.primary_position == 'RW'"
    />
    <img
      style="object-fit: contain"
      class=""
      src="assets/images/LW.svg"
      alt="stadium"
      *ngIf="record.primary_position == 'LW'"
    />
    <img
      style="object-fit: contain"
      class=""
      src="assets/images/ST.svg"
      alt="stadium"
      *ngIf="record.primary_position == 'ST'"
    />
  </div>
</div>
