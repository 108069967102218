import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DashDomain } from './EndPoints/DashBoardDomain';

@Injectable({
  providedIn: 'root',
})
export class DashBordEndPointsService {
  api = DashDomain;

  constructor(private http: HttpClient) {}

  getMoreData(NextURL: any) {
    return this.http.get(NextURL);
  }

  getPostSummary() {
    return this.http.get(`${this.api}academy/api/post-report/summary/`);
  }

  getPostGraph(year: any, userType: any) {
    return this.http.get(
      `${this.api}academy/api/post-report/?year=${year}&user_type=${userType}`
    );
  }

  getPlatformStatisticsVideoReport(startDate: any, ordering: any) {
    return this.http.get(
      `${this.api}academy/api/video-report/?start_date=${startDate}&ordering=${ordering}&page_size=8`
    );
  }

  getTeamPayment(team: any, year: any, month: any, page_size: any = '') {
    return this.http.get(
      `${this.api}academy/api/user-payment/?team=${team}&year=${year}&month=${month}&page_size=${page_size}&ordering=-is_paid,username`
    );
  }

  getPlayerPayment(player: any, start: any, end: any, page_size: any = '') {
    return this.http.get(
      `${this.api}academy/api/user-payment/player_payments/?start_date=${start}&end_date=${end}&player_id=${player}&page_size=${page_size}&ordering=-is_paid,username`
    );
  }

  getPaymentSummry(team: any, year: any, month: any) {
    return this.http.get(
      `${this.api}academy/api/user-payment/summary/?team=${team}&year=${year}&month=${month}&ordering=-is_paid,username`
    );
  }

  getAcademyStates(team: any) {
    return this.http.get(`${this.api}academy/api/academy-states/?team=${team}`);
  }

  getAcademyMatchesSummry(year: any, month: any, team: any) {
    return this.http.get(
      `${this.api}academy/api/academy-matches-summary/?year=${year}&month=${month}&team=${team}`
    );
  }

  getAcademyTrainingSummry(year: any, month: any, team: any) {
    return this.http.get(
      `${this.api}academy/api/academy-training-summary/?year=${year}&month=${month}&team=${team}`
    );
  }

  getAcademyYearMatchSummary(year: any) {
    return this.http.get(
      `${this.api}academy/api/academy-year-matches-summary/?year=${year}`
    );
  }

  getAcademyYearTrainigSummary(year: any) {
    return this.http.get(
      `${this.api}academy/api/academy-year-training-summary/?year=${year}`
    );
  }

  getPlayerAttendancesTable(
    playerID: any,
    teamId: any,
    from: any,
    to: any,
    type: any,
    tournament: any
  ) {
    return this.http.get(
      `${this.api}academy/api/event-points/?player_user=${playerID}&tournament=${tournament}&event__team_id=${teamId}&start_date=${from}&end_date=${to}&event_type=${type}&page_size=200`
    );
  }

  getPlayerAttendancesChart(
    playerID: any,
    teamId: any,
    from: any,
    to: any,
    type: any,
    tournament: any
  ) {
    return this.http.get(
      `${this.api}academy/api/event-points/charts/?player_user=${playerID}&event__team_id=${teamId}&start_date=${from}&end_date=${to}&event_type=${type}&page_size=200&tournament=${tournament}`
    );
  }

  getPlayerMatchAttendancesTable(
    playerID: any,
    teamId: any,
    from: any,
    to: any,
    tournament: any
  ) {
    return this.http.get(
      `${this.api}academy/api/matches/?player_user=${playerID}&team=${teamId}&start_date=${from}&end_date=${to}&page_size=200&tournament=${tournament}`
    );
  }

  getUserPayment(teamId: any, year: any, month: any) {
    return this.http.get(
      `${this.api}academy/api/user-payment/?team=${teamId}&year=${year}&month=${month}&page_size=400&ordering=-is_paid,username`
    );
  }

  getUserPaymentSummary(teamId: any, year: any, month: any) {
    return this.http.get(
      `${this.api}academy/api/user-payment/summary/?team=${teamId}&year=${year}&month=${month}&page_size=200&ordering=-is_paid,username`
    );
  }

  getPlayerMatchSummary(
    playerID: any,
    teamId: any,
    from: any,
    to: any,
    tournament: any
  ) {
    return this.http.get(
      `${this.api}academy/api/matches/player-summary-score/?player_user=${playerID}&team=${teamId}&start_date=${from}&end_date=${to}&page_size=200&tournament=${tournament}`
    );
  }

  getCollectivePlayerEvaluationAttributes(
    playerID: any,
    type: any,
    from: any,
    to: any
  ) {
    return this.http.get(
      `${this.api}academy/api/collective-player-evaluation-attributes/?player=${playerID}&start_date=${from}&end_date=${to}&evaluation_attribute__category=${type}&page_size=200`
    );
  }

  getEvaluationAttributesCharts(playerID: any, type: any, from: any, to: any) {
    return this.http.get(
      `${this.api}academy/api/evaluation-attributes/charts/?player=${playerID}&category=${type}&start_date=${from}&end_date=${to}&page_size=200`
    );
  }

  getEvaluationSummary(playerID: any, from: any, to: any) {
    return this.http.get(
      `${this.api}academy/api/event-points/evaluation-summary/?player_user=${playerID}&start_date=${from}&end_date=${to}&page_size=200`
    );
  }

  getEventWithEvaluation(playerID: any, from: any, to: any) {
    return this.http.get(
      `${this.api}academy/api/event-points/with-evaluation/?player_user=${playerID}&start_date=${from}&end_date=${to}&page_size=200`
    );
  }

  getAcademyUsersPlayer(gender: any, teamID: any) {
    return this.http.get(
      `${this.api}academy/api/player-report/?gender=${gender}&team=${teamID}&page_size=200`
    );
  }

  getAcademyUsersParents(teamID: any) {
    return this.http.get(
      `${this.api}academy/api/parent-report/?team=${teamID}&page_size=200`
    );
  }

  getAcademyCoaches(coachID: any) {
    return this.http.get(
      `${this.api}academy/api/coach-report/?id=${coachID}&page_size=200`
    );
  }

  getAcademyTraining(team: any, from: any, to: any) {
    return this.http.get(
      `${this.api}academy/api/training-report/?page_size=200&team=${team}&start_date=${from}&end_date=${to}`
    );
  }

  getAcademyMatches(team: any, tournament: any, from: any, to: any) {
    return this.http.get(
      `${this.api}academy/api/match-report/?page_size=200&team=${team}&tournament=${tournament}&start_date=${from}&end_date=${to}`
    );
  }

  getStatsAchiversMatchPlayed(team: any, tournament: any) {
    return this.http.get(
      `${this.api}academy/api/match-top-achievers/?page_size=200&team=${team}&tournament=${tournament}`
    );
  }

  getStatsAchiversAttendancePercentage(team: any, tournament: any) {
    return this.http.get(
      `${this.api}academy/api/match-top-achievers/matches-attendance-percentage/?page_size=200&team=${team}&tournament=${tournament}`
    );
  }

  getStatsAchiversGoals(team: any, tournament: any) {
    return this.http.get(
      `${this.api}academy/api/goals-top-achievers/?page_size=200&team=${team}&tournament=${tournament}`
    );
  }

  getStatsAchiversAssists(team: any, tournament: any) {
    return this.http.get(
      `${this.api}academy/api/assists-top-achievers/?page_size=200&team=${team}&tournament=${tournament}`
    );
  }

  getStatsAchiversCleanSheets(team: any, tournament: any) {
    return this.http.get(
      `${this.api}academy/api/clean-sheets-top-achievers/?page_size=200&team=${team}&tournament=${tournament}`
    );
  }

  getStatsAchiversRate(team: any) {
    return this.http.get(
      `${this.api}academy/api/rate-top-achievers/?page_size=200&team=${team}`
    );
  }

  getSocialAchiversPosts(team: any) {
    return this.http.get(
      `${this.api}academy/api/posts-top-achievers/?page_size=200&team=${team}`
    );
  }

  getSocialAchiversInteractionMade(team: any) {
    return this.http.get(
      `${this.api}academy/api/posts-interactions-made-top-achievers/?page_size=200&team=${team}`
    );
  }

  getSocialAchiversInteractionRec(team: any) {
    return this.http.get(
      `${this.api}academy/api/posts-interactions-received-top-achievers/?page_size=200&team=${team}`
    );
  }

  getPreTrainingInstCoachAchivers() {
    return this.http.get(
      `${this.api}academy/api/pre-training-instructions-top-achievers/?page_size=200`
    );
  }

  getEvaluationCoachAchivers() {
    return this.http.get(
      `${this.api}academy/api/coach-training-evaluation-top-achievers/?page_size=200`
    );
  }

  getAttrPlayerAchivers(catID: any, attrID: any, team: any) {
    return this.http.get(
      `${this.api}academy/api/attribute-top-achievers/?page_size=200&&category_id=${catID}&&attribute_id=${attrID}&team=${team}`
    );
  }

  getPostsCoachAchivers() {
    return this.http.get(
      `${this.api}academy/api/coach-posts-created-top-achievers/?page_size=200`
    );
  }

  getInteractionsCoachAchivers() {
    return this.http.get(
      `${this.api}academy/api/coach-posts-interactions-created-top-achievers/?page_size=200`
    );
  }

  getNumberOfGenderTeam() {
    return this.http.get(
      `${this.api}academy/api/training-report/gender-teams-count/?page_size=200`
    );
  }

  getMatchReportStats(
    team: any,
    tournament: any,
    startDate: any,
    end_date: any
  ) {
    return this.http.get(
      `${this.api}academy/api/match-report/team-stats/?page_size=200&team=${team}&tournament=${tournament}&start_date=${startDate}&end_date=${end_date}`
    );
  }

  getTrainingReportStats(team: any, startDate: any, end_date: any) {
    return this.http.get(
      `${this.api}academy/api/training-report/team-stats/?page_size=200&team=${team}&start_date=${startDate}&end_date=${end_date}`
    );
  }

  getAllTeamsStats(tournament: any, startDate: any, end_date: any) {
    return this.http.get(
      `${this.api}academy/api/summary-team-report/get_match_summary_report/?&tournament=${tournament}&start_date=${startDate}&end_date=${end_date}`
    );
  }

  getCalender(year: any, month: any, coach: any, team: any) {
    return this.http.get(
      `${this.api}academy/api/calender/?year=${year}&month=${month}&coach=${coach}&team=${team}&page_size=60`
    );
  }

  getDetailedDayEvent(year: any, month: any, day: any, coach: any, team: any) {
    return this.http.get(
      `${this.api}academy/api/calender/detailed_day_events/?year=${year}&month=${month}&day=${day}&coach=${coach}&team=${team}&page_size=60`
    );
  }

  getTopVisitedProfiles(startDate: any, endDate: any) {
    return this.http.get(
      `${this.api}academy/api/users/?user_types=playeruser&start_date=${startDate}&end_date=${endDate}&ordering=-calculated_visits_count&page_size=8`
    );
  }

  getTopInteractiveProfiles(startDate: any, endDate: any, user: any) {
    return this.http.get(
      `${this.api}academy/api/users/?user_types=${user}&start_date=${startDate}&end_date=${endDate}&ordering=-total_interactions_count&page_size=8`
    );
  }
}
