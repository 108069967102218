import { DashBordEndPointsService } from './../../services/dash-bord-end-points.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { StatsService } from 'src/app/services/StatsServices/stats-services.service';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss'],
})
export class AttendanceComponent implements OnInit {
  labelArray: any = [];
  coachArray: any = [];
  playerArray: any = [];
  parentArray: any = [];
  totalCoaches = 0;
  totalPlayers = 0;
  totalParents = 0;

  generalStats: any;
  selectedYear = '2023';
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined =
    undefined;

  @Input() attendanceType: any = '';
  @Input() from: any;
  @Input() to: any;
  @Input() teamId: any;
  @Input() userID: any;
  @Input() tournament: any;

  tableDate: any;
  chartData: any;

  constructor(
    private _DashBordEndPointsService: DashBordEndPointsService,
    private _stats: StatsService
  ) {}

  ngOnInit(): void {
    console.log(this.attendanceType);

    // if (this.attendanceType == 'trainig') {
    // Table Part
    this._DashBordEndPointsService
      .getPlayerAttendancesTable(
        this.userID,
        this.teamId,
        this.from,
        this.to,
        this.attendanceType,
        this.tournament
      )
      .subscribe((res: any) => {
        this.tableDate = res.results;
        console.log(this.tableDate);
      });

    // Chart Party
    this._DashBordEndPointsService
      .getPlayerAttendancesChart(
        this.userID,
        this.teamId,
        this.from,
        this.to,
        this.attendanceType,
        this.tournament
      )
      .subscribe((res: any) => {
        this.chartData = res;
        console.log(this.chartData);
      });
    // } else {
    //   // Match Part
    //   // Table Part
    //   this._DashBordEndPointsService
    //     .getPlayerAttendancesTable(
    //       this.userID,
    //       this.teamId,
    //       this.from,
    //       this.to,
    //       'match'
    //     )
    //     .subscribe((res: any) => {
    //       this.tableDate = res.results;
    //       console.log(res);
    //     });

    //   // Chart Party
    //   this._DashBordEndPointsService
    //     .getPlayerAttendancesChart(
    //       this.userID,
    //       this.teamId,
    //       this.from,
    //       this.to,
    //       'match'
    //     )
    //     .subscribe((res: any) => {
    //       this.chartData = res;
    //       console.log(res);
    //     });
    // }
    this.updateTheQraph();
  }

  updateTheQraph() {
    this.resetTheQraph();
    this._DashBordEndPointsService.getPlayerAttendancesChart(
      this.userID,
      this.teamId,
      this.from,
      this.to,
      'match',
      ''
    );
    // .subscribe(
    //   (res: any) => {
    //     res.coach.forEach((x: any) => {
    //       this.labelArray.push(x.month);
    //       this.coachArray.push(x.count);
    //       this.totalCoaches = this.totalCoaches + x.count;
    //     });
    //     res.parent.forEach((x: any) => {
    //       this.parentArray.push(x.count);
    //       this.totalParents = this.totalParents + x.count;
    //     });
    //     res.player.forEach((x: any) => {
    //       this.playerArray.push(x.count);
    //       this.totalPlayers = this.totalPlayers + x.count;
    //     });
    //   },
    //   (err: any) => {},
    //   () => {
    //     this.barChartData.labels = this.labelArray;
    //     this.barChartData.datasets[0].data = this.playerArray;
    //     this.barChartData.datasets[1].data = this.parentArray;
    //     this.barChartData.datasets[2].data = this.coachArray;

    //     this.chart?.update();
    //   }
    // );
  }
  resetTheQraph() {
    this.labelArray = [];
    this.coachArray = [];
    this.playerArray = [];
    this.parentArray = [];
    this.totalCoaches = 0;
    this.totalPlayers = 0;
    this.totalParents = 0;
  }
  public barChartType: ChartType = 'line';

  public barChartOptions: any = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    // scales: {
    //   x: {},
    //   y: {},
    // },
    // plugins: {
    //   legend: {
    //     display: false,
    //   },
    //   datalabels: {
    //     anchor: 'end',
    //     align: 'end',
    //   },
    // },
  };

  public barChartData: ChartData<'bar'> = {
    labels: [
      'Apr',
      'Apr',
      'Apr',
      'Apr',
      'Apr',
      'Apr',
      'Apr',
      'Apr',
      'Apr',
      'Apr',
      'Apr',
      'Apr',
    ],
    datasets: [{ data: [65, 59, 80, 81, 56, 55, 40] }],
  };
}
