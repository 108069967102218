import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-staff-card',
  templateUrl: './staff-card.component.html',
  styleUrls: ['./staff-card.component.scss'],
})
export class StaffCardComponent implements OnInit {
  @Input() coachData: any;
  constructor(private router: Router) {}

  ngOnInit(): void {
    console.log(this.coachData);
  }

  navtoInfoPage() {
    this.router.navigate(['/coachProfile'], {
      queryParams: { coachData: JSON.stringify(this.coachData) },
    });
  }
}
