import { DashDomain } from './DashBoardDomain';
import { domain } from './DomainUrl';

export const STATSENDPOINTS = {
  user: {
    url: (date_range: any, userType: any) =>
      `${DashDomain}academy/api/statistics/users/?date_range=${date_range}&user_type=${userType}`,
  },
  teams: {
    url: (date: any) =>
      `${DashDomain}academy/api/statistics/teams/?date_range=${date}`,
  },
  platForm: {
    url: (date: any, interactionUser: any) =>
      `${DashDomain}academy/api/statistics/platform/?date_range=${date}&interactions_user_type=${interactionUser}`,
  },
  charts: {
    url: (date: any) =>
      `${DashDomain}academy/api/statistics/users/chart-data/?year=${date}`,
  },
  video: {
    url: (date: any) =>
      `${DashDomain}academy/api/posts/?type=video&top_viewed_date_range=${date}&ordering=views&page_size=6`,
  },
  usersTopVisited: {
    url: (date: any) =>
      `${DashDomain}academy/api/users/?top_visited_date_range=${date}&page_size=8&user_types=playeruser,coachuser`,
  },
  usersTopInteractive: {
    url: (date: any, type: string) =>
      `${DashDomain}academy/api/users/?top_interactive_date_range=${date}&page_size=8&user_types=${type}`,
  },
};
