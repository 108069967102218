<div class="row bread-crumb">
    <div class="col-md-6 d-flex order-1 order-md-2 justify-content-md-end">
        <nav aria-label="breadcrumb" class="float-right mt-1">
            <ol class="breadcrumb">
                <ng-container *ngFor="let item of breadcrumbItems">
                    <li *ngIf="!item.active" class="breadcrumb-item links">
                        <a routerLink="{{item.path}}">{{ item.label}}</a>
                    </li>
                    <li *ngIf="item.active" class="breadcrumb-item active">{{ item.label }}</li>
                </ng-container>
            </ol>
        </nav>


    </div>
    <div class="col-md-6 order-2 order-md-1">
        <h4 class="mb-1 mt-0 breadcrumb-title">{{ title }}</h4>
    </div>
</div>