import { Component, OnInit } from '@angular/core';
import { StatsService } from 'src/app/services/StatsServices/stats-services.service';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss'],
})
export class TeamsComponent implements OnInit {
  teamStats: any;
  constructor(private _stats: StatsService) {}

  breadcrumb = [
    { label: 'Dashboard', path: '', active: false },
    { label: 'Academy', path: '#', active: false },
    { label: 'Teams', path: '/teams', active: true },
  ];

  ngOnInit(): void {
    this._stats.teamStats('week').subscribe((res: any) => {
      this.teamStats = res;
    });
  }
}
