<div class="row mb-3">
  <h6>
    {{
      academyName ? "Academy Reports/Coaches/" : "Academy Reports / Coaches / "
    }}
    <span>
      {{ coachID != "all" ? coachData[0].username : " All Coaches " }}
    </span>
  </h6>
</div>
<div class="card row" *ngIf="coachID != 'all'">
  <div class="col-6 p-0 m-0 d-flex flex-row gap-3">
    <div *ngIf="coachData[0].picture">
      <img
        style="width: 310px; object-fit: cover; height: 360px"
        [src]="coachData[0].picture"
        alt="couch picture"
      />
    </div>
    <div *ngIf="!coachData[0].picture">
      <img
        style="width: 310px; object-fit: cover; height: 360px"
        src="assets/images/coachuser.jpg"
        alt="couch picture"
      />
    </div>
    <div class="couch-caption mt-3">
      <h4>{{ coachData[0].username }}</h4>
      <h6 class="couch-name">{{ coachData[0].job }}</h6>
      <p>{{ coachData[0].age ? coachData[0].age : "33 " }} Years</p>
      <div class="line"></div>
      <div
        class="manged-teams d-flex flex-row align-items-center gap-2 mb-3"
        *ngFor="let team of coachData[0].managed_teams"
      >
        <img
          src="assets/icons/academyLogo.png"
          alt="academyLogo"
          style="width: 16px; height: 16px"
        />
        <p class="m-0">{{ team }}</p>
      </div>
    </div>
  </div>

  <div class="col-6 pt-3 m-0">
    <div class="row g-4">
      <div class="col-12">
        <div
          class="total-container d-flex flex-row justify-content-around px-3 py-3"
        >
          <div class="total-matches-container my-auto">
            <h5>{{ coachData[0].number_of_matches_played }}</h5>
            <p>Matches</p>
          </div>
          <div>
            <img
              src="assets/icons/Matches Played.png"
              alt=" Matches Played"
              style="width: 103px"
            />
          </div>
        </div>
      </div>
      <div class="col-4">
        <div
          class="total-container d-flex flex-row justify-content-around py-3"
        >
          <div class="total-matches-container my-auto">
            <h5>{{ coachData[0].number_of_wins }}</h5>
            <p>Wins</p>
          </div>
          <div>
            <img src="assets/icons/win.png" alt=" win" style="width: 60px" />
          </div>
        </div>
      </div>
      <div class="col-4">
        <div
          class="total-container d-flex flex-row justify-content-around py-3"
        >
          <div class="total-matches-container my-auto">
            <h5>{{ coachData[0].number_of_losses }}</h5>
            <p>Losses</p>
          </div>
          <div>
            <img
              src="assets/icons/losses.png"
              alt=" losses"
              style="width: 60px"
            />
          </div>
        </div>
      </div>
      <div class="col-4">
        <div
          class="total-container d-flex flex-row justify-content-around py-3"
        >
          <div class="total-matches-container my-auto">
            <h5>{{ coachData[0].number_of_draws }}</h5>
            <p>Draws</p>
          </div>
          <div>
            <img
              src="assets/icons/draws.png"
              alt=" draws"
              style="width: 60px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="px-5 couch-info mt-5 pb-5" *ngIf="coachID != 'all'">
  <h6>About</h6>
  <div class="second-line"></div>
  <p>
    {{ coachData[0].bio ? coachData[0].bio : "-" }}
  </p>

  <div class="second-line"></div>

  <div class="personal-info mt-5">
    <h6>Personal Information</h6>
    <div class="d-flex info mt-5">
      <div class="d-flex flex-column gap-4 info-title">
        <p>Name</p>
        <p>Academy</p>
        <p>Job</p>
        <p>Age</p>
        <p>Email</p>
        <p>Location</p>
      </div>
      <div class="d-flex flex-column ml-5 mr-2 gap-4 info-title-two">
        <p>:</p>
        <p>:</p>
        <p>:</p>
        <p>:</p>
        <p>:</p>
        <p>:</p>
      </div>
      <div class="d-flex flex-column gap-4 info-title-three">
        <p>{{ coachData[0].username }}</p>
        <p>{{ coachData[0].academy }}</p>
        <p>{{ coachData[0].job }}</p>
        <p>{{ coachData[0].age ? coachData[0].age : "33" }}</p>
        <p>{{ coachData[0].email }}</p>
        <p>{{ coachData[0].phone ? coachData[0].phone : "012 578 789 42" }}</p>
      </div>
    </div>
  </div>
</div>

<div *ngIf="coachID == 'all'">
  <div class="coachCard px-4 align-items-center py-4 ml-4">
    <div>
      <h1>{{ coachNumber }}</h1>
      <h2>Total Coaches</h2>
    </div>

    <img
      src="assets/icons/couchIcon.png"
      style="width: 100px"
      alt="couchIcon"
    />
  </div>

  <div class="row mt-3">
    <h2 class="table-header-text mt-5">Academy Coaches</h2>
    <table>
      <tr>
        <th>Coach name</th>
        <th>No. Of teams managed</th>
        <th>teams name</th>
        <th>Contact Number</th>
      </tr>
      <tr *ngFor="let coach of coachData">
        <td class="coachName">{{ coach.username }}</td>
        <td class="numberOfTeams">{{ coach.managed_teams_count }}</td>
        <td class="numberOfTeams">
          <span *ngFor="let team of coach.managed_teams; let i = index"
            >{{ team }} {{ i + 1 != coach.managed_teams.length ? "/" : "" }}
          </span>
        </td>
        <td class="phone">{{ coach.phone }}</td>
      </tr>
    </table>
  </div>
</div>
