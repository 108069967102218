import { Component, Input, OnInit } from '@angular/core';
import { DashBordEndPointsService } from 'src/app/services/dash-bord-end-points.service';

@Component({
  selector: 'app-coaches-feedback',
  templateUrl: './coaches-feedback.component.html',
  styleUrls: ['./coaches-feedback.component.scss'],
})
export class CoachesFeedbackComponent implements OnInit {
  @Input() from: any;
  @Input() to: any;
  @Input() userID: any;
  tableContent: any;
  cardsContent: any;
  constructor(private _DashBordEndPointsService: DashBordEndPointsService) {}

  ngOnInit(): void {
    this._DashBordEndPointsService
      .getEvaluationSummary(this.userID, this.from, this.to)
      .subscribe((res: any) => {
        this.cardsContent = res;
        console.log(this.cardsContent);
      });

    this._DashBordEndPointsService
      .getEventWithEvaluation(this.userID, this.from, this.to)
      .subscribe((res: any) => {
        this.tableContent = res.results;
        console.log(this.tableContent, 'table');
      });
  }
}
