<div class="row mb-4">
  <h6>
    <span>
      {{
        gender
          ? gender == "male"
            ? "Boys"
            : "Girls"
          : teamID
          ? playreData[0]?.team
          : "Academy Reports / Players / All Players /"
      }}
    </span>
    {{ academyName }}
  </h6>
</div>
<div class="row mb-4">
  <!-- <div class="mr-4" *ngIf="teamID">
    <h3>{{ playreData[0]?.team }}</h3>
    <h4>Coach name:<span> Abdilhalem Ali</span></h4>
  </div> -->

  <div class="card px-5 align-items-center py-4 ml-2 flex-row" *ngIf="gender">
    <div>
      <h5>{{ filterNumber }}</h5>
      <h2>{{ gender == "male" ? "Boys" : "Girls" }}</h2>
    </div>
    <img
      src="assets/icons/allPlayers.png"
      style="width: 100px"
      alt="couchIcon"
    />
  </div>
  <div class="card px-5 align-items-center py-4 ml-2 flex-row">
    <div>
      <h1>{{ gender ? totalNumber : filterNumber }}</h1>
      <h2>Total Players</h2>
    </div>

    <img
      src="assets/icons/allPlayers.png"
      style="width: 100px"
      alt="couchIcon"
    />
  </div>
</div>

<div class="row">
  <h2 class="table-header-text mt-5">Academy Players</h2>

  <table>
    <tr>
      <th>Player name</th>
      <th *ngIf="!teamID">Team name</th>
      <th *ngIf="teamID">Shirt number</th>
      <th>Position</th>
      <th>Age</th>
      <th>Gender</th>
      <th>Attribute score</th>
    </tr>
    <tr *ngFor="let record of playreData">
      <td class="userName">{{ record?.username }}</td>
      <td class="teamName" *ngIf="!teamID">{{ record?.team }}</td>
      <td class="teamName" *ngIf="teamID">{{ record?.shirt_number }}</td>
      <td class="primaryPosition">{{ record?.primary_position }}</td>
      <td class="age">{{ record?.age }}</td>
      <td class="gender">{{ record?.gender }}</td>
      <td class="attributeScore">{{ record?.attribute_score }}</td>
    </tr>
  </table>
</div>
