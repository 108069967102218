<app-breadcrumb
  [breadcrumbItems]="breadcrumb"
  title="Calendar"
></app-breadcrumb>

<div class="row mt-5 justify-content-between gap d-flex">
  <div class="flex1 bg-white p-3 py-5">
    <h1 class="fs22">Filters</h1>
    <select name="Branch" disabled id="Branch" class="mt-4">
      <option value="" disabled selected>All</option>
    </select>

    <select
      name="Coaches"
      id="Coaches"
      class="mt-4"
      (change)="selectCoach()"
      [(ngModel)]="selectedCoaches"
    >
      <option value="" selected disabled>Coaches</option>
      <option value="">All</option>
      <option *ngFor="let coach of allCoaches" [value]="coach.id">
        {{ coach.first_name }} {{ coach.last_name }}
      </option>
    </select>

    <select
      name="Teams"
      id="Teams"
      class="mt-4"
      (change)="selectTeam()"
      [(ngModel)]="selectedTeam"
    >
      <option value="" disabled selected>Team</option>
      <option value="">All</option>
      <option *ngFor="let team of allTeams" [value]="team.id">
        {{ team.name }}
      </option>
    </select>
  </div>
  <div class="flex3 bg-white">
    <div class="calendar-container p-3 my-2">
      <div class="calendar-header mb-3">
        <button (click)="prevMonth()">&lt;</button>
        <h2 class="fs22">{{ currentDate | date : "MMMM y" }}</h2>
        <button (click)="nextMonth()">&gt;</button>
      </div>
      <!-- <select
            [(ngModel)]="currentYear"
            (ngModelChange)="onYearChange()"
            class="year-dropdown"
          >
            <option *ngFor="let year of getYears()" [ngValue]="year">{{ year }}</option>
          </select> -->

      <table class="calendar-table">
        <thead>
          <tr>
            <th *ngFor="let day of days" class="day-header fs18">{{ day }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let week of getWeeks()">
            <ng-container *ngFor="let day of week">
              <td
                [class]="day.isCurrentMonth ? 'day-cell' : 'day-cell dimmed'"
                (click)="clickOnDay(day)"
              >
                <div class="row d-flex dayNumber fs16">
                  {{ day.date | date : "d" }}
                </div>
                <div class="d-flex icon">
                  <img
                    src="../../../assets/icons/pitch.png"
                    width="30"
                    *ngIf="pitchThisDay(day)"
                  />
                  <img
                    src="../../../assets/icons/clock.png"
                    width="30"
                    *ngIf="clockThisDay(day)"
                  />
                </div>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
