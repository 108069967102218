import { Component, OnInit } from '@angular/core';
import { Academy } from 'src/app/Models/Academy';

@Component({
  selector: 'app-academies',
  templateUrl: './academies.component.html',
  styleUrls: ['./academies.component.scss']
})
export class AcademiesComponent implements OnInit {

  search!: string;
  academyTemp: Academy = {
    name: "Wadi Degla",
    imageUrl: 'assets/image/club-logo.png',
    users: 255,
    like: 2580,
    shares: 231,
    comments: 58,
    views: 1700
  }

  constructor() { }

  ngOnInit(): void {
  }

  periodChangeHandler(event: any) {
    // console.log(event)
  }
}
