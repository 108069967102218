<div class="container-fluid">
  <div class="row">
    <div
      class="loginCover col-lg-8 col-12 d-flex justify-content-center align-items-center mb-4 mb-lg-0"
    >
      <div
        id="carouselExampleInterval"
        class="carousel slide carousel-fade"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active" data-bs-interval="2000">
            <img src="assets/images/BG.png" class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item" data-bs-interval="2000">
            <img src="assets/images/BG.png" class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item" data-bs-interval="2000">
            <img src="assets/images/BG.png" class="d-block w-100" alt="..." />
          </div>
        </div>
        <div class="sliderText">
          <h1>ScoutX</h1>
          <h2>THE UJTIMATE DIGITAL SPORTS PLARFORM.</h2>
        </div>
        <!-- <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button> -->
      </div>
    </div>
    <div
      class="loginForm col-lg-4 col-12 d-flex flex-column justify-content-center align-items-center order-lg-0 order-1"
    >
      <div class="w-75">
        <div class="text-center">
          <img
            src="assets/images/scoutxLogoTran.png"
            alt="scoutX logo"
            class="w-50"
          />
          <img src="assets/images/plus.png" alt="scoutX logo" />
        </div>
        <div class="text-center mb-4">
          <h1 class="fs22">Login into Your Account</h1>
        </div>
        <form #form="ngForm" (ngSubmit)="onLogin(form)">
          <!-- Email -->
          <label>Email ID*</label>

          <input
            class="input"
            type="email"
            placeholder="Email"
            name="email"
            ngModel
            required
            email
          />

          <!-- Password -->
          <label>Password*</label>

          <input
            class="input"
            type="password"
            placeholder="Password"
            name="password"
            ngModel
            required
          />

          <div *ngIf="isError" class="error">
            <p>Wrong email or password.</p>
          </div>

          <button class="btn btn-block btn-primary">Login</button>
        </form>
      </div>
    </div>
  </div>
</div>
