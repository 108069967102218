import { Component, Input, OnInit } from '@angular/core';
import { DashBordEndPointsService } from 'src/app/services/dash-bord-end-points.service';
import { MobileEndPointsService } from 'src/app/services/mobileEndPoints/mobile-end-points.service';

@Component({
  selector: 'app-academy-teams',
  templateUrl: './academy-teams.component.html',
  styleUrls: ['./academy-teams.component.scss'],
})
export class AcademyTeamsComponent implements OnInit {
  @Input() type: any = '';
  @Input() team: any = '';
  @Input() tournament: any = '';
  @Input() fromTime: any = '';
  @Input() toTime: any = '';

  academyName = localStorage.getItem('academyName');
  trainigsNumber = 0;
  matchesNumber = 0;
  dataTable: any;
  playersNumber = 0;
  boysTeams = 0;
  girlsTeams = 0;
  avgMatchgRate = 0;
  numberOfLoses = 0;
  numberOfWins = 0;
  numberOfDraws = 0;
  avgTrainingRate = 0;
  allTeams: any;
  constructor(
    private _DashBordEndPointsService: DashBordEndPointsService,
    private _MobileEndPointsService: MobileEndPointsService
  ) {}

  ngOnInit(): void {
    this._MobileEndPointsService.getPlayers().subscribe((res: any) => {
      this.playersNumber = res.count;
      console.table(this.playersNumber);
    });

    this._DashBordEndPointsService
      .getAllTeamsStats(this.tournament, this.fromTime, this.toTime)
      .subscribe((res: any) => {
        this.allTeams = res;
        console.table(this.allTeams);
      });

    this._DashBordEndPointsService
      .getNumberOfGenderTeam()
      .subscribe((res: any) => {
        this.boysTeams = res.boys_teams;
        this.girlsTeams = res.girls_teams;
        console.table(this.boysTeams);
        console.table(this.girlsTeams);
      });

    this._DashBordEndPointsService
      .getMatchReportStats(
        this.team,
        this.tournament,
        this.fromTime,
        this.toTime
      )
      .subscribe((res: any) => {
        this.avgMatchgRate = res.coach_rate;
        this.numberOfWins = res.win_matches;
        this.numberOfLoses = res.lose_matches;
        this.numberOfDraws = res.draw_matches;
        console.table(res);
        console.table(this.avgMatchgRate);
        console.table(this.numberOfWins);
        console.table(this.numberOfLoses);
        console.table(this.numberOfDraws);
      });

    this._DashBordEndPointsService
      .getTrainingReportStats(this.team, this.fromTime, this.toTime)
      .subscribe((res: any) => {
        this.avgTrainingRate = res.coach_rate;
        console.table(this.avgTrainingRate);
      });

    if (this.type == 'training') {
      this._DashBordEndPointsService
        .getAcademyTraining(this.team, this.fromTime, this.toTime)
        .subscribe((res: any) => {
          this.dataTable = res.results;
          this.trainigsNumber = res.count;
          console.table(this.dataTable);
        });
    } else {
      this._DashBordEndPointsService
        .getAcademyMatches(
          this.team,
          this.tournament,
          this.fromTime,
          this.toTime
        )
        .subscribe((res: any) => {
          this.dataTable = res.results;
          this.matchesNumber = res.count;
          console.table(this.dataTable);
          console.table(this.matchesNumber);
        });
    }
  }
}
