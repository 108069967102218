<div class="academy-stats">
  <!-- date filter -->

  <app-breadcrumb
    [breadcrumbItems]="breadcrumb"
    title="Academy Stats"
  ></app-breadcrumb>

  <div class="row" id="TopOfPage">
    <div class="col-md-5">
      <div class="mb-3">
        <app-period-selection-bar
          (periodChange)="periodChangeHandler($event)"
        ></app-period-selection-bar>
      </div>
    </div>

    <div class="col-md-7">
      <div class="row">
        <div
          class="col-md-4 text-center statNav"
          (click)="scrollTO('userStatsDiv')"
        >
          Users Statistics
          <div class="dot m-auto hidden"></div>
        </div>

        <div
          class="col-md-4 text-center statNav"
          (click)="scrollTO('teamStatsDiv')"
        >
          Teams Statistics
          <div class="dot m-auto hidden"></div>
        </div>

        <div
          class="col-md-4 text-center statNav"
          (click)="scrollTO('platformStatsDiv')"
        >
          Platform Statistics
          <div class="dot m-auto hidden"></div>
        </div>
      </div>
    </div>

    <div class="row" id="TopOfPage">
      <div class="col-md-5">
        <div class="mb-3">
          <app-period-selection-bar
            (periodChange)="periodChangeHandler($event)"
          ></app-period-selection-bar>
        </div>
      </div>

      <div class="col-md-7">
        <div class="row">
          <div
            class="col-md-4 text-center statNav"
            (click)="scrollTO('userStatsDiv')"
          >
            Users Statistics
            <div class="dot m-auto hidden"></div>
          </div>

          <div
            class="col-md-4 text-center statNav"
            (click)="scrollTO('teamStatsDiv')"
          >
            Teams Statistics
            <div class="dot m-auto hidden"></div>
          </div>

          <div
            class="col-md-4 text-center statNav"
            (click)="scrollTO('platformStatsDiv')"
          >
            Platform Statistics
            <div class="dot m-auto hidden"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- user stats -->
    <div class="users" id="userStatsDiv">
      <h1 class="m-0 mt-5">
        <div class="dot"></div>
        Users Statistics
      </h1>
      <!-- NEW  -->
      <div class="card">
        <h1>Select Branch</h1>
        <select name="Branch" id="Branch">
          <option value="" disabled selected>Select</option>
          <option value="match">Match</option>
          <option value="training">Training</option>
        </select>
      </div>

      <div class="card">
        <img src="assets/icons/foot.png" />
        <h2>Players: <span>75</span></h2>
      </div>
      <div class="card">
        <img src="assets/icons/whistle.png" />
        <h2>Players: <span>75</span></h2>
      </div>
      <div class="card">
        <img src="assets/icons/ParentAndSon.png" />
        <h2>Players: <span>75</span></h2>
      </div>

      <div class="card">
        <div class="flexRow">
          <h1>Users Statistics</h1>
          <select name="Branch" id="Branch">
            <option value="" disabled selected>Select</option>
            <option value="match">Match</option>
            <option value="training">Training</option>
          </select>
        </div>
        <div></div>
      </div>
      <!-- done New -->
      <div class="row">
        <ng-container *ngIf="userStats">
          <div class="col-md-4 d-flex justify-content-between flex-column my-2">
            <div class="mb-1">
              <app-stats-card
                [number]="userStats.new_players_count"
                [description]="'New Players'"
                [name]="'appUserStats'"
                [state]="'increase'"
                [textColor]="'#56C662'"
                [isColoredIcon]="false"
                [total]="userStats.total_players_count"
                [totalText]="'Total Players'"
              ></app-stats-card>
            </div>
            <div class="mb-1">
              <app-stats-card
                [number]="userStats.new_parents_count"
                [description]="'New Parents'"
                [name]="'appUserStats'"
                [state]="'increase'"
                [textColor]="'#56C662'"
                [isColoredIcon]="false"
                [total]="userStats.total_parents_count"
                [totalText]="'Total Parents'"
              ></app-stats-card>
            </div>
            <div class="mb-1">
              <app-stats-card
                [number]="userStats.new_coaches_count"
                [description]="'New Coaches'"
                [name]="'appUserStats'"
                [state]="'decrease'"
                [textColor]="''"
                [isColoredIcon]="false"
                [total]="userStats.total_coaches_count"
                [totalText]="'Total Coaches'"
              ></app-stats-card>
            </div>
            <app-stats-card
              [number]="userStats.new_admins_count"
              [description]="'New Admins'"
              [name]="'appUserStats'"
              [state]="'decrease'"
              [textColor]="''"
              [isColoredIcon]="false"
              [total]="userStats.total_admins_count"
              [totalText]="'Total Admins'"
            >
            </app-stats-card>
          </div>
        </ng-container>

        <ng-container *ngIf="chartData">
          <div class="col-md-8 my-2">
            <!-- <app-bar-chart
            [title]="'New Users Chart'"
            [Icon]="'download'"
            [isUserChart]="true"
            [xAxisTitle]="xAxisTitle"
            [barChartLabels]="x_Axis"
            [data]="y_Axis"
            (UserTypeChange)="changeUserType($event)"
          >
          </app-bar-chart> -->
          </div>
        </ng-container>
      </div>

      <!-- team stats -->
      <ng-container *ngIf="teamStats">
        <div class="users" id="teamStatsDiv">
          <h1 class="m-0 mt-5">
            <div class="dot"></div>
            Teams Statistics
          </h1>
          <div class="row">
            <div class="col-md-4 my-2 my-lg-0">
              <div class="mb-1">
                <app-info-sticker
                  [number]="teamStats.matches_count"
                  [description]="'Total Matches'"
                  [iconName]="'soccer'"
                  [pageClasses]="'tabletView'"
                >
                </app-info-sticker>
              </div>
              <div class="mb-1">
                <app-info-sticker
                  [number]="teamStats.trainings_count"
                  [description]="'Total Trainings'"
                  [iconName]="'training'"
                  [pageClasses]="'tabletView'"
                >
                </app-info-sticker>
              </div>
              <!-- <app-info-sticker [number]="teamStats.tournaments_count" [description]="'Total Tournaments'"
                        [iconName]="'tour'">
                    </app-info-sticker> -->
            </div>

            <div
              class="col-md-8 d-flex justify-content-between flex-column mb-2"
            >
              <app-info-sticker
                [number]="teamStats.teams_count"
                [description]="'Teams'"
                [iconName]="'video'"
                [hasImage]="true"
                [ImagePath]="'assets/images/TeamsIcon.png'"
              >
              </app-info-sticker>

              <div class="row mx-0" style="flex-grow: 1">
                <div class="col-md-6 p-0 my-2">
                  <div class="team-table">
                    <div class="row mx-0">
                      <div class="col-2">
                        <div class="counter text-center">
                          {{ teamStats.boys_teams_count }} <br />
                          Boys
                        </div>
                      </div>
                      <div class="col-9">
                        <div class="counter text-center">
                          {{ teamStats.boys_teams_players_count }} Players
                        </div>
                      </div>
                    </div>

                    <div class="p-3">
                      <ng-container
                        *ngFor="
                          let team of teamStats.boys_teams_players_count_dict_arr
                        "
                      >
                        <div class="team">
                          {{ team.name }}:
                          <span>{{ team.players_count }}</span>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 p-0 my-2">
                  <div class="team-table">
                    <div class="row mx-0">
                      <div class="col-2">
                        <div class="counter text-center">
                          {{ teamStats.girls_teams_count }} <br />
                          Girls
                        </div>
                      </div>
                      <div class="col-9">
                        <div class="counter text-center">
                          {{ teamStats.girls_teams_players_count }} Players
                        </div>
                      </div>
                    </div>
                    <div class="p-3">
                      <ng-container
                        *ngFor="
                          let team of teamStats.girls_teams_players_count_dict_arr
                        "
                      >
                        <div class="team">
                          {{ team.name }}:
                          <span>{{ team.players_count }}</span>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="platForms">
        <div class="users" id="platformStatsDiv">
          <h1 class="m-0 mt-5">
            <div class="dot"></div>
            Platform Statistics
          </h1>
          <div class="row">
            <div class="col-md-4">
              <app-info-sticker
                [number]="platForms.total_videos"
                [description]="'Total Videos'"
                [iconName]="'video'"
                [pageClasses]="'tabletView'"
              >
              </app-info-sticker>
            </div>

            <div class="col-md-4">
              <app-info-sticker
                [number]="platForms.total_videos_views"
                [description]="'Total Views'"
                [iconName]="'eye'"
                [pageClasses]="'tabletView'"
              >
              </app-info-sticker>
            </div>
          </div>

          <div class="col-md-4">
            <app-info-sticker
              [number]="platForms.total_videos_views"
              [description]="'Total Views'"
              [iconName]="'eye'"
              [pageClasses]="'tabletView'"
            >
            </app-info-sticker>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-xl-4 col-md-6 col-12">
            <!-- <app-doughunt-chart
            [Icon]="'click'"
            [title]="'Total Interactions'"
            [data]="[platForms.total_likes, platForms.total_comments]"
            [doughnutChartLabels]="['Likes', 'Comments']"
            [selectors]="true"
            (typeChange)="userTypeChangeDoughnut($event)"
            [totalInterActions]="platForms.total_interactions"
          ></app-doughunt-chart> -->
          </div>
          <!-- <div class="col-xl-4 col-md-6 col-12">
                <app-doughunt-chart [Icon]="'ads'" [title]="'Ads Interactions'" [data]="[120,32,80]"
                    [doughnutChartLabels]="['Likes', 'Shares', 'Comments']"></app-doughunt-chart>

            </div> -->
          <div class="col-xl-4 col-md-6 col-12">
            <!-- <app-doughunt-chart
            [Icon]="'coach'"
            [title]="'Total Coaches Activities'"
            [data]="[
              platForms.coaches_instructions_count,
              platForms.coaches_evaluations_count
            ]"
            [doughnutChartLabels]="['Instructions', 'Evaluations']"
          ></app-doughunt-chart> -->
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
