import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { domain } from '../EndPoints/DomainUrl';

@Injectable({
  providedIn: 'root',
})
export class MobileEndPointsService {
  api = domain;

  constructor(private http: HttpClient) {}

  getCoaches() {
    return this.http.get(
      `${
        this.api
      }coachapp/coaches/?page_size=200&&academy=${localStorage.getItem(
        'academy'
      )}`
    );
  }

  getTeams() {
    return this.http.get(
      `${this.api}teams/?page_size=200&&academy=${localStorage.getItem(
        'academy'
      )}`
    );
  }

  getPlayerByID(playerID: number) {
    return this.http.get(`${this.api}playerapp/players/${playerID}/`);
  }

  getPlayers() {
    return this.http.get(`${this.api}playerapp/players/?page_size=200`);
  }

  getTeamPlayers(teamId: number) {
    return this.http.get(
      `${this.api}playerapp/players/?team=${teamId}&&page_size=200`
    );
  }

  getEvaluationAttributeCategories(position: string) {
    return this.http.get(
      `${this.api}evaluation-attribute-categories/?player_position_type=${position}`
    );
  }

  getAllEvaluationAttribute() {
    return this.http.get(`${this.api}evaluation-attribute-categories/`);
  }

  getAllTournaments() {
    return this.http.get(`${this.api}tournaments/?page_size=200`);
  }
}
