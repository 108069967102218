<div class="row" style="min-height: 100px;height: 100%;">
    <div class="col-lg-8 p-0" [ngClass]="{'col-md-10': total==''}">
        <section class="d-flex mr-1 mb-md-0 mb-2" style="height: 100%;">
            <div class="infoSticker  d-flex flex-row  align-items-center shadow-sm justify-content-around statsCard">
                <ng-container *ngIf="!shownIconName">
                    <app-icon [name]="iconName" [isColored]="isColoredIcon" [width]="iconWidth" [coloredIcon]="state">
                    </app-icon>
                </ng-container>
                <ng-container *ngIf="shownIconName">
                    <app-icon [name]="shownIconName" [isColored]="isColoredIcon" [width]="iconWidth"
                        [coloredIcon]="state"></app-icon>
                </ng-container>
                <div class="infoData descriptionDiv mr-5 pl-3">
                    <b class="description text-center" *ngIf="!isAnalytic" [ngStyle]="{color:textColor}">{{number}}</b>
                    <h1 class="description">{{description}}</h1>
                </div>

                <input type="date" *ngIf="isAnalytic">
            </div>
        </section>
    </div>
    <div class="col-lg-4 my-lg-0 my-2 p-0">
        <div class="total d-flex flex-row align-items-center shadow-sm">
            <div class="infoData totalTxtDiv  mr-5 pl-3">
                <b class="totalTxt">{{total}}</b>
                <h1 class="totalTxt">{{totalText}}</h1>
            </div>
        </div>
    </div>
</div>