import { Component, Input, OnInit } from '@angular/core';
import { DashBordEndPointsService } from 'src/app/services/dash-bord-end-points.service';

@Component({
  selector: 'app-coaches-achievers',
  templateUrl: './coaches-achievers.component.html',
  styleUrls: ['./coaches-achievers.component.scss'],
})
export class CoachesAchieversComponent implements OnInit {
  @Input() type: any = '';
  tableData: any;

  constructor(private _DashBordEndPointsService: DashBordEndPointsService) {}

  ngOnInit(): void {
    console.log(this.type);

    if (this.type == 'Instructions') {
      this._DashBordEndPointsService
        .getPreTrainingInstCoachAchivers()
        .subscribe((res: any) => {
          this.tableData = res.results;
        });
    } else if (this.type == 'Evaluation') {
      this._DashBordEndPointsService
        .getEvaluationCoachAchivers()
        .subscribe((res: any) => {
          this.tableData = res.results;
        });
    } else if (this.type == 'Posts') {
      this._DashBordEndPointsService
        .getPostsCoachAchivers()
        .subscribe((res: any) => {
          this.tableData = res.results;
        });
    } else if (this.type == 'Interaction') {
      this._DashBordEndPointsService
        .getInteractionsCoachAchivers()
        .subscribe((res: any) => {
          this.tableData = res.results;
        });
    }
  }
}
