import { Component, OnInit } from '@angular/core';
import { MobileEndPointsService } from 'src/app/services/mobileEndPoints/mobile-end-points.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  attributes: any = {
    1: 'Physical',
    2: 'Mental',
    3: 'Technical',
    5: 'Goalkeeping',
  };
  academyLogo = localStorage.getItem('academylogo');
  reportTypePreview: boolean = false;
  reportPlayer: any;
  ArrayOfData: any = [
    'Performance',
    'Attendance',
    'match',
    'select Tournament',
    'Attribute progress',
    '1',
    '2023-05-01',
    '2023-09-10',
    '12',
    '166',
    'https://scoutxstaging.s3.eu-west-1.amazonaws.com:4…my_logos/9e62c46b-8c3a-4280-b60f-22e00b5df508.jpg',
    'mental',
    'physical',
    'technical',
    'goalkeeping',
  ];

  constructor(private _MobileEndPointsService: MobileEndPointsService) {}

  ngOnInit(): void {
    this.getUserbyId();
  }

  // [ReportType, ReportDetails, attributeCategory, developmentArea, attributeCategory, fromTime, toTime,selectedTeam,selectedPlayer]
  reportProp(data: String) {
    this.reportTypePreview = true;
    this.ArrayOfData = data.split(',');
    this.getUserbyId(this.ArrayOfData[9]);
  }

  getUserbyId(ID = 171) {
    this._MobileEndPointsService.getPlayerByID(ID).subscribe((res: any) => {
      this.reportPlayer = res;
    });
  }

  print() {
    window.print();
  }

  back() {
    this.reportTypePreview = false;
  }
}
