import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashBordEndPointsService } from 'src/app/services/dash-bord-end-points.service';

@Component({
  selector: 'app-calender-day-modal',
  templateUrl: './calender-day-modal.component.html',
  styleUrls: ['./calender-day-modal.component.scss'],
})
export class CalenderDayModalComponent {
  eventInThisDay: any;
  cardDate = new Date();
  isMatch: any;
  isTraining: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dashBoard: DashBordEndPointsService
  ) {
    let day = data.day.date.getDate();
    let month = data.day.date.getMonth() + 1;
    let year = data.day.date.getFullYear();
    this.cardDate.setDate(day);
    this.cardDate.setMonth(month - 1);
    this.cardDate.setFullYear(year);
    this.isMatch = data.isMatch;
    this.isTraining = data.isTrainging;

    this._dashBoard
      .getDetailedDayEvent(year, month, day, data.coach, data.team)
      .subscribe((res: any) => {
        this.eventInThisDay = res.results;
        console.log(res, res);
      });
  }
}
