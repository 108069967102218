<div class="d-flex academy-card custom-shadow p-3" [ngClass]="isColored? 'academy-card--colored':''">
    <div class="club-info col-7 px-0 pr-2">
        <img src="assets/images/club-logo.png" alt="club logo">
        <h1>{{academy.name}}</h1>
        <span>
            <b>{{academy.users}}</b> Users
        </span>
    </div>
    <div class="club-reacts col-5 px-0 pl-2">
        <span>
            <b>{{academy.comments}}</b> Comments
        </span>
        <span>
            <b>{{academy.like}}</b> Likes
        </span>
        <span>
            <b>{{academy.shares}}</b> Shares
        </span>
        <span>
            <b>{{academy.views}}</b> Views
        </span>
    </div>
</div>