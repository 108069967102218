<div class="staff-card" (click)="navtoInfoPage()">
  <div class="img-container" *ngIf="coachData?.picture">
    <img [src]="coachData?.picture" alt="user-image" />
  </div>
  <div class="img-container" *ngIf="!coachData?.picture">
    <img
      *ngIf="!coachData?.picture"
      src="assets/images/coachuser.jpg"
      alt="Coach Image"
      style="object-fit: cover"
    />
  </div>

  <div class="presonal-data mt-2">
    <span class="name">{{ coachData?.username }}</span>
    <span class="age">{{ coachData?.age ? coachData?.age : "-" }}</span>
  </div>

  <div class="summary mt-2">
    <span>{{ coachData?.bio ? coachData?.bio : "-" }}</span>
  </div>

  <div class="teams w-100">
    <p class="mt-3 mb-2">Teams</p>

    <div
      class="d-flex align-items-center flex-wrap px-3 gap-3 justify-content-center minHeight"
      *ngIf="coachData?.managed_teams.length === 0; else notEmpty"
    >
      <p class="team-link mx-3 border-0 text-center">-</p>
    </div>
    <ng-template #notEmpty>
      <div
        class="d-flex align-items-center flex-wrap px-3 justify-content-center gap-3 minHeight"
      >
        <a class="team-link mx-1" *ngFor="let team of coachData?.managed_teams">
          {{ team ? team : "-" }}
        </a>
      </div>
    </ng-template>
  </div>
</div>
