import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { StatsService } from 'src/app/services/StatsServices/stats-services.service';
// import DataLabelsPlugin from 'chartjs-plugin-datalabels';
@Component({
  selector: 'app-users-statistics',
  templateUrl: './users-statistics.component.html',
  styleUrls: ['./users-statistics.component.scss'],
})
export class UsersStatisticsComponent implements OnInit {
  labelArray: any = [];
  coachArray: any = [];
  playerArray: any = [];
  parentArray: any = [];
  totalCoaches = 0;
  totalPlayers = 0;
  totalParents = 0;

  generalStats: any;
  selectedYear = '2024';
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  constructor(private _stats: StatsService) {}

  breadcrumb = [
    { label: 'Dashboard', path: '', active: false },
    { label: 'Academy', path: '#', active: false },
    { label: 'Users Statistics', path: '', active: true },
  ];

  ngOnInit(): void {
    this._stats.userStats('week', '').subscribe((res: any) => {
      this.generalStats = res;
    });

    this.updateTheQraph();
  }

  updateTheQraph() {
    this.resetTheQraph();
    this._stats.chartsData(this.selectedYear).subscribe(
      (res: any) => {
        res.coach.forEach((x: any) => {
          this.labelArray.push(x.month);
          this.coachArray.push(x.count);
          this.totalCoaches = this.totalCoaches + x.count;
        });
        res.parent.forEach((x: any) => {
          this.parentArray.push(x.count);
          this.totalParents = this.totalParents + x.count;
        });
        res.player.forEach((x: any) => {
          this.playerArray.push(x.count);
          this.totalPlayers = this.totalPlayers + x.count;
        });
      },
      (err: any) => {},
      () => {
        this.barChartData.labels = this.labelArray;
        this.barChartData.datasets[0].data = this.playerArray;
        this.barChartData.datasets[1].data = this.parentArray;
        this.barChartData.datasets[2].data = this.coachArray;

        this.chart?.update();
      }
    );
  }

  resetTheQraph() {
    this.labelArray = [];
    this.coachArray = [];
    this.playerArray = [];
    this.parentArray = [];
    this.totalCoaches = 0;
    this.totalPlayers = 0;
    this.totalParents = 0;
  }

  public barChartOptions: any = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {},
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public barChartType: ChartType = 'bar';

  public barChartData: ChartData<'bar'> = {
    labels: [
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
    ],
    datasets: [
      {
        data: [],
        label: 'Players',
        borderColor: '#32582C',
        backgroundColor: '#32582C',
      },
      {
        data: [],
        label: 'Parents',
        borderColor: '#ED6425',
        backgroundColor: '#ED6425',
      },
      {
        data: [],
        label: 'Coaches',
        borderColor: '#955E2A',
        backgroundColor: '#955E2A',
      },
    ],
  };

  public randomize(): void {
    // Only Change 3 values
    this.barChartData.datasets[0].data = [
      Math.round(Math.random() * 100),
      59,
      80,
      Math.round(Math.random() * 100),
      56,
      Math.round(Math.random() * 100),
      40,
    ];
  }
}
