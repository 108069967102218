import { Component, OnInit } from '@angular/core';
import { MobileEndPointsService } from 'src/app/services/mobileEndPoints/mobile-end-points.service';

@Component({
  selector: 'app-top-achievers',
  templateUrl: './top-achievers.component.html',
  styleUrls: ['./top-achievers.component.scss'],
})
export class TopAchieversComponent implements OnInit {
  reportTypePreview: boolean = false;
  reportPlayer: any;

  ArrayOfData: any = [
    'Players report',
    'Attendance',
    'match',
    'Coaches Feedback',
    'Mental',
    '2022-06-29',
    '2022-06-29',
    '7',
    '96',
    '../../../assets/images/user.png',
  ];
  reportProp(data: String) {
    this.reportTypePreview = true;
    this.ArrayOfData = data.split(',');
    this.getUserbyId(this.ArrayOfData[8]);
  }
  getUserbyId(ID: any) {
    this._MobileEndPointsService.getPlayerByID(ID).subscribe((res: any) => {
      this.reportPlayer = res;
    });
  }
  constructor(private _MobileEndPointsService: MobileEndPointsService) {}
  print() {
    window.print();
  }

  back() {
    this.reportTypePreview = false;
  }

  ngOnInit(): void {}
}
