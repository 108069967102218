<div class="subscription">
    <div class="row">
        <div class="col-12">
            <div class="mb-3">
                <app-period-selection-bar></app-period-selection-bar>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-5">
            <app-stats-card [number]="160" [description]="'New Subs.'" [iconName]="'invoice_green'"
            [iconWidth]="44" [isColoredIcon]="false" [total]="200"></app-stats-card>
        </div>

        <div class="col-md-5">
            <app-stats-card [number]="17" [description]="'Overdue Subs.'" [iconName]="'invoice_red'"
            [iconWidth]="44" [isColoredIcon]="false"></app-stats-card>
        </div>
    </div>
</div>