import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AcademiesComponent } from './pages/academies/academies.component';
import { AcademyBillComponent } from './pages/academy-bill/academy-bill.component';
import { AcademyStatsComponent } from './pages/academy-stats/academy-stats.component';
import { HomeComponent } from './pages/home/home.component';
import { LicenceReportsComponent } from './pages/licence-reports/licence-reports.component';
import { LoginComponent } from './pages/login/login.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { SubscriptionsComponent } from './pages/subscriptions/subscriptions.component';
import { TransactionsComponent } from './pages/transactions/transactions.component';
import { UsersActivitiesComponent } from './pages/users-activities/users-activities.component';
import { IsAuthGuard } from './providers/is-auth.guard';
import { StaffComponent } from './pages/staff/staff.component';
import { UsersStatisticsComponent } from './pages/users-statistics/users-statistics.component';
import { TeamsComponent } from './pages/teams/teams.component';
import { CoachProfileComponent } from './pages/coach-profile/coach-profile.component';
import { CalenderComponent } from './pages/calender/calender.component';
import { PlayerPaymentComponent } from './pages/player-payment/player-payment.component';
import { TeamsActivitiesComponent } from './pages/teams-activities/teams-activities.component';
import { AcademyReportsComponent } from './pages/academy-reports/academy-reports.component';
import { TopAchieversComponent } from './pages/top-achievers/top-achievers.component';
import { SubscriptionPaymentsComponent } from './pages/subscription-payments/subscription-payments.component';
import { PlatformStatisticsComponent } from './pages/platform-statistics/platform-statistics.component';

const routes: Routes = [
  {
    path: '',
    component: UsersStatisticsComponent,
    pathMatch: 'full',
    canActivate: [IsAuthGuard],
  },
  {
    path: 'coachProfile',
    component: CoachProfileComponent,
    canActivate: [IsAuthGuard],
  },
  {
    path: 'teams',
    component: TeamsComponent,
    canActivate: [IsAuthGuard],
  },
  {
    path: 'academies',
    component: AcademiesComponent,
    canActivate: [IsAuthGuard],
  },
  {
    path: 'calendar',
    component: CalenderComponent,
    canActivate: [IsAuthGuard],
  },
  {
    path: 'playerPayment',
    component: PlayerPaymentComponent,
    canActivate: [IsAuthGuard],
  },
  {
    path: 'transactions',
    component: TransactionsComponent,
    canActivate: [IsAuthGuard],
  },
  {
    path: 'license-reports',
    component: LicenceReportsComponent,
    canActivate: [IsAuthGuard],
  },

  { path: 'login', component: LoginComponent },

  {
    path: 'academy-state',
    component: AcademyStatsComponent,
    canActivate: [IsAuthGuard],
  },
  {
    path: 'users-activities',
    component: UsersActivitiesComponent,
    canActivate: [IsAuthGuard],
  },
  {
    path: 'subscriptions',
    component: SubscriptionsComponent,
    canActivate: [IsAuthGuard],
  },
  {
    path: 'my-bill',
    component: AcademyBillComponent,
    canActivate: [IsAuthGuard],
  },
  {
    path: 'my-reports',
    component: ReportsComponent,
    canActivate: [IsAuthGuard],
  },
  {
    path: 'staff',
    component: StaffComponent,
    canActivate: [IsAuthGuard],
  },
  {
    path: 'teams-activities',
    component: TeamsActivitiesComponent,
    canActivate: [IsAuthGuard],
  },
  {
    path: 'academy-reports',
    component: AcademyReportsComponent,
    canActivate: [IsAuthGuard],
  },
  {
    path: 'top-achievers',
    component: TopAchieversComponent,
    canActivate: [IsAuthGuard],
  },
  {
    path: 'Subscription-Payments',
    component: SubscriptionPaymentsComponent,
    canActivate: [IsAuthGuard],
  },
  {
    path: 'Platform-Statistics',
    component: PlatformStatisticsComponent,
    canActivate: [IsAuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
