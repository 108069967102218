import { Component, Input, OnInit } from '@angular/core';
import { DashBordEndPointsService } from 'src/app/services/dash-bord-end-points.service';

@Component({
  selector: 'app-player-attr-achievers',
  templateUrl: './player-attr-achievers.component.html',
  styleUrls: ['./player-attr-achievers.component.scss'],
})
export class PlayerAttrAchieversComponent implements OnInit {
  @Input() catId: any = '';
  @Input() attrID: any = '';
  tableData: any = [];
  @Input() team: any = '';
  gkOrder = 0;
  nongkOrder = 0;

  constructor(private _DashBordEndPointsService: DashBordEndPointsService) {}

  ngOnInit(): void {
    this.gkOrder = 0;
    this.nongkOrder = 0;
    this._DashBordEndPointsService
      .getAttrPlayerAchivers(
        this.catId,
        this.attrID == 'all' ? '' : this.attrID,
        this.team
      )
      .subscribe((res: any) => {
        this.tableData = res.results;
        let x = this.tableData.filter((x: any) => {
          return x.primary_position == 'GK';
        });
        let y = this.tableData.filter((x: any) => {
          return x.primary_position != 'GK';
        });

        if (this.catId == 5) {
          this.tableData = x;
        } else {
          this.tableData = y;
        }
      });
  }
}
