<app-breadcrumb
  [breadcrumbItems]="breadcrumb"
  title="Subscription Payments"
></app-breadcrumb>
<main class="bg-white" *ngIf="!ReportShown">
  <div class="p-5 allPlayers-report">
    <!-- /*##########All Players ##########*/ -->

    <h1>All Players<span>*</span></h1>
    <div class="date mt-5">
      <!-- /*##########AllPlayersMonthSelect##########*/ -->
      <div class="d-flex flex-row gap-3 w-25 align-items-center">
        <h6>Month</h6>
        <select
          name="month"
          id="month"
          class="AllPlayersSelectMonth"
          ngDefaultControl
          [(ngModel)]="AllPlayersMonth"
        >
          <option value="Select Tournament" disabled selected>
            Select Month
          </option>
          <option
            value="{{ x + 1 }}"
            *ngFor="let month of months; let x = index"
          >
            {{ month }}
          </option>
        </select>
      </div>
      <!-- /*##########AllPlayersYearSelect##########*/ -->
      <div class="d-flex flex-row gap-3 w-25 align-items-center">
        <h6>Year</h6>
        <select
          name="year"
          id="year"
          class="AllPlayersSelectYear"
          [(ngModel)]="AllPlayersYear"
          ngDefaultControl
        >
          <option value="Select Tournament" disabled selected>
            Select Year
          </option>
          <option value="{{ year }}" *ngFor="let year of years">
            {{ year }}
          </option>
        </select>
      </div>
    </div>
    <button
      class="mt-4"
      (click)="showReport('AllPlayers')"
      [disabled]="!AllPlayersYear || !AllPlayersMonth"
    >
      View Report
    </button>

    <!-- /*##########All Team##########*/ -->
    <div class="team-selection">
      <h1>Select Team<span>*</span></h1>
      <select
        ngDefaultControl
        name="select-team"
        id="select-team"
        class="TeamSelect"
        [(ngModel)]="selectedTeam"
      >
        <option value="Select Tournament" disabled selected>Select team</option>
        <option value="{{ team.id }}" *ngFor="let team of teams">
          {{ team.name }}
        </option>
      </select>

      <div class="date mt-3">
        <!-- /*##########Teams month Select##########*/ -->
        <div class="d-flex flex-row gap-3 w-25 align-items-center">
          <h6>Month</h6>
          <select
            name="month"
            id="month"
            class="TeamMonnthSelect"
            [(ngModel)]="TeamsMonth"
          >
            <option value="Select Tournament" disabled selected>
              Select Month
            </option>
            <option
              value="{{ x + 1 }}"
              *ngFor="let month of months; let x = index"
            >
              {{ month }}
            </option>
          </select>
        </div>
        <!-- /*##########Teams year Select##########*/ -->
        <div class="d-flex flex-row gap-3 w-25 align-items-center">
          <h6>Year</h6>
          <select
            name="year"
            id="year"
            class="TeamYearSelect"
            [(ngModel)]="TeamsYear"
            ngDefaultControl
          >
            <option value="Select Tournament" disabled selected>
              Select Year
            </option>
            <option value="{{ year }}" *ngFor="let year of years">
              {{ year }}
            </option>
          </select>
        </div>
      </div>
      <button
        class="mt-4"
        (click)="showReport('Team')"
        [disabled]="!TeamsMonth || !TeamsYear || !selectedTeam"
      >
        View Report
      </button>
    </div>

    <!-- /*##########Select One Player##########*/ -->
    <div class="team-selection">
      <h1>Select Player<span>*</span></h1>
      <select
        name="select-team"
        id="select-team"
        class="SelectPlayer"
        ngDefaultControl
        [(ngModel)]="selectedPlayer"
      >
        <option value="Select Tournament" disabled selected>
          Select Player
        </option>
        <option value="{{ player.id }}" *ngFor="let player of players">
          {{ player.username }}
        </option>
      </select>

      <div class="date mt-3">
        <div class="d-flex flex-row gap-3 w-25 align-items-center">
          <h6>From</h6>
          <input
            placeholder="from"
            type="date"
            [(ngModel)]="SinglePlayerFrom"
          />
        </div>
        <div class="d-flex flex-row gap-3 w-25 align-items-center">
          <h6>To</h6>
          <input placeholder="to" type="date" [(ngModel)]="SinglePlayerTo" />
        </div>
      </div>
      <button
        class="mt-4"
        (click)="showReport('Player')"
        [disabled]="!selectedPlayer || !SinglePlayerFrom || !SinglePlayerTo"
      >
        View Report
      </button>
    </div>
  </div>
</main>

<div class="bg-white" *ngIf="ReportShown">
  <div class="d-flex flex-row w-100 justify-content-between removeFromPrint">
    <img
      src="assets/icons/backArrow.png"
      (click)="back()"
      style="width: 29px"
      class="pointer"
    />
    <img
      src="assets/icons/printIcon.png"
      (click)="print()"
      style="width: 29px"
      class="pointer"
    />
  </div>
  <!-- <div class="notPrintView row mb-5 mx-3 mt-4 justify-content-between">
    <img src="assets/images/scoutxLogoTran.png" (click)="back()" class="align-self-center pointer"
      style="width: 251px; height: 83px" />
    <img src="assets/icons/strikeLogo.png" (click)="print()" style="width: 125px" class="pointer" />
  </div> -->
  <app-subscription-report
    [type]="type"
    [month]="AllPlayersMonth"
    [year]="AllPlayersYear"
    *ngIf="type == 'AllPlayers'"
  ></app-subscription-report>

  <app-subscription-report
    [type]="type"
    [team]="selectedTeam"
    [month]="TeamsMonth"
    [year]="TeamsYear"
    [teams]="teams"
    *ngIf="type == 'Team'"
  ></app-subscription-report>

  <app-subscription-report
    [type]="type"
    [player]="selectedPlayer"
    [from]="SinglePlayerFrom"
    [to]="SinglePlayerTo"
    [players]="players"
    *ngIf="type == 'Player'"
  ></app-subscription-report>
</div>
