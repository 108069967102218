<div class="row">
  <div class="col-3 card">
    <h1>{{ cardData.total_matches }}</h1>
    <h2>Matches played</h2>
  </div>
  <div class="col-3 card" *ngIf="position != 'GK'">
    <h1>{{ cardData.total_assets }}</h1>
    <h2>Total assists</h2>
  </div>
  <div class="col-3 card" *ngIf="position != 'GK'">
    <h1>{{ cardData.total_gaols }}</h1>
    <h2>Total goals</h2>
  </div>
  <div class="col-3 card" *ngIf="position == 'GK'">
    <h1>{{ cardData.goals_conceded }}</h1>
    <h2>Goals conceded</h2>
  </div>
  <div class="col-3 card" *ngIf="position == 'GK'">
    <h1>{{ cardData.total_matches - cardData.total_matches }}</h1>
    <h2>Clean sheets</h2>
  </div>
</div>

<div class="row printView">
  <div class="col-4 order-2 printView">
    <!-- <app-doughunt-chart
      [Icon]="'soccer'"
      [title]="'Team matches'"
      [data]="[
        chartData.total_events - chartData.total_attendance_events,
        chartData.total_attendance_events
      ]"
      [doughnutChartLabels]="['Missed', 'Participated']"
    ></app-doughunt-chart> -->
  </div>

  <div class="col-8 order-1 printView">
    <table>
      <tr>
        <!-- <th>#</th> -->
        <th>Date</th>
        <th>Opponent</th>
        <th>Attendance</th>
        <th>Score</th>
        <th *ngIf="position != 'GK'">Goals</th>
        <th *ngIf="position != 'GK'">Assists</th>
        <th *ngIf="position == 'GK'">Goals conceded</th>
        <th *ngIf="position == 'GK'">Clean sheet</th>
        <th>Recorded by</th>
      </tr>
      <tr *ngFor="let record of tableData">
        <!-- <td class="id">{{ record.id }}</td> -->
        <td class="date">{{ record.date }}</td>
        <td class="location">{{ record.opponent_team_name }}</td>
        <td>{{ record.attended }}</td>
        <td class="score">
          {{ record.team_score }} - {{ record.opponent_team_score }}
        </td>
        <td class="goals" *ngIf="position != 'GK'">{{ record.goals }}</td>
        <td class="goals" *ngIf="position != 'GK'">{{ record.assets }}</td>
        <td class="goals" *ngIf="position == 'GK'">
          {{ record.goals_conceded }}
        </td>
        <td class="goals" *ngIf="position == 'GK'">{{ record.clean_sheet }}</td>
        <td class="by">{{ record.recorded_by }}</td>
      </tr>
    </table>
  </div>
</div>
