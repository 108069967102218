import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ChartData, ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'app-doughunt-chart',
  templateUrl: './doughunt-chart.component.html',
  styleUrls: ['./doughunt-chart.component.scss'],
})
export class DoughuntChartComponent implements OnInit, OnChanges {
  // Colors
  fontColor: string = '#ED6623';
  lightFontColor: string = '#ED66236C';
  fontFamily: string = 'circular-bold';
  @Input() select: any = [
    { text: 'Coaches', isActive: false, value: 'coachuser' },
    { text: 'Players', isActive: true, value: 'playeruser' },
    { text: 'Parents', isActive: false, value: 'parentuser' },
  ];
  //Input Data
  // @Input() selectors: Boolean = false;

  // @Input() title!: String;
  // @Input() Icon!: string;
  // @Input() doughnutChartLabels: Label[] = ['Likes', 'Shares', 'Comments'];
  // @Input() data: number[] = [180, 123, 120];
  // @Input() totalInterActions: number = 0

  // // Doughnut`
  // doughnutChartData!: MultiDataSet;
  // doughnutChartType: ChartType = 'doughnut';
  // options: ChartOptions = {
  //   responsive: true,
  //   legend: { display: false },
  //   cutoutPercentage: 70
  // }
  // donutColors = [{
  //   backgroundColor: [
  //     '#D30000',
  //     '#56C662',
  //     '#009DFF'
  //   ]
  // }];

  // Custom Vars
  total!: number;
  leftCornerOrRight!: string;

  constructor() {}

  @Output('typeChange') typeChange = new EventEmitter<string>();

  ngOnInit(): void {
    // this.doughnutChartData = [this.data]
    // this.total = this.data.reduce((total, item) => (total + item));
    // this.leftCornerOrRight = (this.data.length === 3 ? 'bottomRight' : 'bottomLeft')
    // this.typeChange.emit("playeruser")
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.doughnutChartData = [this.data]
    // this.total = this.data.reduce((total, item) => (total + item));
    // this.leftCornerOrRight = (this.data.length === 3 ? 'bottomRight' : 'bottomLeft')
  }

  activate(index: number) {
    // console.log("indeeeeez",index);
    this.select = this.select.map((item: any) => {
      return { ...item, isActive: false };
    });
    this.select[index].isActive = true;
    this.typeChange.emit(this.select[index]['value']);
  }
}
