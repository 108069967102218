<app-breadcrumb
  [breadcrumbItems]="breadcrumb"
  title="Players Payment Recordings"
></app-breadcrumb>

<div class="m-1">
  <div class="card flexRow col-12">
    <div class="col-4 my-4">
      <h1>Select Team</h1>
      <select
        name="Teams"
        id="Teams"
        [(ngModel)]="selectedTeam"
        (change)="filter()"
        class="w-75"
      >
        <option value="" disabled selected>Team</option>
        <option value="">All</option>
        <option *ngFor="let team of allTeams" [value]="team.id">
          {{ team.name }}
        </option>
      </select>
    </div>
    <div class="col-8 my-4 p-0">
      <h1>Select Date</h1>
      <div>
        <select
          name="Month"
          id="Month"
          class="mr-3 w-25"
          [(ngModel)]="selectedMonth"
          (change)="filter()"
        >
          <option value="1">January</option>
          <option value="2">February</option>
          <option value="3">March</option>
          <option value="4">April</option>
          <option value="5">May</option>
          <option value="6">June</option>
          <option value="7">July</option>
          <option value="8">August</option>
          <option value="9">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>
        <select
          name="year"
          id="year"
          [(ngModel)]="selectedYear"
          (change)="filter()"
          class="w-25"
        >
          <option value="2018">2018</option>
          <option value="2019">2019</option>
          <option value="2020">2020</option>
          <option value="2021">2021</option>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
          <option value="2024" selected>2024</option>
          <option value="2025">2025</option>
          <option value="2026">2026</option>
          <option value="2027">2027</option>
        </select>
      </div>
    </div>
  </div>

  <div class="row mt-5 justify-content-between gap d-flex">
    <div class="flex1 p-0">
      <div class="card flex-row align-items-center p-3 h-100">
        <img src="assets/icons/orangeBall.svg" />
        <h2 class="ml-2">
          {{ usePaymentSummary.players }}
          <span> Players</span>
        </h2>
      </div>
    </div>
    <div class="flex1 p-0">
      <div class="card flex-row align-items-center p-3 h-100">
        <img src="assets/icons/orangeDollarSign.svg" />
        <h2 class="ml-2">
          {{ usePaymentSummary.payments }}
          <span> Recorded Payments</span>
        </h2>
      </div>
    </div>
  </div>

  <div class="d-flex flex-row mt-4">
    <div class="col-3 pl-4"><h3>Member</h3></div>
    <div class="col-3 pl-1"><h3>Team</h3></div>
    <div class="col-3 pl-1"><h3>Month</h3></div>
    <div class="col-3 pl-1"><h3>Status</h3></div>
  </div>

  <div
    class="d-flex flex-row mt-2 card card-zoom"
    *ngFor="let user of userPayment"
  >
    <div class="col-3 pl-4 pr-2 py-2">
      <h4>{{ user.username }}</h4>
    </div>
    <div class="col-3 p-2">
      <h4>{{ user.team }}</h4>
    </div>
    <div class="col-3 p-2">
      <h5>{{ allMonths[+selectedMonth - 1] }} {{ selectedYear }}</h5>
    </div>
    <div class="col-3 p-2">
      <h6 class="paid" *ngIf="user.is_paid">Paid</h6>
      <h6 class="failed" *ngIf="!user.is_paid">Failed</h6>
    </div>
  </div>
</div>
