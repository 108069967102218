<div class="mt-5 pageSpace">
  <div class="row">
    <div class="col-3 text-center">
      <div class="total-matches-container">
        <h5 class="text-capitalize">Total {{ attendanceType }}</h5>
        <h6>{{ chartData?.total_events }}</h6>
      </div>
    </div>
    <div class="col-4 m-auto">
      <div class="Matches-Participated-container">
        <h5 class="text-capitalize">
          {{ attendanceType }} Participated:
          <span>{{ chartData?.total_attendance_events }}</span>
        </h5>
        <div
          class="progress"
          role="progressbar"
          aria-label="Example with label"
          aria-valuenow="97"
          aria-valuemin="0"
          aria-valuemax="100"
          style="background-color: rgba(0, 190, 143, 0.1) !important"
        >
          <div
            class="progress-bar"
            [ngStyle]="{
              width:
                (chartData?.total_attendance_events / chartData?.total_events) *
                  100 +
                '%'
            }"
            style="background-color: rgba(0, 190, 143, 1) !important"
          >
            {{
              (
                (chartData?.total_attendance_events / chartData?.total_events) *
                100
              ).toFixed(2) + "%"
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-4 m-auto">
      <div class="Matches-Participated-container">
        <h5 class="text-capitalize">
          {{ attendanceType }} Missed:
          <span>{{
            chartData?.total_events - chartData?.total_attendance_events
          }}</span>
        </h5>
        <div
          class="progress"
          role="progressbar"
          aria-label="Example with label"
          aria-valuenow="97"
          aria-valuemin="0"
          aria-valuemax="100"
          style="background-color: rgba(255, 0, 0, 0.1)"
        >
          <div
            class="progress-bar"
            [ngStyle]="{
              width:
                ((chartData?.total_events -
                  chartData?.total_attendance_events) /
                  chartData?.total_events) *
                  100 +
                '%'
            }"
            style="background-color: rgba(255, 0, 0, 1) !important"
          >
            {{
              (
                ((chartData?.total_events -
                  chartData?.total_attendance_events) /
                  chartData?.total_events) *
                100
              ).toFixed(2) + "%"
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mt-5 pageSpace pageBreakBefore">
  <h4 style="padding-inline: 20px">Player Attendance</h4>
  <table *ngIf="attendanceType == 'match'">
    <tr>
      <th>Date</th>
      <th>Location</th>
      <th>Opponent</th>
      <th>Tournament</th>
      <th>Participation</th>
      <th>Recorded By</th>
    </tr>
    <tr *ngFor="let record of tableDate">
      <td class="userName">{{ record.date }}</td>
      <td class="teamName">{{ record.location }}</td>
      <td class="teamName">{{ record.opponent_team_name }}</td>
      <td class="teamName">Tournament</td>

      <td class="gender" *ngIf="record.attended == 'Missed'">
        <div
          style="
            width: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 28px;
            text-align: center;
            color: white;
            background-color: #e9655a;
            border-radius: 6px;
            font-size: 13px;
          "
        >
          No
        </div>
      </td>

      <td class="gender" *ngIf="record.attended != 'Missed'">
        <div
          style="
            width: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 28px;
            text-align: center;
            color: white;
            background-color: #71d875;
            border-radius: 6px;
            font-size: 13px;
          "
        >
          Yes
        </div>
      </td>
      <td class="attributeScore">{{ record.recorded_by }}</td>
    </tr>
  </table>
  <table *ngIf="attendanceType != 'match'">
    <tr>
      <th>Date</th>
      <th>Location</th>
      <th>Attendance</th>
      <th>Recorded By</th>
    </tr>
    <tr *ngFor="let record of tableDate">
      <td class="userName">{{ record.date }}</td>
      <td class="teamName">{{ record.location }}</td>
      <td
        class="gender"
        *ngIf="record.attended == 'Missed' || record.attended == 'Absent'"
      >
        <div
          style="
            width: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 28px;
            text-align: center;
            color: white;
            background-color: #e9655a;
            border-radius: 6px;
            font-size: 13px;
          "
        >
          No
        </div>
      </td>

      <td
        class="gender"
        *ngIf="record.attended != 'Missed' && record.attended != 'Absent'"
      >
        <div
          style="
            width: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 28px;
            text-align: center;
            color: white;
            background-color: #71d875;
            border-radius: 6px;
            font-size: 13px;
          "
        >
          Yes
        </div>
      </td>

      <td class="attributeScore">{{ record.recorded_by }}</td>
    </tr>
  </table>
</div>
