import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-stats-card',
  templateUrl: './stats-card.component.html',
  styleUrls: ['./stats-card.component.scss']
})
export class StatsCardComponent implements OnInit, OnChanges {
  @Input() iconName: string = "academy";
  @Input() number: number = 0;
  @Input() description: string = "";
  @Input() isColoredIcon: boolean = true;
  @Input() coloredIcon: string = '';
  @Input() name: string = "";
  shownIconName: any = null

  @Input() textColor: string = '';

  @Input() iconWidth: number = 26

  @Input() state: string = "";

  @Input() total: any = ''
  @Input() totalText: any = 'Total Subs.'

  @Input() isAnalytic: Boolean = false
  constructor() { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.name == "appUserStats") {
      this.shownIconName = this.number == 0 ? "minus" : "increase"
    }
    // console.log(this.description,"===>",this.total)
  }

}
