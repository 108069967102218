<div>
  <div class="position-relative">
    <img src="assets/images/coachImg.png" class="w-100 mt-2" />
    <img [src]="profileData?.picture" class="position-absolute profileImg" />
  </div>
  <div>
    <div class="info pt-4 pb-4">
      <div>
        <h1>{{ profileData?.username }}</h1>
        <h2>Coach</h2>
      </div>

      <div>
        <h3>{{ profileData?.email }}</h3>
        <h2>Email ID</h2>
      </div>

      <div>
        <h3>{{ profileData?.phone }}</h3>
        <h2>Phone No</h2>
      </div>
    </div>

    <div class="mt-5 d-flex gap-4">
      <div class="col-4 bg-white p-4">
        <div class="text-center">
          <h4 class="m-0">{{ profileData?.number_of_matches_played }}</h4>
          <h5>Total Matches</h5>
        </div>
        <div class="d-flex justify-content-evenly mt-3">
          <div>
            <h6>{{ profileData?.number_of_wins }}</h6>
            <h6 class="subtitles">Wins</h6>
          </div>
          <div>
            <h6>{{ profileData?.number_of_draws }}</h6>
            <h6 class="subtitles">Draws</h6>
          </div>
          <div>
            <h6>{{ profileData?.number_of_losses }}</h6>
            <h6 class="subtitles">Loses</h6>
          </div>
        </div>
        <div class="mt-3 ml-3">
          <h1 class="fw-light mb-4">Teams</h1>
          <div
            class="d-flex align-items-center"
            *ngFor="let team of profileData?.managed_teams"
          >
            <img src="assets/images/squareTshirt.png" />
            <h6 class="subtitles ml-3">{{ team }}</h6>
          </div>
        </div>
      </div>
      <div class="col bg-white p-4 hFit">
        <h1>About</h1>
        <h2>{{ profileData?.bio }}</h2>
        <h1 class="mt-4">Personal Information</h1>
        <div class="d-flex">
          <div>
            <h6 class="personInfo">Name</h6>
            <h6 class="personInfo">Academy</h6>
            <h6 class="personInfo">Job</h6>
            <h6 class="personInfo">Age</h6>
          </div>
          <div class="ml-3">
            <h2 class="mt-3">: {{ profileData?.username }}</h2>
            <h2 class="mt-3">: {{ profileData?.academy }}</h2>
            <h2 class="mt-3">: {{ profileData?.job }}</h2>
            <h2 class="mt-3">: {{ profileData?.age }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
