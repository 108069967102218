import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-licence-reports',
  templateUrl: './licence-reports.component.html',
  styleUrls: ['./licence-reports.component.scss']
})
export class LicenceReportsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
  periodChangeHandler(event: any) {
    // console.log(event)
  }

}
