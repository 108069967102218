import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { StatsService } from 'src/app/services/StatsServices/stats-services.service';
import { ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { MobileEndPointsService } from 'src/app/services/mobileEndPoints/mobile-end-points.service';
import { DashBordEndPointsService } from 'src/app/services/dash-bord-end-points.service';
import { select } from '@syncfusion/ej2-angular-schedule';
@Component({
  selector: 'app-teams-activities',
  templateUrl: './teams-activities.component.html',
  styleUrls: ['./teams-activities.component.scss'],
})
export class TeamsActivitiesComponent implements OnInit {
  breadcrumb = [
    { label: 'Dashboard', path: '', active: false },
    { label: 'Academy', path: '#', active: false },
    { label: 'Staff', path: '/staff', active: true },
  ];
  teams!: any[];
  selectedMonth: any;
  selectedTeam: any = '';
  selectedYear = '2024';
  selectedYearTraining = '2024';
  selectedYearMatches = '2024';
  teamSummary: any;
  matchSummary: any;
  trainingSummary: any;
  constructor(
    private _stats: StatsService,
    private _appEndPoints: MobileEndPointsService,
    private _dashEndpoints: DashBordEndPointsService
  ) {
    let currentDate = new Date();
    this.selectedYear = currentDate.getFullYear() + '';
    this.selectedMonth = currentDate.getMonth() + 1 + '';
    console.log(this.selectedMonth, 'se lected month');

    this.getTeamSummry();
    this.getTeamsInfo();
    this.getAcademyYearMatchSummary(this.selectedYear);
    this.getAcademyYearTrainigSummary(this.selectedYear);
  }

  ngOnInit(): void {
    this.updateTheQraphTraining();
    this.updateTheQraph();
    this.getTeams();
  }

  getAcademyYearMatchSummary(year: any) {
    this._dashEndpoints
      .getAcademyYearMatchSummary(year)
      .subscribe((res: any) => {});
  }

  getAcademyYearTrainigSummary(year: any) {
    this._dashEndpoints
      .getAcademyYearTrainigSummary(year)
      .subscribe((res: any) => {});
  }

  getTeamSummry() {
    this._dashEndpoints
      .getAcademyStates(this.selectedTeam)
      .subscribe((res: any) => {
        this.teamSummary = res;
      });
  }

  getTeamsInfo() {
    this._dashEndpoints
      .getAcademyMatchesSummry(
        this.selectedYear,
        this.selectedMonth,
        this.selectedTeam
      )
      .subscribe((res: any) => {
        this.matchSummary = res;
      });

    this._dashEndpoints
      .getAcademyTrainingSummry(
        this.selectedYear,
        this.selectedMonth,
        this.selectedTeam
      )
      .subscribe((res: any) => {
        this.trainingSummary = res;
      });
  }

  monthes = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  years = [
    '2017',
    '2018',
    '2019',
    '2020',
    '2021',
    '2022',
    '2023',
    '2024',
    '2025',
    '2026',
    '2027',
  ];
  // charts
  labelArray: any = [];
  totalMatches = 0;
  totalInstructions = 0;
  totalEvaluations = 0;
  totalSquads = 0;
  totalLineups = 0;
  totalUpdates = 0;

  matchesArray: any = [];
  InstructionsArray: any = [];
  evaluationsArray: any = [];
  squadsArray: any = [];
  lineupsArray: any = [];
  updatedArray: any = [];

  @ViewChild('first') chart: BaseChartDirective | undefined;
  updateTheQraph() {
    this.resetTheQraph();
    this._dashEndpoints
      .getAcademyYearMatchSummary(this.selectedYearMatches)
      .subscribe(
        (res: any) => {
          console.log(res, 'res');
          res.forEach((x: any) => {
            this.labelArray.push(x.month);
            this.matchesArray.push(x.count);
            this.InstructionsArray.push(x.instructions);
            this.evaluationsArray.push(x.evaluations);
            this.squadsArray.push(x.matches_with_squads);
            this.lineupsArray.push(x.matches_with_lineups);
            this.updatedArray.push(x.updated_results);

            this.totalMatches = this.totalMatches + x.count;
            this.totalEvaluations = this.totalEvaluations + x.evaluations;
            this.totalInstructions = this.totalInstructions + x.instructions;

            this.totalSquads = this.totalSquads + x.matches_with_squads;
            this.totalLineups = this.totalLineups + x.matches_with_lineups;
            this.totalUpdates = this.totalUpdates + x.updated_results;
          });
        },
        (err: any) => {},
        () => {
          this.barChartData.labels = this.labelArray;
          this.barChartData.datasets[0].data = this.matchesArray;
          this.barChartData.datasets[1].data = this.InstructionsArray;
          this.barChartData.datasets[2].data = this.evaluationsArray;
          this.barChartData.datasets[3].data = this.squadsArray;
          this.barChartData.datasets[4].data = this.lineupsArray;
          this.barChartData.datasets[5].data = this.updatedArray;
          this.chart?.update();
        }
      );
  }

  resetTheQraph() {
    this.labelArray = [];
    this.totalMatches = 0;
    this.totalInstructions = 0;
    this.totalEvaluations = 0;
    this.totalSquads = 0;
    this.totalLineups = 0;
    this.totalUpdates = 0;
    this.matchesArray = [];
    this.InstructionsArray = [];
    this.evaluationsArray = [];
    this.squadsArray = [];
    this.lineupsArray = [];
    this.updatedArray = [];
  }

  totalTraining = 0;
  totalInstructionsTraining = 0;
  totalEvaluationsTraining = 0;
  totalAttendanceTraining = 0;

  labelArrayTraining: any = [];
  TrainingArray: any = [];
  InstructionsArrayTraining: any = [];
  evaluationsArrayTraining: any = [];
  attendanceArrayTraining: any = [];

  @ViewChild('second') chartTraining: BaseChartDirective | undefined;

  updateTheQraphTraining() {
    this.resetTheQraphTraining();
    this._dashEndpoints
      .getAcademyYearTrainigSummary(this.selectedYearTraining)
      .subscribe(
        (res: any) => {
          res.forEach((x: any) => {
            this.labelArrayTraining.push(x.month);
            this.TrainingArray.push(x.count);
            this.InstructionsArrayTraining.push(x.instructions);
            this.evaluationsArrayTraining.push(x.evaluations);
            this.attendanceArrayTraining.push(x.recorded_training_attendance);
            this.totalTraining = this.totalTraining + x.count;
            this.totalEvaluationsTraining =
              this.totalEvaluationsTraining + x.evaluations;
            this.totalInstructionsTraining =
              this.totalInstructionsTraining + x.instructions;
            this.totalAttendanceTraining =
              this.totalAttendanceTraining + (x.matches_with_squads || 0);
          });
        },
        (err: any) => {},
        () => {
          this.barChartDataTraining.labels = this.labelArrayTraining;
          this.barChartDataTraining.datasets[0].data = this.TrainingArray;
          this.barChartDataTraining.datasets[1].data =
            this.InstructionsArrayTraining;
          this.barChartDataTraining.datasets[2].data =
            this.evaluationsArrayTraining;
          this.barChartDataTraining.datasets[3].data =
            this.attendanceArrayTraining;
          this.chartTraining?.update();
        }
      );
  }

  resetTheQraphTraining() {
    this.labelArrayTraining = [];
    this.totalTraining = 0;
    this.totalEvaluationsTraining = 0;
    this.totalInstructionsTraining = 0;
    this.totalAttendanceTraining = 0;
    this.TrainingArray = [];
    this.InstructionsArrayTraining = [];
    this.evaluationsArrayTraining = [];
    this.attendanceArrayTraining = [];
  }

  public barChartDataTraining: ChartData<'bar'> = {
    labels: [
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
    ],
    datasets: [
      {
        data: [],
        label: 'Trainings',
        borderColor: '#4CAF50',
        backgroundColor: '#4CAF50',
      },
      {
        data: [],
        label: 'Instructions',
        borderColor: '#32582C',
        backgroundColor: '#32582C',
      },
      {
        data: [],
        label: 'Evaluations',
        borderColor: '#ED6425',
        backgroundColor: '#ED6425',
      },
      {
        data: [],
        label: 'Recorded Training Attendance',
        borderColor: '#955E2A',
        backgroundColor: '#955E2A',
      },
    ],
  };

  public barChartOptions: any = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {},
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public barChartType: ChartType = 'bar';

  public barChartData: ChartData<'bar'> = {
    labels: [
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
      'Apr2023',
    ],
    datasets: [
      {
        data: [],
        label: 'Matches',
        borderColor: '#F44336',
        backgroundColor: '#F44336',
      },
      {
        data: [],
        label: 'Instructions',
        borderColor: '#32582C',
        backgroundColor: '#32582C',
      },
      {
        data: [],
        label: 'Evaluations',
        borderColor: '#ED6425',
        backgroundColor: '#ED6425',
      },
      {
        data: [],
        label: 'Matches with entered squads',
        borderColor: '#955E2A',
        backgroundColor: '#955E2A',
      },
      {
        data: [],
        label: 'Matches with entered Lineups',
        borderColor: '#705E2A',
        backgroundColor: '#705E2A',
      },
      {
        data: [],
        label: 'Matches with Updated results',
        borderColor: '#4CAF50',
        backgroundColor: '#4CAF50',
      },
    ],
  };
  public randomize(): void {
    // Only Change 3 values
    this.barChartData.datasets[0].data = [
      Math.round(Math.random() * 100),
      59,
      80,
      Math.round(Math.random() * 100),
      56,
      Math.round(Math.random() * 100),
      40,
    ];
  }
  getTeams() {
    this._appEndPoints.getTeams().subscribe(
      (res: any) => {
        this.teams = res.results;
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
}
