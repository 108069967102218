import { Component, OnInit, Output } from '@angular/core';
import { MobileEndPointsService } from 'src/app/services/mobileEndPoints/mobile-end-points.service';

@Component({
  selector: 'app-academy-reports',
  templateUrl: './academy-reports.component.html',
  styleUrls: ['./academy-reports.component.scss'],
})
export class AcademyReportsComponent implements OnInit {
  academyLogo = localStorage.getItem('academylogo');
  reportTypePreview: boolean = false;
  reportPlayer: any;
  ArrayOfData: any = [
    'Players report',
    'Attendance',
    'match',
    'Coaches Feedback',
    'Mental',
    '2022-06-29',
    '2022-06-29',
    '7',
    '96',
    '../../../assets/images/user.png',
  ];

  constructor(private _MobileEndPointsService: MobileEndPointsService) {}

  ngOnInit(): void {}

  // [ReportType, ReportDetails, attributeCategory, developmentArea, attributeCategory, fromTime, toTime,selectedTeam,selectedPlayer]
  reportProp(data: String) {
    this.reportTypePreview = true;
    this.ArrayOfData = data.split(',');
    this.getUserbyId(this.ArrayOfData[8]);
  }

  getUserbyId(ID: any) {
    this._MobileEndPointsService.getPlayerByID(ID).subscribe((res: any) => {
      this.reportPlayer = res;
    });
  }

  print() {
    window.print();
  }

  back() {
    this.reportTypePreview = false;
  }
}
