<h2 class="mb-5 ms-4 top-achievers-header-text">
  Top achievers / player / Stats /
  <span *ngIf="stats == 'matchPlayed'">Matches played</span>
  <span *ngIf="stats == 'participationPercentage'">
    Participation Percentage
  </span>
  <span *ngIf="stats == 'goals'"> Goals </span>
  <span *ngIf="stats == 'assists'"> Assists </span>
  <span *ngIf="stats == 'cleanSheets'"> Clean Sheets </span>
</h2>
<div
  class="row card flex-row shadow mb-2 pb-3"
  *ngFor="let record of tableData; let i = index"
>
  <div class="col-3 p-0 m-0 overflow">
    <div class="indexing">{{ i + 1 }}</div>
    <div *ngIf="record.picture">
      <img style="object-fit: contain" [src]="record.picture" />
    </div>
    <div *ngIf="!record.picture">
      <img style="object-fit: contain" src="/assets/images/playeruser.jpg" />
    </div>

    <!-- <img  [src]="record.picture" /> -->
    <!-- <img  [src]="record.picture" /> -->
  </div>
  <div class="col-4">
    <h1>{{ record.username }}</h1>
    <h2 class="strike-text">{{ record.academy ? record.academy : "-" }}</h2>
    <h2 class="selection-text">{{ record.team ? record.team : "-" }}</h2>
    <div class="line"></div>
    <div class="d-flex flex-row gap-4">
      <div>
        <h6 class="position-text">Position</h6>
        <p class="age-text">
          {{ record.primary_position ? record.primary_position : "-" }}
        </p>
      </div>
      <div>
        <h6 class="position-text">Age</h6>
        <p class="age-text">{{ record.age ? record.age : "-" }}</p>
      </div>
    </div>
    <div class="line"></div>
    <p class="top-achiever-text m-0">
      Top achievers / player / Stats /
      <span *ngIf="stats == 'matchPlayed'">Matches played</span>
      <span *ngIf="stats == 'participationPercentage'">
        Participation Percentage
      </span>
      <span *ngIf="stats == 'goals'"> Goals </span>
      <span *ngIf="stats == 'assists'"> Assists </span>
      <span *ngIf="stats == 'cleanSheets'"> Clean Sheets </span>
    </p>
    <div class="d-flex flex-row gap-2">
      <img
        *ngIf="stats === 'assists'"
        src="assets/icons/assists.png"
        style="width: 53px"
        alt="couchIcon"
      />
      <img
        *ngIf="stats === 'goals'"
        src="assets/icons/goals.png"
        style="width: 53px"
        alt="couchIcon"
      />
      <img
        *ngIf="stats === 'participationPercentage'"
        src="assets/icons/Participation.png"
        style="width: 53px"
        alt="couchIcon"
      />
      <img
        *ngIf="stats === 'matchPlayed'"
        src="assets/icons/Matches Played.png"
        style="width: 53px"
        alt="couchIcon"
      />
      <img
        *ngIf="stats === 'cleanSheets'"
        src="assets/icons/Clean Sheets.png"
        style="width: 53px"
        alt="couchIcon"
      />
      <div class="d-flex align-items-center gap-1">
        <span class="number-count-text"> {{ record.assists_count }} </span>
        <span class="number-count-text"> {{ record.match_attendance }} </span>
        <span
          class="number-count-text"
          *ngIf="record.match_attendance_percentage"
        >
          {{ record.match_attendance_percentage?.toFixed() }}%
        </span>
        <span class="number-count-text"> {{ record.goals_count }} </span>
        <span class="number-count-text"> {{ record.clean_sheets_count }} </span>

        <span class="stats-text" *ngIf="stats == 'matchPlayed'">
          Matches played</span
        >
        <span class="stats-text" *ngIf="stats == 'participationPercentage'">
          Participation
        </span>
        <span class="stats-text" *ngIf="stats == 'goals'"> Goals </span>
        <span class="stats-text" *ngIf="stats == 'assists'"> Assists </span>
        <span class="stats-text" *ngIf="stats == 'cleanSheets'">
          Clean Sheets
        </span>
      </div>
    </div>
  </div>
  <div class="col-5">
    <img
      style="object-fit: contain"
      class=""
      src="assets/images/stadium.png"
      alt="stadium"
      *ngIf="record.primary_position == null"
    />
    <img
      *ngIf="record.primary_position"
      style="object-fit: contain"
      class=""
      src="assets/images/{{ record.primary_position }}.svg"
      alt="stadium"
    />
  </div>
</div>
