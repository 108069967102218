import { Component, Input, OnInit } from '@angular/core';
import { DashBordEndPointsService } from 'src/app/services/dash-bord-end-points.service';

@Component({
  selector: 'app-stats-achivers',
  templateUrl: './stats-achivers.component.html',
  styleUrls: ['./stats-achivers.component.scss'],
})
export class StatsAchiversComponent implements OnInit {
  @Input() stats: any = '';
  @Input() team: any = '';
  @Input() tournament: any = '';

  tableData: any;
  constructor(private _DashBordEndPointsService: DashBordEndPointsService) {}

  ngOnInit(): void {
    if (this.stats == 'matchPlayed') {
      this._DashBordEndPointsService
        .getStatsAchiversMatchPlayed(this.team, this.tournament)
        .subscribe((res: any) => {
          this.tableData = res.results;
          console.log('hiiiii', this.tableData);
        });
    } else if (this.stats == 'participationPercentage') {
      this._DashBordEndPointsService
        .getStatsAchiversAttendancePercentage(this.team, this.tournament)
        .subscribe((res: any) => {
          this.tableData = res.results;
          console.log('hiiiii', this.tableData);
        });
    } else if (this.stats == 'goals') {
      this._DashBordEndPointsService
        .getStatsAchiversGoals(this.team, this.tournament)
        .subscribe((res: any) => {
          this.tableData = res.results;
          console.log('hiiiii', res.results);
        });
    } else if (this.stats == 'assists') {
      this._DashBordEndPointsService
        .getStatsAchiversAssists(this.team, this.tournament)
        .subscribe((res: any) => {
          this.tableData = res.results;
          console.log('hiiiii', res.results);
        });
    } else if (this.stats == 'cleanSheets') {
      this._DashBordEndPointsService
        .getStatsAchiversCleanSheets(this.team, this.tournament)
        .subscribe((res: any) => {
          this.tableData = res.results;
          console.log('hiiiii', res.results);
        });
    }
  }

  roundUp(value: any) {
    return Math.round(value);
  }
}
