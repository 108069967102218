import { ViewportScroller } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StatsService } from 'src/app/services/StatsServices/stats-services.service';

@Component({
  selector: 'app-academy-stats',
  templateUrl: './academy-stats.component.html',
  styleUrls: ['./academy-stats.component.scss'],
})
export class AcademyStatsComponent implements OnInit, OnChanges {
  constructor(
    private stats: StatsService,
    private route: ActivatedRoute,
    private scroller: ViewportScroller
  ) {}
  breadcrumb = [
    { label: 'Academy stats', path: '', active: true },
    // { label: "Academy stats", path: "", active: false },
    // { label: "Academy stats", path: "", active: false },
    // { label: "Academy stats", path: "", active: false },
  ];
  fragment: string = 'TopOfPage';
  /** filters */
  date: any;
  userInterAction: any;
  userType: any;
  /** responses */
  teamStats: any;
  userStats: any;
  platForms: any;
  x_Axis: any = [];
  y_Axis: any = [];
  chartData: any;
  xAxisTitle: string = 'Day';

  getTeamStats() {
    this.stats
      .teamStats(this.date)
      .toPromise()
      .then((res: any) => {
        this.teamStats = res;
      })
      .catch((err: any) => {});
  }

  getUsersStats() {
    this.stats
      .userStats(this.date)
      .toPromise()
      .then((res: any) => {
        this.userStats = res;
      })
      .catch((err: any) => {});
  }

  getPlatFormStats() {
    this.stats
      .platFormStats(this.date, this.userInterAction)
      .toPromise()
      .then((res: any) => {
        this.platForms = res;
      })
      .catch((err: any) => {});
  }

  getuserChartData() {
    // this.stats.chartsData(this.date, this.userType).toPromise().then((res: any) => {
    //   this.x_Axis = [];
    //   this.y_Axis = [];
    //   res.reverse();
    //   res.map((element: any) => {
    //     this.x_Axis.push(element.date);
    //     this.y_Axis.push(element.count);
    //   });
    //   this.chartData = res;
    // }).catch((err: any) => { })
  }

  ngOnInit(): void {
    this.date = 'three_month';
    this.userInterAction = 'playeruser';
    this.userType = 'playeruser';
    // this.route.fragment.subscribe(fragment => { fragment ? document.getElementById(this.fragment).scrollIntoView() : null });
  }

  periodChangeHandler(event: any) {
    this.date = event;
    this.xAxisTitle = ['year', 'three_month'].includes(this.date)
      ? 'Months'
      : 'Days';
    this.getTeamStats();
    this.getUsersStats();
    this.getPlatFormStats();
    this.getuserChartData();
  }

  userTypeChangeDoughnut(event: any) {
    this.userInterAction = event;
    this.getPlatFormStats();
  }

  changeUserType(event: any) {
    this.userType = event;
    this.getuserChartData();
  }

  ngOnChanges(change: SimpleChanges): void {}

  scrollTO(id: string) {
    let element = document.getElementById(id);
    element?.scrollIntoView({ behavior: 'smooth' });
  }
}
