<div class="px-4 report">
  <h1>Academy Reports / Subscriptions / {{ breadCrumbElement }}</h1>
  <!-- Data -->
  <div class="mt-4">
    <!-- All Players , Team , Player -->
    <h2 *ngIf="type == 'AllPlayers'">Players</h2>
    <h2 *ngIf="type == 'Team'">{{ TeamObj?.name }}</h2>
    <h2 *ngIf="type == 'Player'">
      {{ playerObj?.first_name + " " + playerObj?.last_name }}
    </h2>
    <!-- Team , Player -->
    <h5 *ngIf="type == 'Team' && TeamObj?.coach_obj?.first_name">
      Coach:
      {{
        TeamObj?.coach_obj?.first_name +
          "
      " +
          TeamObj?.coach_obj?.last_name
      }}
    </h5>
    <h5 *ngIf="type == 'Player' && playerObj?.team_obj?.name">
      {{ playerObj?.team_obj?.name }}
    </h5>
    <!-- All Players , Team -->
    <div class="d-flex gap-4" *ngIf="table && type !== 'Player'">
      <div class="d-flex gap-2 mt-4 align-items-center">
        <img src="assets/icons/allPlayers.png" alt="allPlayers" />
        <h6 *ngIf="table.length">
          <span>{{ NumberOfTotalPlayer }}</span> Players
        </h6>
      </div>
      <div class="d-flex gap-2 mt-4 align-items-center">
        <img src="assets/icons/allPlayers.png" alt="allPlayers" />
        <h6>
          <span class="red">{{ NumberOfPayments }} /</span
          ><span> {{ NumberOfTotalPlayer }} </span>Players
        </h6>
      </div>
    </div>

    <!-- Date -->
    <h3 *ngIf="['AllPlayers', 'Team'].includes(type)">
      {{ months[month - 1] }} {{ year }} Subscription
    </h3>
    <h3 *ngIf="!['AllPlayers', 'Team'].includes(type)">
      {{ playerFromDate }} - {{ playerToDate }}
    </h3>
    <!-- All Players,Team Table -->
    <table *ngIf="['AllPlayers', 'Team'].includes(type) && table.length">
      <tr>
        <th>{{ type !== "Team" ? "Player Name" : "Player" }}</th>
        <th *ngIf="type !== 'Team'">Team</th>
        <th>Paid</th>
      </tr>
      <tr *ngFor="let player of table">
        <td class="userName">{{ player.username }}</td>
        <td class="teamName" *ngIf="type !== 'Team'">
          {{ player?.team || "-" }}
        </td>
        <td class="primaryPosition">
          <div class="paid" [ngClass]="{ 'not-paid': !player?.is_paid }">
            {{ player?.is_paid ? "Yes" : "No" }}
          </div>
        </td>
      </tr>
    </table>

    <!-- Single Player Table -->
    <table *ngIf="['Player'].includes(type) && table.length">
      <tr>
        <th>Month</th>
        <th>Year</th>
        <th>Paid</th>
      </tr>
      <tr *ngFor="let obj of table">
        <td class="userName">{{ obj.month }}</td>
        <td class="teamName">{{ obj.year }}</td>
        <td class="primaryPosition">
          <div class="paid" [ngClass]="{ 'not-paid': !obj?.is_paid }">
            {{ obj?.is_paid ? "Yes" : "No" }}
          </div>
        </td>
      </tr>
    </table>

    <!-- /*##########Not found##########*/ -->
    <div class="d-flex justify-content-center my-3 py-3" *ngIf="!table.length">
      <h1>No Data Found</h1>
    </div>

    <div
      class="d-flex justify-content-center my-3 py-3"
      *ngIf="NextURL && type !== 'Player'"
    >
      <button class="loadMore" [disabled]="isLoading" (click)="loadMoreData()">
        Load More
      </button>
    </div>
  </div>
</div>
