<div class="row g-4">
  <div class="col-md-3 px-3" *ngFor="let data of usersTopVisited">
    <div class="player-card bg-white p-2 py-4">
      <div class="image-circle">
        <img
          src="{{
            data.picture ? data.picture : 'assets/images/playeruser.jpg'
          }}"
          alt="playerImage"
          style="width: 100px; height: 100px; border-radius: 50%"
        />
        <div class="position" *ngIf="data?.playeruser?.primary_position">
          {{ data?.playeruser?.primary_position }}
        </div>
      </div>
      <h4>{{ data?.username }}</h4>
      <h6 *ngIf="data.user_type == 'playeruser'">Player</h6>
      <div class="player-team p-2">
        {{ data?.playeruser?.team_obj?.name || "-" }}
      </div>
    </div>
  </div>
</div>
