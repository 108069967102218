<div class="pageSpace">
  <div class="mt-5">
    <div class="row">
      <div class="col-6">
        <div class="py-4 d-flex justify-content-between px-4 stats">
          <div>
            <h6 class="position-number">
              {{
                cardsContent.player_training_evaluation
                  ? cardsContent.player_training_evaluation
                  : 0
              }}
            </h6>
            <h6 class="matches-played">Average Player Evaluation- Trainings</h6>
          </div>
          <div>
            <img
              src="assets/icons/blackClock.png"
              alt="blackClock"
              style="width: 70px"
            />
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="py-4 d-flex justify-content-between px-4 stats">
          <div>
            <h6 class="position-number">
              {{
                cardsContent.player_match_evaluation
                  ? cardsContent.player_match_evaluation
                  : 0
              }}
            </h6>
            <h6 class="matches-played">Average Player Evaluation - Matches</h6>
          </div>
          <div>
            <img
              src="assets/icons/match played.png"
              alt="match played"
              style="width: 70px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mt-5 coach-table pageBreakBefore">
  <h4>Coaches Feedback</h4>

  <table>
    <tr>
      <th>Date</th>
      <th>Match/Training</th>
      <th>Score</th>
      <th>Rating</th>
      <th>Recorded By</th>
    </tr>
    <tr *ngFor="let record of tableContent" class="printDesign">
      <td class="userName">{{ record.date }}</td>
      <td class="teamName">{{ record.training ? "Training" : "Match" }}</td>
      <td class="age">
        {{
          record.training
            ? "-"
            : record.team_score + " - " + record.opponent_team_score
        }}
      </td>
      <td class="teamName">
        {{
          record.player_evaluation_score ? record.player_evaluation_score : "-"
        }}
      </td>
      <td class="attributeScore">{{ record.recorded_by }}</td>
    </tr>
  </table>
</div>
