import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit, Input } from '@angular/core';
import { DashBordEndPointsService } from 'src/app/services/dash-bord-end-points.service';

@Component({
  selector: 'app-subscription-report',
  templateUrl: './subscription-report.component.html',
  styleUrls: ['./subscription-report.component.scss'],
})
export class SubscriptionReportComponent implements OnInit {
  @Input() type: any;
  @Input() team: any;
  @Input() month: any;
  @Input() year: any;
  @Input() player: any;
  @Input() from: any;
  @Input() to: any;
  @Input() teams: any;
  @Input() players: any;
  table: any = [];
  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  breadCrumbElement: any;
  TeamObj: any;
  playerObj: any;
  NumberOfTotalPlayer: any;
  NumberOfPayments: any;

  isLoading = false;
  NextURL = '';

  playerFromDate = '';
  playerToDate = '';

  constructor(private _dashAPI: DashBordEndPointsService) {}

  handleBreadCrumbElement(type: any) {
    switch (type) {
      case 'AllPlayers':
        this.breadCrumbElement = 'All Players';
        break;

      case 'Team':
        this.breadCrumbElement = 'Teams';
        break;

      case 'Player':
        this.breadCrumbElement = 'player';
        break;

      default:
        this.breadCrumbElement = 'Players';
        break;
    }
  }

  getPlayerObj() {
    let selectedPlayer = this.players.find((ele: any) => ele.id == this.player);
    this.playerObj = selectedPlayer;
    console.log('selected player', selectedPlayer);
  }

  getTeamObj() {
    let SelectedTeamObj = this.teams.find((ele: any) => ele.id == this.team);
    this.TeamObj = SelectedTeamObj;
  }

  getPaidObjects(Arr: any) {
    let paidArr = Arr.filter((obj: any) => obj.is_paid === true);
    // this.NumberOfPayments = paidArr.length;
  }

  sharedSubScribeFunction(res: any) {
    this.NumberOfTotalPlayer = res.count;

    this.table = [...this.table, ...res.results];
    this.NextURL = res?.next;
    // this.getPaidObjects(res.results);
  }

  loadMoreData() {
    this.isLoading = true;
    this._dashAPI.getMoreData(this.NextURL).subscribe((res: any) => {
      this.sharedSubScribeFunction(res);
      this.isLoading = false;
    });
  }

  handleAdjustPlayerData(arr: any) {
    let newData = [] as any;
    arr.map((obj: any) => {
      let element = { ...obj };
      let date = element.date;
      let year = date.split('-')[0];
      let monthIndex = parseInt(date.split('-')[1]);
      let month = this.months[monthIndex - 1];

      element.year = year;
      element.month = month;
      newData.push(element);
    });

    this.table = [...newData];
  }

  handlePlayerReportShownDate() {
    let fromDateArr = this.from.split('-');
    let toDateArr = this.to.split('-');

    this.playerFromDate = `${this.months[parseInt(fromDateArr[1]) - 1]} ${
      fromDateArr[0]
    }`;
    this.playerToDate = `${this.months[parseInt(toDateArr[1]) - 1]} ${
      toDateArr[0]
    }`;
  }

  ngOnInit(): void {
    this.handleBreadCrumbElement(this.type);

    if (this.type == 'AllPlayers') {
      this._dashAPI
        .getPaymentSummry('', this.year, this.month)
        .subscribe((res: any) => {
          this.NumberOfPayments = res.payments;
        });
      this._dashAPI
        .getTeamPayment('', this.year, this.month, 5)
        .subscribe((res: any) => this.sharedSubScribeFunction(res));
    } else if (this.type == 'Team') {
      this.getTeamObj();
      this._dashAPI
        .getPaymentSummry(this.team, this.year, this.month)
        .subscribe((res: any) => {
          this.NumberOfPayments = res.payments;
        });

      this._dashAPI
        .getTeamPayment(this.team, this.year, this.month, 5)
        .subscribe((res: any) => this.sharedSubScribeFunction(res));
    } else if (this.type == 'Player') {
      this.handlePlayerReportShownDate();
      this.getPlayerObj();
      this._dashAPI
        .getPlayerPayment(this.player, this.from, this.to, 5)
        .subscribe((res: any) => {
          this.handleAdjustPlayerData(res);
          console.log(res);
        });
    }
  }
}
