<div class="side-bar d-flex flex-column">
  <ul>
    <li class="title">Academy View</li>
    <li
      [routerLink]="['/']"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img
        src="assets/images/user-stats.svg"
        alt="user stats"
        [class.active-image]="isActive('/')"
      />
      <span>Users Statistics</span>
    </li>
    <li
      [routerLink]="['/staff']"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <img
        src="assets/images/staff-icon.svg"
        alt="staff"
        [class.active-image]="isActive('/staff')"
      />
      <span>Staff</span>
    </li>
    <li [routerLink]="['/teams']" routerLinkActive="active">
      <img
        src="assets/images/teams-icon.svg"
        alt="teams"
        [class.active-image]="isActive('/teams')"
      />
      <span>Teams</span>
    </li>
    <li [routerLink]="['/calendar']" routerLinkActive="active">
      <img
        src="assets/images/academy-calendar-icon.svg"
        alt="calendar"
        [class.active-image]="isActive('/calendar')"
      />
      <span>Academy Calender</span>
    </li>

    <li class="title">Academy Activities</li>
    <li [routerLink]="['/teams-activities']" routerLinkActive="active">
      <img
        src="assets/images/activites-icon.svg"
        alt="activities-icon"
        [class.active-image]="isActive('/teams-activities')"
      />
      <span>Teams Activities</span>
    </li>
    <li [routerLink]="['/playerPayment']" routerLinkActive="active">
      <img
        src="assets/images/player-payment-icon.svg"
        alt="player payment"
        [class.active-image]="isActive('/playerPayment')"
      />
      <span>Player Payment Recordings</span>
    </li>
    <li class="title">User Activity</li>
    <li [routerLink]="['/Platform-Statistics']" routerLinkActive="active">
      <img
        src="assets/images/platform-icon.svg"
        alt="platform stats"
        [class.active-image]="isActive('/Platform-Statistics')"
      />
      <span>Platform Statistics</span>
    </li>

    <li class="title">My Subscriptions</li>
    <li>
      <img
        src="assets/images/subscription-icon.svg"
        alt="subscription"
        [class.active-image]="isActive('/Platform-Statistics')"
      />
      <span>ScoutX Plus Subscription</span>
    </li>

    <li class="title">Reports</li>
    <li [routerLink]="['/my-reports']" routerLinkActive="active">
      <img
        src="assets/images/reports-icon.svg"
        alt="reports"
        [class.active-image]="isActive('/my-reports')"
      />
      <span>Player Reports </span>
    </li>
    <li [routerLink]="['/academy-reports']" routerLinkActive="active">
      <img
        src="assets/images/reports-icon.svg"
        alt="reports"
        [class.active-image]="isActive('/academy-reports')"
      />
      <span>Academy Reports</span>
    </li>
    <li [routerLink]="['/top-achievers']" routerLinkActive="active">
      <img
        src="assets/images/reports-icon.svg"
        alt="reports"
        [class.active-image]="isActive('/top-achievers')"
      />
      <span>Top Achievers</span>
    </li>
    <li [routerLink]="['/Subscription-Payments']" routerLinkActive="active">
      <img
        src="assets/images/reports-icon.svg"
        alt="reports"
        [class.active-image]="isActive('/Subscription-Payments')"
      />
      <span>Subscription Payments</span>
    </li>

    <!-- logout -->
    <li class="logout" (click)="logout()">
      <img src="assets/images/logout-icon.svg" alt="logout" />
      <span>Logout</span>
    </li>
  </ul>
</div>
