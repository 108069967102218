<!-- NEW  -->
<app-breadcrumb
  [breadcrumbItems]="breadcrumb"
  title="Users Statistics"
></app-breadcrumb>

<div class="flexRow">
  <div class="card">
    <h1>Select Branch</h1>
    <select disabled name="Branch" id="Branch">
      <option value="All" disabled selected>All</option>
    </select>
  </div>

  <div class="card">
    <div class="foot-container">
      <img src="assets/icons/foot.svg" />
    </div>
    <h2>
      Players: <span>{{ generalStats?.total_players_count }}</span>
    </h2>
  </div>
  <div class="card">
    <img src="assets/icons/whistle.svg" />
    <h2>
      Coaches: <span>{{ generalStats?.total_coaches_count }}</span>
    </h2>
  </div>
  <div class="card">
    <img src="assets/icons/ParentAndSon.svg" />
    <h2>
      Parents: <span>{{ generalStats?.total_parents_count }}</span>
    </h2>
  </div>
</div>

<div class="card mt-3">
  <div class="flexRow">
    <h1>Users Statistics</h1>
    <select
      name="year"
      id="year"
      [(ngModel)]="selectedYear"
      (change)="updateTheQraph()"
    >
      <option value="2018">2018</option>
      <option value="2019">2019</option>
      <option value="2020">2020</option>
      <option value="2021">2021</option>
      <option value="2022">2022</option>
      <option value="2023">2023</option>
      <option value="2024" selected>2024</option>
      <option value="2025">2025</option>
      <option value="2026">2026</option>
      <option value="2027">2027</option>
    </select>
  </div>
  <div class="flexRow mt-5">
    <div class="col-9">
      <canvas
        baseChart
        class="chart"
        [data]="barChartData"
        [options]="barChartOptions"
        [type]="barChartType"
      >
      </canvas>
    </div>
    <div class="col-3 text-right">
      <h1 class="players">Total Players</h1>
      <h1 class="players players-number">{{ totalPlayers }}</h1>

      <h1 class="parents">Total Parents</h1>
      <h1 class="parents players-number">{{ totalParents }}</h1>

      <h1 class="coaches">Total Coaches</h1>
      <h1 class="coaches players-number">{{ totalCoaches }}</h1>
    </div>
  </div>
</div>
<!-- done New -->
