<h6 class="ml-3" *ngIf="ArrayOfData[12] == '2'">Mental Stats</h6>
<h6 class="ml-3" *ngIf="ArrayOfData[12] == '1'">Physical Stats</h6>
<h6 class="ml-3" *ngIf="ArrayOfData[12] == '3'">Technical Stats</h6>
<h6 class="ml-3" *ngIf="ArrayOfData[12] == '5'">Goal Keeping Stats</h6>

<div class="row g-4 pageSpace">
  <div class="col-4" *ngFor="let data of tableData">
    <div class="attr-card py-3 d-flex justify-content-around">
      <div class="d-flex gap-3 align-items-center">
        <img
          src="assets/icons/{{ data.evaluation_attribute.name }}.png"
          alt="Pace"
        />
        <h6 class="m-0">{{ data.evaluation_attribute.name }}</h6>
      </div>
      <div class="d-flex align-items-center">
        <p class="m-0">{{ data.rate }}</p>
        <img
          *ngIf="data.is_increasing"
          src="assets/icons/greenArrow.png"
          style="width: 40px"
          alt=""
        />
        <!-- <img
          *ngIf="!data.is_increasing"
          src="assets/icons/redArrow.png"
          style="width: 40px"
          alt=""
        /> -->
      </div>
    </div>
  </div>

  <div class="row mt-5 g-4">
    <div
      class="col-4"
      *ngFor="let data of chartData; let i = index"
      id="Creativity"
      [class.page-break]="shouldPageBreak(i)"
    >
      <!-- Player Chart  -->
      <div style="background-color: #f3f6f9; padding: 15px">
        <h6 class="mb-4">{{ data.name }}</h6>
        <app-line-graph [graphData]="enhanceChartData(data)"></app-line-graph>
        <!-- <canvas
          baseChart
          class="chart"
          [data]="barChartData"
          [options]="barChartOptions"
          [type]="barChartType"
        >
        </canvas> -->
      </div>
    </div>
  </div>
</div>
