<app-breadcrumb
  [breadcrumbItems]="breadcrumb"
  title="Top Achievers - {{ selectAchievers }} - {{
    selectAchievers == 'players' ? playersReport : coachesReport
  }}"
></app-breadcrumb>

<div class="report bg-white">
  <div *ngIf="academyReportType == 'Achievers'" class="academyUsers">
    <div>
      <h1>Select Achievers<span class="astrix-color">*</span></h1>

      <select
        name="Selectachievers"
        id="Selectachievers"
        class="mt-3"
        [(ngModel)]="selectAchievers"
      >
        <option value="Select" disabled selected>Select</option>
        <option value="players">Players</option>
        <option value="coaches">Coaches</option>
      </select>
    </div>

    <div *ngIf="selectAchievers == 'players'" class="academyUsers">
      <div>
        <h1>Select team<span class="astrix-color">*</span></h1>
        <select class="mt-3" name="Team" id="Team" [(ngModel)]="selectedTeam">
          <!-- <option value="" disabled selected>Team name</option> -->
          <option value="">All teams</option>
          <option *ngFor="let team of teams" [value]="team.id">
            {{ team?.name }}
          </option>
        </select>
      </div>

      <div>
        <h1>Players report<span class="astrix-color">*</span></h1>

        <select
          name="playersReport"
          id="playersReport"
          [(ngModel)]="playersReport"
          class="mt-3"
        >
          <option value="" disabled selected>Select</option>
          <option value="stats">Stats</option>
          <option value="rating">Rating</option>
          <option value="attributes">Attributes</option>
          <option value="social">Social</option>
        </select>
      </div>

      <div *ngIf="playersReport == 'stats'">
        <h1>Stats<span class="astrix-color">*</span></h1>

        <select class="mt-3" name="stats" id="stats" [(ngModel)]="stats">
          <option value="" disabled selected>Select</option>
          <option value="matchPlayed">Matches played</option>
          <option value="participationPercentage">
            Participation percentage (attendance)
          </option>
          <option value="goals">Goals</option>
          <option value="assists">Assists</option>
          <option value="cleanSheets">Clean sheets</option>
        </select>

        <h1 class="marginTopRem">
          Select Tournament<span class="astrix-color">*</span>
        </h1>

        <select
          name="teamActivites"
          id="teamActivites"
          class="mt-3"
          [(ngModel)]="selectedTournaments"
        >
          <option value="" selected>All Tournament</option>
          <option
            *ngFor="let tournaments of allTournaments"
            [value]="tournaments.id"
          >
            {{ tournaments?.name }}
          </option>
        </select>
      </div>

      <div *ngIf="playersReport == 'attributes'" class="academyUsers mt-5">
        <div>
          <h1>Attributes</h1>
          <mat-radio-group
            aria-label="Select an option"
            [(ngModel)]="attributes"
            (change)="selectSpacificAttr()"
            class="mt-3"
          >
            <mat-radio-button
              *ngFor="let attr of achieversAttr"
              [value]="attr.id"
            >
              {{ attr.name }}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div>
          <h1>Select Attributes</h1>

          <select name="stats" id="stats" [(ngModel)]="selectedAttributes">
            <option value="" disabled selected>Select</option>
            <!-- <option value="all">All</option> -->
            <option
              *ngFor="let attr of achieverSelectionAttr"
              [value]="attr.id"
            >
              {{ attr.name }}
            </option>
          </select>
        </div>
      </div>

      <div *ngIf="playersReport == 'social'" class="mt-5">
        <h1>Social</h1>
        <mat-radio-group
          aria-label="Select an option"
          name="stats"
          id="stats"
          [(ngModel)]="social"
        >
          <mat-radio-button value="posts"> Posts </mat-radio-button>
          <mat-radio-button value="made"> Interactions made </mat-radio-button>
          <mat-radio-button value="received">
            Interactions received
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div *ngIf="selectAchievers == 'coaches'" class="academyUsers">
      <div>
        <h1>Coaches report</h1>
        <mat-radio-group
          aria-label="Select an option"
          name="playersReport"
          id="playersReport"
          [(ngModel)]="coachesReport"
          class="mt-3"
        >
          <mat-radio-button value="Instructions">
            Instructions
          </mat-radio-button>
          <mat-radio-button value="Evaluation"> Evaluation </mat-radio-button>
          <mat-radio-button value="Posts"> Posts created </mat-radio-button>
          <mat-radio-button value="Interaction">
            Interactions to posts
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>

  <div class="mt-5">
    <button (click)="viewReport()">View report</button>
  </div>

  <div class="text-center" id="error">
    <h3>Please fill required fields (*)</h3>
  </div>
</div>
