<div class="row">
  <div class="col-12">
    <div class="mb-3">
      <app-period-selection-bar
        (periodChange)="periodChangeHandler($event)"
      ></app-period-selection-bar>
    </div>
    <div
      class="d-flex flex-row flex-wrap justify-content-md-start justify-content-center align-items-center"
    >
      <div class="col-md-5">
        <app-info-sticker
          [number]="1600"
          [description]="'No. Registered Academies'"
          [iconName]="'academy'"
        >
        </app-info-sticker>
      </div>
      <div class="col-md-5">
        <app-info-sticker
          [number]="1270"
          [description]="'No. Active Users'"
          [iconName]="'activity'"
          [iconWidth]="44"
          [isColoredIcon]="false"
        >
        </app-info-sticker>
      </div>
    </div>
  </div>
</div>

<div class="row mt-3 justify-content-between">
  <div class="col-md-6 col-12">
    <!-- <app-bar-chart [title]="'Total Hours Spent'" [Icon]="'clock'"></app-bar-chart> -->
  </div>
  <div class="col-md-6 col-12">
    <!-- <app-bar-chart [title]="'Number Of Downloads'" [Icon]="'download'"></app-bar-chart> -->
  </div>
  <div class="col-md-6 col-12">
    <!-- <app-bar-chart [title]="'Total Views'" [Icon]="'eye'"></app-bar-chart> -->
  </div>
</div>
