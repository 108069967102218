<app-breadcrumb
  [breadcrumbItems]="breadcrumb"
  title="Teams Activities"
></app-breadcrumb>
<!-- header -->
<div class="row bg-white justify-content-between p-4">
  <div class="col-md-5">
    <div class="row">
      <div class="col-md-6">
        <div class="d-flex flex-column position-relative">
          <p class="select-date-text">Select Branch</p>

          <div class="position-relative">
            <select class="minimal" disabled="true">
              <option value="">All</option>
            </select>
            <div class="position-absolute line"></div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex flex-column position-relative">
          <p class="select-date-text">Select Team</p>
          <div class="position-relative">
            <select
              name="Teams"
              id="Teams"
              class="minimal"
              [(ngModel)]="selectedTeam"
              (change)="getTeamSummry(); getTeamsInfo()"
            >
              <option value="" disabled selected>Team</option>
              <option value="">All</option>
              <option *ngFor="let team of teams" [value]="team.id">
                {{ team.name }}
              </option>
            </select>

            <div class="position-absolute line"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-7 col-xl-7 mt-3">
    <div class="row h-100">
      <div class="col-md-6 d-flex justify-content-center align-items-center">
        <div class="d-flex justify-content-between gap-3 mt-auto">
          <div
            class="rounded-circle img-container d-flex justify-content-center align-items-center mt-auto"
          >
            <img src="assets/images/fieldWithOutBg.svg" class="" alt="" />
          </div>
          <div class="d-flex align-items-center gap-1 img-text">
            <span>{{ teamSummary?.matches }} </span>
            <span> Matches</span>
          </div>
        </div>
      </div>
      <div
        class="col-md-6 d-flex justify-content-center align-items-center mt-auto"
      >
        <div class="d-flex justify-content-between gap-3 mt-auto">
          <div
            class="rounded-circle img-container d-flex justify-content-center align-items-center"
          >
            <img src="assets/images/clock.svg" class="" alt="" />
          </div>
          <div class="d-flex align-items-center gap-1 img-text mt-2">
            <span>{{ teamSummary?.trainings }} </span>
            <span> Trainings</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mt-5 printMargin">
  <!-- select date text -->
  <div class="row">
    <div class="col-md-4 m-0 p-0">
      <div class="row">
        <p class="select-date-text">Select Date</p>

        <div class="col-md-6 p-0 pe-3">
          <div class="d-flex flex-column position-relative">
            <div class="position-relative">
              <select
                class="minimal"
                [(ngModel)]="selectedMonth"
                (change)="getTeamsInfo()"
              >
                <option
                  [value]="x + 1"
                  *ngFor="let month of monthes; let x = index"
                >
                  {{ month }}
                </option>
              </select>
              <div class="position-absolute line"></div>
            </div>
          </div>
        </div>
        <div class="col-md-6 p-0 ps-3">
          <div class="d-flex flex-column position-relative">
            <div class="position-relative">
              <select
                name="year"
                id="year"
                [(ngModel)]="selectedYear"
                class="minimal"
                (change)="getTeamsInfo()"
              >
                <option value="2018">2018</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024" selected>2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
              </select>
              <div class="position-absolute line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-4 printMargin">
    <div class="row">
      <div class="col-md-6 p-0">
        <div class="col-md-12 p-0">
          <div class="d-flex align-items-center bg-white">
            <img src="assets/images/fieldWithOutBg.svg" class="" alt="" />
            <div
              class="img-text-two d-flex justify-content-center align-items-center gap-2"
            >
              <span class="number-text">
                {{
                  matchSummary?.draws +
                    matchSummary?.loss +
                    matchSummary?.wins ===
                  "0"
                    ? "0"
                    : matchSummary?.draws +
                      matchSummary?.loss +
                      matchSummary?.wins
                }}
              </span>
              <span>Total Matches</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-6 p-0">
        <div class="row">
          <div class="col-md-12 p-0">
            <div class="d-flex align-items-center bg-white p-4">
              <img
                src="assets/images/shoe.svg"
                class=""
                alt=""
                style="width: 63px; height: 63px"
              />
              <div
                class="d-flex justify-content-center align-items-center gap-2 shoe-container"
              >
                <span>Team Performance</span>
              </div>
            </div>
          </div>
          <div class="row p-0 gap3">
            <div class="w-31 px-0 my-3">
              <app-team-activities-card
                [number]="matchSummary?.wins"
                [text]="'Wins'"
                [image]="'assets/images/cup.png'"
              ></app-team-activities-card>
            </div>

            <div class="w-31 px-0 my-3">
              <app-team-activities-card
                [number]="matchSummary?.loss"
                [text]="'Loss'"
                [image]="'assets/images/one.png'"
              ></app-team-activities-card>
            </div>

            <div class="w-31 px-0 my-3">
              <app-team-activities-card
                [number]="matchSummary?.draws"
                [text]="'Draws'"
                [image]="'assets/images/two.png'"
              ></app-team-activities-card>
            </div>
            <div class="w-31 px-0 my-3">
              <app-team-activities-card
                [number]="matchSummary?.goals_scored"
                [text]="'Goals scored'"
                [image]="'assets/images/three.png'"
              ></app-team-activities-card>
            </div>
            <div class="w-31 px-0 my-3">
              <app-team-activities-card
                [number]="matchSummary?.goals_conceded"
                [text]="'Goals conceded'"
                [image]="'assets/images/four.png'"
              ></app-team-activities-card>
            </div>
            <div class="w-31 px-0 my-3">
              <app-team-activities-card
                [number]="matchSummary?.goals_difference"
                [text]="'Goals difference'"
                [image]="'assets/images/five.png'"
              ></app-team-activities-card>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12 p-0">
            <div class="d-flex align-items-center bg-white p-4">
              <img src="assets/images/team-instructions.svg" class="" alt="" />
              <div
                class="d-flex justify-content-center align-items-center gap-2 shoe-container"
              >
                <span>Team Transactions</span>
              </div>
            </div>
          </div>
          <div class="row p-0 gap3">
            <div class="w-31 px-0 my-3">
              <app-team-activities-card
                [number]="matchSummary?.instructions"
                [text]="'Instructions'"
                [image]="'assets/images/team-1.png'"
              ></app-team-activities-card>
            </div>

            <div class="w-31 px-0 my-3">
              <app-team-activities-card
                [number]="matchSummary?.evaluations"
                [text]="'Evaluations'"
                [image]="'assets/images/team-2.png'"
              ></app-team-activities-card>
            </div>

            <div class="w-31 px-0 my-3">
              <app-team-activities-card
                [number]="matchSummary?.matches_with_squads"
                [text]="'Matches with entered squads'"
                [image]="'assets/images/team-3.png'"
              ></app-team-activities-card>
            </div>
            <div class="w-31 px-0 my-3">
              <app-team-activities-card
                [number]="matchSummary?.matches_with_lineups"
                [text]="'Matches with entered lineups'"
                [image]="'assets/images/team-4.png'"
              ></app-team-activities-card>
            </div>
            <div class="w-31 px-0 my-3">
              <app-team-activities-card
                [number]="matchSummary?.matches_with_goals"
                [text]="'matches with updated results'"
                [image]="'assets/images/team-5.png'"
                class="bg-danger"
              ></app-team-activities-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row mt-3 total-trainings-print">
  <div class="row p-0">
    <div class="col-md-6 p-0">
      <div class="d-flex align-items-center bg-white p-2 printStyle">
        <img src="assets/images/clock-2.png" class="" alt="" />
        <div
          class="img-text-two d-flex justify-content-center align-items-center gap-2"
        >
          <span class="number-text"> {{ trainingSummary.count }} </span>
          <span>Total Trainings</span>
        </div>
      </div>
      <div class="row p-0 g-4">
        <div class="col-md-6 ps-0">
          <app-team-activities-card
            [number]="trainingSummary.instructions"
            [text]="'Instructions'"
            [image]="'assets/images/team-1.png'"
          ></app-team-activities-card>
        </div>

        <div class="col-md-6 pe-0">
          <app-team-activities-card
            [number]="trainingSummary.evaluations"
            [text]="'Evaluations'"
            [image]="'assets/images/team-2.png'"
          ></app-team-activities-card>
        </div>

        <div class="col-md-6 ps-0">
          <app-team-activities-card
            [number]="trainingSummary.recorded_training_attendance"
            [text]="'Recorded trainings attendance'"
            [image]="'assets/images/whiteClock.png'"
          ></app-team-activities-card>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- charts -->
<div class="printMarginTop">
  <div class="card mt-3 px-5">
    <div class="flexRow">
      <h1>Match statistics for <span class="orange">All Teams</span></h1>
      <select
        name="yearrr"
        id="yearrr"
        [(ngModel)]="selectedYearMatches"
        class="p-0"
        (change)="updateTheQraph()"
      >
        <option value="2018">2018</option>
        <option value="2019">2019</option>
        <option value="2020">2020</option>
        <option value="2021">2021</option>
        <option value="2022">2022</option>
        <option value="2023">2023</option>
        <option value="2024" selected>2024</option>
        <option value="2025">2025</option>
        <option value="2026">2026</option>
        <option value="2027">2027</option>
      </select>
    </div>
    <div class="flexRow mt-5">
      <div class="col-9">
        <canvas
          #first="base-chart"
          baseChart
          class="chart"
          [data]="barChartData"
          [options]="barChartOptions"
          [type]="barChartType"
        >
        </canvas>
      </div>
      <div class="col-3 text-right">
        <h1 class="players color1">Matches</h1>
        <h1 class="players">{{ totalMatches }}</h1>

        <h1 class="parents color2">Instructions</h1>
        <h1 class="parents">{{ totalInstructions }}</h1>

        <h1 class="coaches color3">Evaluations</h1>
        <h1 class="coaches">{{ totalEvaluations }}</h1>

        <h1 class="coaches color4">Matches with entered squads</h1>
        <h1 class="coaches">{{ totalSquads }}</h1>

        <h1 class="coaches color5">Matches with entered Lineups</h1>
        <h1 class="coaches">{{ totalLineups }}</h1>

        <h1 class="coaches color6">Matches with Updated results</h1>
        <h1 class="coaches">{{ totalUpdates }}</h1>
      </div>
    </div>
  </div>
</div>
<div class="card mt-3 px-5">
  <div class="flexRow">
    <h1>Trainings statistics for <span class="orange">All Teams</span></h1>
    <select
      name="year"
      id="year"
      [(ngModel)]="selectedYearTraining"
      class="p-0"
      (change)="updateTheQraphTraining()"
    >
      <option value="2018">2018</option>
      <option value="2019">2019</option>
      <option value="2020">2020</option>
      <option value="2021">2021</option>
      <option value="2022">2022</option>
      <option value="2023">2023</option>
      <option value="2024" selected>2024</option>
      <option value="2025">2025</option>
      <option value="2026">2026</option>
      <option value="2027">2027</option>
    </select>
  </div>
  <div class="flexRow mt-5">
    <div class="col-9">
      <canvas
        #second="base-chart"
        baseChart
        class="chart"
        [data]="barChartDataTraining"
        [options]="barChartOptions"
        [type]="barChartType"
      >
      </canvas>
    </div>
    <div class="col-3 text-right">
      <h1 class="lightGreen">Trainings</h1>
      <h1 class="lightGreen">{{ totalTraining }}</h1>

      <h1 class="players color2">Instructions</h1>
      <h1 class="players">{{ totalInstructionsTraining }}</h1>

      <h1 class="parents color3">Evaluations</h1>
      <h1 class="parents">{{ totalEvaluationsTraining }}</h1>

      <h1 class="coaches color4">Recorded Training Attendance</h1>
      <h1 class="coaches">{{ totalAttendanceTraining }}</h1>
    </div>
  </div>
</div>
