import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { PeriodSelectionBarComponent } from './components/period-selection-bar/period-selection-bar.component';
import { InfoStickerComponent } from './components/info-sticker/info-sticker.component';
import { NgChartsModule } from 'ng2-charts';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { IconComponent } from './components/icon/icon.component';
import { AcademiesComponent } from './pages/academies/academies.component';
import { TransactionsComponent } from './pages/transactions/transactions.component';
import { LicenceReportsComponent } from './pages/licence-reports/licence-reports.component';
import { AcademyCardComponent } from './components/academy-card/academy-card.component';
import { DoughuntChartComponent } from './components/doughunt-chart/doughunt-chart.component';
import { LicenseReportCardComponent } from './components/license-report-card/license-report-card.component';
import { AcademyStatsComponent } from './pages/academy-stats/academy-stats.component';
import { UsersActivitiesComponent } from './pages/users-activities/users-activities.component';
import { SubscriptionsComponent } from './pages/subscriptions/subscriptions.component';
import { AcademyBillComponent } from './pages/academy-bill/academy-bill.component';
import { StatsCardComponent } from './components/stats-card/stats-card.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { PostCardComponent } from './components/post-card/post-card.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './services/interceptor/interceptor.service';
import { ReportsComponent } from './pages/reports/reports.component';
import { ReportPopoverComponent } from './components/report-popover/report-popover.component';
import { AttendanceComponent } from './components/attendance/attendance.component';
import { AttributeProgressComponent } from './components/attribute-progress/attribute-progress.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { MatchStatisticsComponent } from './components/match-statistics/match-statistics.component';
import { CoachesFeedbackComponent } from './components/coaches-feedback/coaches-feedback.component';
import { AcademyUsersPlayersComponent } from './components/academy-users-players/academy-users-players.component';
import { AcademyUsersParentsComponent } from './components/academy-users-parents/academy-users-parents.component';
import { AcademyCoachesComponent } from './components/academy-coaches/academy-coaches.component';
import { AcademyTeamsComponent } from './components/academy-teams/academy-teams.component';
import { StatsAchiversComponent } from './components/stats-achivers/stats-achivers.component';
import { RateAchiversComponent } from './components/rate-achivers/rate-achivers.component';
import { SocialAchiversComponent } from './components/social-achivers/social-achivers.component';
import { CoachesAchieversComponent } from './components/coaches-achievers/coaches-achievers.component';
import { PlayerAttrAchieversComponent } from './components/player-attr-achievers/player-attr-achievers.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { StaffComponent } from './pages/staff/staff.component';
import { StaffCardComponent } from './components/staff-card/staff-card.component';
import { UsersStatisticsComponent } from './pages/users-statistics/users-statistics.component';
import { TeamsComponent } from './pages/teams/teams.component';
import { CoachProfileComponent } from './pages/coach-profile/coach-profile.component';
import { CalenderComponent } from './pages/calender/calender.component';
import { ScheduleModule } from '@syncfusion/ej2-angular-schedule';
import { CalenderDayModalComponent } from './components/calender-day-modal/calender-day-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PlayerPaymentComponent } from './pages/player-payment/player-payment.component';
import { TeamsActivitiesComponent } from './pages/teams-activities/teams-activities.component';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { TeamActivitiesCardComponent } from './components/team-activities-card/team-activities-card.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AcademyReportsComponent } from './pages/academy-reports/academy-reports.component';
import { AcademyComponent } from './components/academy/academy.component';
import { TopAchieversComponent } from './pages/top-achievers/top-achievers.component';
import { AchieversComponent } from './components/achievers/achievers.component';
import { SubscriptionPaymentsComponent } from './pages/subscription-payments/subscription-payments.component';
import { PlatformStatisticsComponent } from './pages/platform-statistics/platform-statistics.component';
import { PlatformPlayersChartComponent } from './components/platform-players-chart/platform-players-chart.component';
import { PlatformParentsChartComponent } from './components/platform-parents-chart/platform-parents-chart.component';
import { PlatformCoachesChartComponent } from './components/platform-coaches-chart/platform-coaches-chart.component';
import { VisitedProfilePlayerCardComponent } from './components/visited-profile-player-card/visited-profile-player-card.component';
import { VisitedProfileParentCardComponent } from './components/visited-profile-parent-card/visited-profile-parent-card.component';
import { VisitedProfileCoachCardComponent } from './components/visited-profile-coach-card/visited-profile-coach-card.component';
import { PlayerReportMatchStatisticsComponent } from './components/player-report-match-statistics/player-report-match-statistics.component';
import { SubscriptionReportComponent } from './components/subscription-report/subscription-report.component';
import { TopVisitedProfileCardComponent } from './components/top-visited-profile-card/top-visited-profile-card.component';
import { FooterComponent } from './components/footer/footer.component';
import { LineGraphComponent } from './components/line-graph/line-graph.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    SideBarComponent,
    PeriodSelectionBarComponent,
    InfoStickerComponent,
    BarChartComponent,
    IconComponent,
    AcademiesComponent,
    TransactionsComponent,
    LicenceReportsComponent,
    AcademyCardComponent,
    DoughuntChartComponent,
    LicenseReportCardComponent,
    AcademyStatsComponent,
    UsersActivitiesComponent,
    SubscriptionsComponent,
    AcademyBillComponent,
    StatsCardComponent,
    UserCardComponent,
    PostCardComponent,
    ReportsComponent,
    ReportPopoverComponent,
    AttendanceComponent,
    AttributeProgressComponent,
    LineChartComponent,
    MatchStatisticsComponent,
    CoachesFeedbackComponent,
    AcademyUsersPlayersComponent,
    AcademyUsersParentsComponent,
    AcademyCoachesComponent,
    AcademyTeamsComponent,
    StatsAchiversComponent,
    RateAchiversComponent,
    SocialAchiversComponent,
    CoachesAchieversComponent,
    PlayerAttrAchieversComponent,
    NavbarComponent,
    BreadcrumbComponent,
    StaffComponent,
    StaffCardComponent,
    UsersStatisticsComponent,
    TeamsComponent,
    CoachProfileComponent,
    CalenderComponent,
    CalenderDayModalComponent,
    PlayerPaymentComponent,
    TeamsActivitiesComponent,
    TeamActivitiesCardComponent,
    AcademyReportsComponent,
    AcademyComponent,
    TopAchieversComponent,
    AchieversComponent,
    SubscriptionPaymentsComponent,
    PlatformStatisticsComponent,
    PlatformPlayersChartComponent,
    PlatformParentsChartComponent,
    PlatformCoachesChartComponent,
    VisitedProfilePlayerCardComponent,
    VisitedProfileParentCardComponent,
    VisitedProfileCoachCardComponent,
    PlayerReportMatchStatisticsComponent,
    SubscriptionReportComponent,
    TopVisitedProfileCardComponent,
    FooterComponent,
    LineGraphComponent,
  ],
  imports: [
    ScheduleModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgChartsModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatIconModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatDialogModule,
    HttpClientModule,
    MatInputModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatCheckboxModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
