<div
  class="d-flex w-100 align-items-center justify-content-between navbar-new fixed-top"
>
  <div class="p-2 logo d-flex">
    <img src="assets/images/ScoutXLogo.png" alt="logo" />
  </div>

  <div class="d-flex align-items-center club-data gap-2">
    <img [src]="academyLogo" alt="academy logo" class="acadLogo mr-3" />
    <!-- <div class="d-flex flex-column">
      <span>{{ userName }}</span>
      <span>{{ academyName }} Academy</span>
    </div> -->
  </div>
</div>
