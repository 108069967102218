import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-post-card',
  templateUrl: './post-card.component.html',
  styleUrls: ['./post-card.component.scss'],
})
export class PostCardComponent implements OnInit {
  @Input() index: number = 0;
  @Input() video: string = '';
  @Input() views: number = 0;
  @Input() title: string = '';
  @Input() added_by: string = '';
  @Input() likes_count: number = 0;
  @Input() comments_count: number = 0;
  @Input() tags: any = [];

  constructor() {}

  ngOnInit(): void {}
}
