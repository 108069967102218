import { Injectable } from '@angular/core';
import { AUTHENDPOINTS } from '../EndPoints/AuthEndPoints';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthServicesService {

  constructor(private http: HttpClient) { }

  Login(values: any) {
    return this.http.post(AUTHENDPOINTS.login.url, values)
  }

  GetAdminDetails() {
    return this.http.get(AUTHENDPOINTS.GetAdminDetails.url);
  }

}
