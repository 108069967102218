<div class="col-md-3">
  <div class="player-card bg-white p-4">
    <img src="assets/images/playerImage.png" alt="playerImage" />
    <h4>Aamn Madkour</h4>
    <h6>Player</h6>
    <div class="d-flex justify-content-around">
      <div class="d-flex gap-1 align-items-center">
        <p class="m-0">250</p>
        <img
          src="assets/icons/plus.png"
          alt="plus"
          style="width: 12px; height: 12px"
        />
      </div>
      <div class="d-flex gap-1 align-items-center">
        <p class="m-0">250</p>
        <img
          src="assets/icons/greenHeart.png"
          alt="greenHeart"
          style="width: 12px; height: 12px"
        />
      </div>
      <div class="d-flex gap-1 align-items-center">
        <p class="m-0">250</p>
        <img
          src="assets/icons/greenComment.png"
          alt="greenComment"
          style="width: 12px; height: 12px"
        />
      </div>
      <div class="d-flex gap-1 align-items-center">
        <p class="m-0">250</p>
        <img
          src="assets/icons/greenShare.png"
          alt="greenShare"
          style="width: 12px; height: 12px"
        />
      </div>
    </div>
  </div>
</div>
<div class="col-md-3">
  <div class="player-card bg-white p-4">
    <img src="assets/images/playerImage.png" alt="playerImage" />
    <h4>Aamn Madkour</h4>
    <h6>Player</h6>
    <div class="d-flex justify-content-around">
      <div class="d-flex gap-1 align-items-center">
        <p class="m-0">250</p>
        <img
          src="assets/icons/plus.png"
          alt="plus"
          style="width: 12px; height: 12px"
        />
      </div>
      <div class="d-flex gap-1 align-items-center">
        <p class="m-0">250</p>
        <img
          src="assets/icons/greenHeart.png"
          alt="greenHeart"
          style="width: 12px; height: 12px"
        />
      </div>
      <div class="d-flex gap-1 align-items-center">
        <p class="m-0">250</p>
        <img
          src="assets/icons/greenComment.png"
          alt="greenComment"
          style="width: 12px; height: 12px"
        />
      </div>
      <div class="d-flex gap-1 align-items-center">
        <p class="m-0">250</p>
        <img
          src="assets/icons/greenShare.png"
          alt="greenShare"
          style="width: 12px; height: 12px"
        />
      </div>
    </div>
  </div>
</div>
<div class="col-md-3">
  <div class="player-card bg-white p-4">
    <img src="assets/images/playerImage.png" alt="playerImage" />
    <h4>Aamn Madkour</h4>
    <h6>Player</h6>
    <div class="d-flex justify-content-around">
      <div class="d-flex gap-1 align-items-center">
        <p class="m-0">250</p>
        <img
          src="assets/icons/plus.png"
          alt="plus"
          style="width: 12px; height: 12px"
        />
      </div>
      <div class="d-flex gap-1 align-items-center">
        <p class="m-0">250</p>
        <img
          src="assets/icons/greenHeart.png"
          alt="greenHeart"
          style="width: 12px; height: 12px"
        />
      </div>
      <div class="d-flex gap-1 align-items-center">
        <p class="m-0">250</p>
        <img
          src="assets/icons/greenComment.png"
          alt="greenComment"
          style="width: 12px; height: 12px"
        />
      </div>
      <div class="d-flex gap-1 align-items-center">
        <p class="m-0">250</p>
        <img
          src="assets/icons/greenShare.png"
          alt="greenShare"
          style="width: 12px; height: 12px"
        />
      </div>
    </div>
  </div>
</div>
