import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
// import {
//   ChartOptions,
//   ChartType,
//   ChartDataSets,
//   ChartPluginsOptions,
// } from 'chart.js';
// import { Label } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit, OnChanges {
  // Colors
  fontColor: string = '#ED6623';
  lightFontColor: string = '#ED66236C';
  fontFamily: string = 'circular-bold';

  //Input Data
  @Input() isUserChart: Boolean = false;

  @Input() select: any = [
    { text: 'Coaches', isActive: false, value: 'coachuser' },
    { text: 'Players', isActive: true, value: 'playeruser' },
    { text: 'Parents', isActive: false, value: 'parentuser' },
  ];

  // @Input() title!: String;
  // @Input() Icon!: string;
  // @Input() xAxisTitle: string =  "Days" ;
  // @Input() barChartLabels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', ''];
  // @Input() data: number[] = [100, 200, 300, 400];
  // barChartData: ChartDataSets[] = [
  //   // { backgroundColor: this.lightFontColor, hoverBackgroundColor: this.fontColor },
  // ];

  @Output('userTypeChange') userTypeChange = new EventEmitter<string>();

  //Important consts
  // total!: number
  // realMaxValue!: number
  // virtualMinValue!: number

  //Data label Plugin options
  // dataLabelPluginOptions: ChartPluginsOptions = {
  //   legend: {
  //     display: true,
  //   },
  //   datalabels: {
  //     offset: 0,
  //     padding: 0,
  //     align: (context: any) => {
  //       let value = context.dataset.data[context.dataIndex];
  //       return value > this.virtualMinValue ? 'center' : 'end'
  //     },
  //     anchor: (context: any) => {
  //       let value = context.dataset.data[context.dataIndex];
  //       return value > this.virtualMinValue ? 'center' : 'end'
  //     },
  //     color: (context: any) => {
  //       let value = context.dataset.data[context.dataIndex];
  //       return value > this.virtualMinValue ? "#fff" : this.fontColor
  //     },
  //     font: (context: any) => {
  //       var width = context.chart.width;
  //       var size = Math.round(width / 32);
  //       return {
  //         size: size,
  //         family: this.fontFamily,
  //         padding:5
  //       }
  //     },
  //   }
  // }

  // stepSize: number = 1
  // // Chart Options
  // barChartType: ChartType = 'bar';
  // barChartLegend = false;
  // barChartPlugins = [DataLabelsPlugin];
  // barChartOptions: ChartOptions = {
  //   responsive: true,
  //   tooltips: { enabled: false },
  //   hover: { mode: undefined },
  //   scales: {
  //     xAxes: [{
  //       ticks: {
  //         fontColor: this.fontColor,
  //         fontSize: window.innerWidth <= 567 ? 10 : 13,
  //         fontFamily: this.fontFamily,
  //         padding: 10,

  //       },
  //       gridLines: {
  //         color: "transparent",
  //         display: true,
  //         drawBorder: false,
  //         zeroLineColor: "#000",
  //         zeroLineWidth: 1,
  //         drawTicks: false
  //       }
  //     }],
  //     yAxes: [{
  //       display: 'none',
  //       ticks: {
  //         fontColor: this.fontColor,
  //         fontSize: 18,
  //         max: this.realMaxValue,
  //         stepSize: this.stepSize,
  //         padding: 10,
  //         fontFamily: this.fontFamily,
  //         suggestedMin: 0,
  //       },
  //       gridLines: {
  //         color: "transparent",
  //         display: true,
  //         drawBorder: false,
  //         zeroLineColor: "#000",
  //         zeroLineWidth: 1,
  //         drawTicks: false
  //       }
  //     }]
  //   },
  //   plugins: this.dataLabelPluginOptions
  // };

  @Output('UserTypeChange') UserTypeChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {
    // this.barChartData[0].data = this.data
    // this.total = this.data.reduce((total, item) => (total + item));
    // this.realMaxValue = Math.max(...this.data)
    // this.virtualMinValue = Math.min(...this.data);
    // this.userTypeChange.emit("playeruser")
    // let stepSizeCalculation = Math.ceil((this.realMaxValue + this.virtualMinValue) / this.data.length)
    // this.stepSize = stepSizeCalculation
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this.barChartData[0].data = this.data
    // this.total = this.data.reduce((total, item) => (total + item));
    // this.realMaxValue = Math.max(...this.data)
    // this.virtualMinValue = Math.min(...this.data);
    // let stepSizeCalculation = Math.ceil((this.realMaxValue + this.virtualMinValue) / this.data.length)
    // this.stepSize = stepSizeCalculation
  }

  // handleChange(event: any) {
  //   if (event == "three_month") {
  //     this.UserTypeChange.emit("playeruser")
  //   } else {
  //     this.UserTypeChange.emit(event)
  //   }

  // }
}
