import { domain } from "./DomainUrl"



export const AUTHENDPOINTS = {
    login: {
        url: `${domain}login/`,
    },
    GetAdminDetails: {
        url:`${domain}users/me/`
    }

}