import { Component, OnInit, Input } from '@angular/core';
import { DashBordEndPointsService } from 'src/app/services/dash-bord-end-points.service';

@Component({
  selector: 'app-player-report-match-statistics',
  templateUrl: './player-report-match-statistics.component.html',
  styleUrls: ['./player-report-match-statistics.component.scss'],
})
export class PlayerReportMatchStatisticsComponent implements OnInit {
  @Input() from: any;
  @Input() to: any;
  @Input() teamId: any;
  @Input() position: any;
  @Input() userID: any;
  @Input() tournament: any;

  tableData: any;
  cardData: any;
  chartData: any;

  constructor(private _DashBordEndPointsService: DashBordEndPointsService) {}

  ngOnInit(): void {
    this._DashBordEndPointsService
      .getPlayerMatchSummary(
        this.userID,
        this.teamId,
        this.from,
        this.to,
        this.tournament
      )
      .subscribe((res: any) => {
        this.cardData = res;
        console.log(this.cardData);
      });

    this._DashBordEndPointsService
      .getPlayerMatchAttendancesTable(
        this.userID,
        this.teamId,
        this.from,
        this.to,
        this.tournament
      )
      .subscribe((res: any) => {
        this.tableData = res.results;
        console.log(this.tableData);
      });

    this._DashBordEndPointsService
      .getPlayerAttendancesChart(
        this.userID,
        this.teamId,
        this.from,
        this.to,
        'match',
        this.tournament
      )
      .subscribe((res: any) => {
        this.chartData = res;
        console.log(res);
      });
  }
}
