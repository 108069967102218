<div class="post-card">
  <img
    src="assets/images/post.png"
    class="w-100"
    height="200"
    style="object-fit: cover"
    *ngIf="!video"
  />

  <video
    class="w-100"
    height="200"
    style="object-fit: cover"
    *ngIf="video"
    controls
  >
    <source [src]="video" />
  </video>
  <div class="p-2 contentBlock">
    <div class="row align-items-center">
      <div class="col-md-8">
        <div class="text">
          <span class="number">{{ index }}.</span>{{ title }}
        </div>
      </div>
      <div class="col-md-4">
        <div class="views">{{ views }} Views</div>
      </div>
    </div>
    <div class="pl-3">
      <div class="added_by"><span class="mr-2">By</span> {{ added_by }}</div>
      <div class="row">
        <div class="col-4 counts p-0">{{ likes_count }} LIKES</div>
        <div class="col-6 counts pl-0">{{ comments_count }} COMMENTS</div>
      </div>
      <div class="row" *ngIf="tags.length > 0">
        <div class="col-2 p-0">Tags</div>
        <div class="col-10 tags">
          <span *ngFor="let tag of tags;let i=index">{{ tag?.name}}{{i !== tags.length-1?", ":"." }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
