<app-breadcrumb
  [breadcrumbItems]="breadcrumb"
  title="Users’ Activity"
></app-breadcrumb>
<div class="">
  <!-- Selecting Branch -->
  <div class="bg-white p-4 selecting-branch">
    <h5>Select Branch</h5>
    <select name="select-team" id="select-team" class="" disabled>
      <option value="Select Tournament" disabled selected>All Branches</option>
    </select>
  </div>

  <!-- Box Icons -->
  <div class="row gap-3 g-3">
    <div class="col p-0">
      <div
        class="bg-white d-flex gap-3 justify-content-center align-items-center py-3 card-container"
      >
        <div>
          <img
            src="assets/icons/Total Videos.png"
            alt="Total Videos"
            style="width: 50px"
          />
        </div>
        <div class="box-text">
          <h6 class="m-0">{{ postSummary?.videos || 0 }}</h6>
          <p class="m-0">Total Videos</p>
        </div>
      </div>
    </div>
    <div class="col p-0">
      <div
        class="bg-white d-flex gap-3 justify-content-center align-items-center py-3 card-container"
      >
        <div>
          <img
            src="assets/icons/Total Views.png"
            alt="Total Videos"
            style="width: 50px"
          />
        </div>
        <div class="box-text">
          <h6 class="m-0">{{ postSummary?.views || 0 }}</h6>
          <p class="m-0">Total Views</p>
        </div>
      </div>
    </div>
    <div class="col p-0">
      <div
        class="bg-white d-flex gap-3 justify-content-center align-items-center py-3 card-container"
      >
        <div>
          <img
            src="assets/icons/Total Likes.png"
            alt="Total Likes"
            style="width: 50px"
          />
        </div>
        <div class="box-text">
          <h6 class="m-0">{{ postSummary?.likes || 0 }}</h6>
          <p class="m-0">Total Likes</p>
        </div>
      </div>
    </div>
    <div class="col p-0">
      <div
        class="bg-white d-flex gap-3 justify-content-center align-items-center py-3 card-container"
      >
        <div>
          <img
            src="assets/icons/Total Comments.png"
            alt="Total Comments"
            style="width: 50px"
          />
        </div>
        <div class="box-text">
          <h6 class="m-0">{{ postSummary?.comments || 0 }}</h6>
          <p class="m-0">Total Comments</p>
        </div>
      </div>
    </div>
    <div class="col p-0">
      <div
        class="bg-white d-flex gap-3 justify-content-center align-items-center py-3 card-container"
      >
        <div>
          <img
            src="assets/icons/Total Shares.png"
            alt="Total Shares"
            style="width: 50px"
          />
        </div>
        <div class="box-text">
          <h6 class="m-0">{{ postSummary?.shares || 0 }}</h6>
          <p class="m-0">Total Shares</p>
        </div>
      </div>
    </div>
  </div>

  <!-- Platform Statistics charts -->
  <div class="card mt-3 p-4">
    <div class="d-flex justify-content-between">
      <div>
        <h1>Platform Statistics</h1>
        <p>Videos & Interactions</p>
      </div>
      <select
        name="year"
        id="year"
        [(ngModel)]="selectedYear"
        (change)="setGraphData()"
      >
        <option value="2018">2018</option>
        <option value="2019">2019</option>
        <option value="2020">2020</option>
        <option value="2021">2021</option>
        <option value="2022">2022</option>
        <option value="2023">2023</option>
        <option value="2024" selected>2024</option>
        <option value="2025">2025</option>
        <option value="2026">2026</option>
        <option value="2027">2027</option>
      </select>
    </div>

    <ul class="nav nav-tabs">
      <li class="nav-item position-relative">
        <a
          class="nav-link position-relative"
          [class.active]="selectedChart === 'playeruser'"
          aria-current="page"
          (click)="showChart('playeruser')"
          >Players</a
        >
        <div
          *ngIf="selectedChart === 'playeruser'"
          style="
            background: white;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 100%;
            z-index: 1;
            padding: 21px;
          "
        ></div>
      </li>
      <li class="nav-item position-relative">
        <a
          class="nav-link position-relative"
          [class.active]="selectedChart === 'parentuser'"
          (click)="showChart('parentuser')"
          >Parents</a
        >
        <div
          *ngIf="selectedChart === 'parentuser'"
          style="
            background: white;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 100%;
            z-index: 1;
            padding: 21px;
          "
        ></div>
      </li>
      <li class="nav-item position-relative">
        <a
          class="nav-link position-relative"
          [class.active]="selectedChart === 'coachuser'"
          (click)="showChart('coachuser')"
          >Coaches</a
        >
        <div
          *ngIf="selectedChart === 'coachuser'"
          style="
            background: white;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 100%;
            z-index: 1;
            padding: 21px;
          "
        ></div>
      </li>
    </ul>

    <div class="flexRow mt-5">
      <div class="col-12" id="players">
        <!-- Small Color Circles  -->
        <div class="d-flex justify-content-between interactions">
          <h6>
            <span class="interaction-number">{{ totalInteractions }} </span>
            Total Interactions
          </h6>
          <div class="d-flex gap-3">
            <div class="circle-color-container d-flex gap-1">
              <div class="circle-color videos"></div>
              <span>Videos</span>
            </div>
            <div class="circle-color-container d-flex gap-1">
              <div class="circle-color views"></div>
              <span>Views</span>
            </div>
            <div class="circle-color-container d-flex gap-1">
              <div class="circle-color comments"></div>
              <span>Comments</span>
            </div>
            <div class="circle-color-container d-flex gap-1">
              <div class="circle-color likes"></div>
              <span>Likes</span>
            </div>
            <!-- <div class="circle-color-container d-flex gap-1">
              <div class="circle-color shares"></div>
              <span>Shares</span>
            </div> -->
          </div>
        </div>
        <!-- Player Chart  -->
        <canvas
          baseChart
          class="chart"
          [data]="barChartData"
          [options]="barChartOptions"
          [type]="barChartType"
        >
        </canvas>
      </div>
    </div>
  </div>

  <!-- Top Videos -->
  <div class="mt-4 card p-4">
    <!-- Header Text -->
    <div class="header-text-video d-flex justify-content-between">
      <div>
        <h6>Top Videos</h6>
      </div>
      <div class="gap-3 d-flex justify-content-between align-items-center">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [checked]="selectedSortBy === '-reactions_count'"
            value="reactions_count"
            id="Likes"
            (change)="sortVideosBy('-reactions_count')"
          />
          <label class="form-check-label" for="Likes"> Likes </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="Views"
            [checked]="selectedSortBy === '-views_count'"
            (change)="sortVideosBy('-views_count')"
          />
          <label class="form-check-label" for="Views"> Views </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="Comments"
            [checked]="selectedSortBy === '-comments_count'"
            (change)="sortVideosBy('-comments_count')"
          />
          <label class="form-check-label" for="Comments"> Comments </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="Shared"
            [checked]="selectedSortBy === '-shares_count'"
            (change)="sortVideosBy('-shares_count')"
          />
          <label class="form-check-label" for="Shared"> Shared </label>
        </div>
        <div>
          <select
            name="year"
            id="year"
            [(ngModel)]="selectedDate"
            (ngModelChange)="onDateChange()"
          >
            <option value="week" selected>This Week</option>
            <option value="month">This Month</option>
            <option value="year">This Year</option>
            <option value="three_month">Anytime</option>
          </select>
        </div>
      </div>
    </div>

    <!-- Videos -->
    <div class="row g-5">
      <div class="col-md-3 p-0" *ngFor="let video of videoData; let i = index">
        <div class="px-2">
          <div class="video-player">
            <video
              width="100%"
              height="200px"
              preload="auto"
              class="video"
              #videoElement
              controls
              (play)="pauseOtherVideos(i)"
            >
              <source [src]="sanitizeVideoUrl(video?.video)" />

              <!-- Add fullscreen button -->
            </video>

            <!-- <div
              class="orange-circle"
              *ngIf="showPlayButton(i)"
              (click)="togglePlay(i)"
            >
              <div class="controls">
                <div class="controls__button toggleButton" title="Toggle Play">
                  <span style="margin-left: 5px">
                    {{ videoStates[i] ? "❚❚" : "►" }}
                  </span>
                </div>
              </div>
            </div>
            <div class="progress" (click)="scrub($event, i)">
              <div
                class="progress__filled"
                [style.width]="percentage[i] + '%'"
              ></div>
            </div>
            <div class="fullScreen">
              <button (click)="openFullscreen(videoElement)">Fullscreen</button>
            </div> -->
          </div>

          <h6 class="m-0 mt-2" style="height: 40px">{{ video.title }}</h6>
          <h2 class="m-0 mt-2" style="height: 20px">
            {{ video.user.username }} ({{ video.user.user_type }})
          </h2>
          <h2 class="m-0 mt-2" style="height: 20px">
            {{ video.created_at | date : "medium" }}
          </h2>

          <div class="d-flex justify-content-between video-caption pt-2 pb-1">
            <div class="d-flex justify-content-center align-items-center gap-1">
              <p>{{ video?.reactions_count }}</p>
              <img src="assets/icons/orangeHeart.png" alt="orangeHeart" />
            </div>
            <div class="d-flex justify-content-center align-items-center gap-1">
              <p>{{ video?.views_count }}</p>
              <img src="assets/icons/greyEye.png" alt="greyEye" />
            </div>
            <div class="d-flex justify-content-center align-items-center gap-1">
              <p>{{ video?.comments_count }}</p>
              <img src="assets/icons/greyComment.png" alt="greyComment" />
            </div>
            <div class="d-flex justify-content-center align-items-center gap-1">
              <p>{{ video?.shares_count }}</p>
              <img src="assets/icons/greyShare.png" alt="greyShare" />
            </div>
          </div>
          <p class="m-0 player-name">
            {{ video?.username }}
          </p>
          <div class="row g-2">
            <div class="col" *ngFor="let tag of video.tags">
              <div class="hashTag">
                <span>
                  {{ tag ? tag : "-" }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Top Visited Profiles  -->
  </div>
  <!-- Top Visited Profiles -->
  <!-- Header Text -->
  <div class="mt-4 d-flex justify-content-between align-items-center">
    <div class="top-visited-profile-text">
      <h5>Top Visited Profiles</h5>
    </div>
    <div class="d-flex gap-4 selectors w-50 justify-content-end">
      <select
        name="year"
        id="year"
        class="months"
        [(ngModel)]="selectedMonthTopVisited"
        (ngModelChange)="onDateChangeTopVistied()"
      >
        <option
          value="{{ i }}"
          selected
          *ngFor="let month of months; let i = index"
        >
          {{ month }}
        </option>
      </select>
      <select
        [(ngModel)]="selectedDateTopVisited"
        (ngModelChange)="onDateChangeTopVistied()"
        name="year"
        id="year"
      >
        <option value="2020">2020</option>
        <option value="2021">2021</option>
        <option value="2022">2022</option>
        <option value="2023">2023</option>
        <option value="2024" selected>2024</option>
        <option value="2025">2025</option>
      </select>
    </div>
  </div>
  <!-- Player Cards -->

  <div class="mt-4 card visitied">
    <app-top-visited-profile-card
      [usersTopVisited]="usersTopVisitedData"
    ></app-top-visited-profile-card>
  </div>

  <!-- Top Interactive Users -->
  <div class="bg-white py-5 px-3 mt-5 inter-users">
    <div class="d-flex justify-content-between align-items-center">
      <div class="top-visited-profile-text">
        <h5>Top Interactive Users</h5>
        <h6 class="interactions-text">Videos & Interactions</h6>
      </div>
      <div class="d-flex gap-4 selectors w-50 justify-content-end">
        <select
          name="year"
          id="year"
          class="months"
          [(ngModel)]="selectedMonthTopInteractive"
          (ngModelChange)="onDateChangeTopInteractive()"
        >
          <option
            value="{{ i }}"
            selected
            *ngFor="let month of months; let i = index"
          >
            {{ month }}
          </option>
        </select>
        <select
          name="year"
          id="year"
          [(ngModel)]="selectedDateTopInteractive"
          (ngModelChange)="onDateChangeTopInteractive()"
        >
          <option value="2020">2020</option>
          <option value="2021">2021</option>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
          <option value="2024" selected>2024</option>
          <option value="2025">2025</option>
        </select>
      </div>
    </div>
    <ul class="nav nav-tabs mt-4">
      <li class="nav-item position-relative">
        <a
          class="nav-link position-relative"
          [class.active]="selectedUser === ''"
          aria-current="page"
          (click)="showUsers('')"
          >All</a
        >
        <div
          *ngIf="selectedUser === ''"
          style="
            background: white;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 100%;
            z-index: 1;
            padding: 21px;
          "
        ></div>
      </li>
      <li class="nav-item position-relative">
        <a
          class="nav-link position-relative"
          [class.active]="selectedUser === 'playeruser'"
          aria-current="page"
          (click)="showUsers('playeruser')"
          >Players</a
        >
        <div
          *ngIf="selectedUser === 'playeruser'"
          style="
            background: white;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 100%;
            z-index: 1;
            padding: 21px;
          "
        ></div>
      </li>
      <li class="nav-item position-relative">
        <a
          class="nav-link position-relative"
          [class.active]="selectedUser === 'parentuser'"
          (click)="showUsers('parentuser')"
          >Parents</a
        >
        <div
          *ngIf="selectedUser === 'parentuser'"
          style="
            background: white;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 100%;
            z-index: 1;
            padding: 21px;
          "
        ></div>
      </li>
      <li class="nav-item position-relative">
        <a
          class="nav-link position-relative"
          [class.active]="selectedUser === 'coachuser'"
          (click)="showUsers('coachuser')"
          >Coaches</a
        >
        <div
          *ngIf="selectedUser === 'coachuser'"
          style="
            background: white;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 100%;
            z-index: 1;
            padding: 21px;
          "
        ></div>
      </li>
    </ul>

    <div class="row">
      <app-visited-profile-coach-card
        [userData]="userData"
      ></app-visited-profile-coach-card>
    </div>
  </div>
</div>
