import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { STATSENDPOINTS } from '../EndPoints/StatsEndPoints';

@Injectable({
  providedIn: 'root',
})
export class StatsService {
  constructor(private http: HttpClient) {}

  userStats(date: any, type = '') {
    return this.http.get(STATSENDPOINTS.user.url(date, type));
  }

  teamStats(date: any) {
    return this.http.get(STATSENDPOINTS.teams.url(date));
  }

  platFormStats(date: any, interaction = '') {
    return this.http.get(STATSENDPOINTS.platForm.url(date, interaction));
  }

  chartsData(date: any) {
    return this.http.get(STATSENDPOINTS.charts.url(date));
  }

  video(date: any) {
    return this.http.get(STATSENDPOINTS.video.url(date));
  }

  usersTopVisited(date: any) {
    return this.http.get(STATSENDPOINTS.usersTopVisited.url(date));
  }

  usersTopInteractive(date: any, type: any = '') {
    let usedTypes;
    if (type.length == 0) usedTypes = 'playeruser,coachuser,parentuser';
    else usedTypes = type;
    return this.http.get(
      STATSENDPOINTS.usersTopInteractive.url(date, usedTypes)
    );
  }
}
