import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-academy-bill',
  templateUrl: './academy-bill.component.html',
  styleUrls: ['./academy-bill.component.scss']
})
export class AcademyBillComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
