<div>
  <h1>{{ cardDate | date : "EEEE,d MMMM yyyy" }}</h1>
  <div class="row">
    <div class="col-6" *ngIf="isMatch">
      <div *ngFor="let event of eventInThisDay">
        <div class="card" *ngIf="event.match">
          <img src="../../../assets/icons/greenPitch.png" />
          <h2>{{ event.coach }}</h2>
          <h3 class="fs18">
            Match: {{ event.from_time | date : "shortTime" }}
          </h3>
          <h4 class="fs14">Location : {{ event.location }}</h4>
          <h4 class="fs14" *ngIf="event.match.tournament_name">
            Tournament : {{ event.match.tournament_name }}
          </h4>
          <h5 class="fs14">
            ({{ event.match.team }})
            <img
              class="vs"
              src="../../../assets/icons/vs.png"
              *ngIf="!event.match.opponent_team_score"
            />
            <span *ngIf="event.match.opponent_team_score"
              >{{ event.match.team_score }} -
              {{ event.match.opponent_team_score }}</span
            >
            ( {{ event.match.opponent_team_name || event.match.opponent_team }})
          </h5>
        </div>
      </div>
    </div>
    <div class="col-6" *ngIf="isTraining">
      <div *ngFor="let event of eventInThisDay">
        <div class="card" *ngIf="event.training">
          <img src="../../../assets/icons/greenClock.png" />
          <h2>{{ event.training.coach }}</h2>
          <h3 class="fs18">
            Training: {{ event.from_time | date : "shortTime" }}
          </h3>
          <h6 class="fs14">{{ event.training.team }}</h6>
          <h4 class="fs14">Location : {{ event.location }}</h4>
        </div>
      </div>
    </div>
  </div>
</div>
