import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { MobileEndPointsService } from 'src/app/services/mobileEndPoints/mobile-end-points.service';

@Component({
  selector: 'app-achievers',
  templateUrl: './achievers.component.html',
  styleUrls: ['./achievers.component.scss'],
})
export class AchieversComponent implements OnInit {
  breadcrumb = [
    { label: 'Dashboard', path: '', active: false },
    { label: 'Reports', path: '#', active: false },
    { label: 'View Reports', path: '/staff', active: true },
  ];
  selectedAttributes = 'all';
  coachesReport = 'Instructions';
  social = 'posts';
  attributes = '1';
  stats = 'matchPlayed';
  playersReport = 'stats';
  selectAchievers = 'players';
  teamActivites = 'training';
  allCoaches: any = [];
  selectCoaches = 'all';
  playerGender = '';
  fromTime = '';
  toTime = '';
  ReportType = 'Academy report';
  academyReportType = 'Achievers';
  userType = 'players';
  playerFilter = 'all';
  teams: any = [];
  players: any = [];
  evaluationAttributeCategories: any = [];
  // achievers case
  achieversAttr: any = [];
  achieverSelectionAttr: any = [];
  // done achievers
  ReportDetails: any = 'Attendance';
  selectedTeam = '';
  developmentArea = 'Coaches Feedback';
  selectedPlayer = '';
  attendanceCategory = 'match';
  attributeCategory = '1';
  selectedTournaments = '';
  allTournaments: any = [];

  @Output() newItemEvent = new EventEmitter<string>();
  constructor(private _MobileEndPointsService: MobileEndPointsService) {}

  ngOnInit(): void {
    this._MobileEndPointsService.getCoaches().subscribe((res: any) => {
      this.allCoaches = res.results;
    });
    this._MobileEndPointsService.getTeams().subscribe((res: any) => {
      this.teams = res.results;
    });
    this._MobileEndPointsService
      .getAllEvaluationAttribute()
      .subscribe((res: any) => {
        this.achieversAttr = res;
        this.attributes = res[0].id;
        this.selectSpacificAttr();
      });
    this._MobileEndPointsService.getAllTournaments().subscribe((res: any) => {
      this.allTournaments = res.results;
    });
  }

  selectSpacificAttr() {
    this.achieversAttr.map((e: any) => {
      if (e.id == +this.attributes) {
        this.achieverSelectionAttr = e.evaluationattribute_set;
      }
    });
    this.selectedAttributes = this.achieverSelectionAttr[0].id;
  }

  viewReport() {
    this.newItemEvent.emit(
      [
        this.ReportType,
        this.academyReportType,
        this.userType,
        this.playerFilter,
        this.playerGender,
        this.selectedTeam,
        this.selectCoaches,
        this.teamActivites,
        this.selectAchievers,
        this.playersReport,
        this.stats,
        this.social,
        this.coachesReport,
        this.attributes,
        this.selectedAttributes,
        this.selectedTournaments,
      ].toString()
    );
  }

  importPlayers() {
    this._MobileEndPointsService
      .getTeamPlayers(+this.selectedTeam)
      .subscribe((res: any) => {
        this.players = res.results;
      });
  }

  importAttributeCategory() {
    this._MobileEndPointsService
      .getEvaluationAttributeCategories(this.selectedPlayer.split(',')[1])
      .subscribe((res: any) => {
        this.evaluationAttributeCategories = res;
      });
  }
}
