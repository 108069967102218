import { Component, Input, OnInit } from '@angular/core';
import { DashBordEndPointsService } from 'src/app/services/dash-bord-end-points.service';

@Component({
  selector: 'app-academy-users-parents',
  templateUrl: './academy-users-parents.component.html',
  styleUrls: ['./academy-users-parents.component.scss'],
})
export class AcademyUsersParentsComponent implements OnInit {
  @Input() teamID: any = '';
  playerData: any;
  playerCount: any;
  academyName = localStorage.getItem('academyName');

  constructor(private _DashBordEndPointsService: DashBordEndPointsService) {}

  ngOnInit(): void {
    console.log('ssssss');

    this._DashBordEndPointsService
      .getAcademyUsersParents(this.teamID)
      .subscribe((res: any) => {
        this.playerData = res.results;
        this.playerCount = res.count;
      });
  }
}
