import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/providers/user.service';
import { AuthServicesService } from 'src/app/services/AuthServices/auth-services.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isError = false;
  constructor(
    private router: Router,
    private _userService: UserService,
    private AuthService: AuthServicesService
  ) {}

  ngOnInit(): void {
    let temp = localStorage.getItem('token'); // saving user token
    if (temp) {
      this._userService.user$.next(temp);
      this.router.navigate(['/']); // navigation to main page
    }
  }

  onLogin(form: NgForm) {
    let requestObject = {
      email_or_phone: form.form.value.email.toLowerCase(),
      password: form.form.value.password,
    };
    this.AuthService.Login(requestObject)
      .toPromise()
      .then((res: any) => {
        if (res.user_type == 'adminuser') {
          localStorage.setItem('token', res['access']); // saving user token
          this._userService.user$.next(res['access']);
          this.router.navigate(['/']); // navigation to main page
          this.isError = false;
        }
      })
      .catch((err) => {
        this.isError = true;
      });
  }
}
