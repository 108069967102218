<app-academy
  (newItemEvent)="reportProp($event)"
  *ngIf="!reportTypePreview"
></app-academy>

<div class="main" *ngIf="reportTypePreview">
  <div class="d-flex flex-row w-100 justify-content-between notPrintView">
    <img
      src="assets/icons/backArrow.png"
      (click)="back()"
      style="width: 29px"
      class="pointer"
    />
    <img
      src="assets/icons/printIcon.png"
      (click)="print()"
      style="width: 29px"
      class="pointer"
    />
  </div>

  <!-- <div class="notPrintView row mb-5 mx-3 mt-4 justify-content-between">
    <img
      src="assets/images/scoutxLogoTran.png"
      (click)="back()"
      class="align-self-center pointer"
      style="width: 251px; height: 83px"
    />
    <img
      src="assets/icons/strikeLogo.png"
      (click)="print()"
      style="width: 125px"
      class="pointer"
    />
  </div> -->

  <div class="row pageBreak" *ngIf="ArrayOfData[0] != 'Academy report'">
    <div class="col-3">
      <img src="assets/images/user.png" *ngIf="!reportPlayer?.picture" />
      <img [src]="reportPlayer?.picture" *ngIf="reportPlayer?.picture" />
    </div>
    <div class="col-5">
      <h1>{{ reportPlayer.username }}</h1>
      <h4>{{ reportPlayer.academy.name }}</h4>
      <h3>Team</h3>
      <h4>{{ reportPlayer.team_obj.name }}</h4>
      <h3>Position</h3>
      <h4>{{ reportPlayer.primary_position }}</h4>
      <h3>Age</h3>
      <h5>{{ reportPlayer.age }} yrs</h5>
    </div>

    <div class="col-4">
      <h4>
        {{ ArrayOfData[1] }} Report<span
          *ngIf="ArrayOfData[1] == 'Performance'"
        >
          - {{ ArrayOfData[3] }}</span
        >
      </h4>
      <h3>From</h3>
      <h6>
        <span>{{ ArrayOfData[5] }}</span>
      </h6>
      <h3>To</h3>
      <h6>
        <span>{{ ArrayOfData[6] }} </span>
      </h6>
    </div>
  </div>

  <app-attendance
    *ngIf="ArrayOfData[1] == 'Attendance'"
    [attendanceType]="ArrayOfData[2]"
    [from]="ArrayOfData[5]"
    [to]="ArrayOfData[6]"
    [teamId]="ArrayOfData[7]"
    [userID]="ArrayOfData[8]"
    [tournament]="ArrayOfData[15]"
  ></app-attendance>

  <app-match-statistics
    *ngIf="ArrayOfData[3] == 'Matches Statistics'"
    [position]="reportPlayer.primary_position"
    [from]="ArrayOfData[5]"
    [to]="ArrayOfData[6]"
    [teamId]="ArrayOfData[7]"
    [userID]="ArrayOfData[8]"
  ></app-match-statistics>

  <app-attribute-progress
    *ngIf="ArrayOfData[3] == 'Attribute progress'"
    [type]="ArrayOfData[4]"
    [from]="ArrayOfData[5]"
    [to]="ArrayOfData[6]"
    [teamId]="ArrayOfData[7]"
    [userID]="ArrayOfData[8]"
  ></app-attribute-progress>

  <app-coaches-feedback
    *ngIf="
      ArrayOfData[3] == 'Coaches Feedback' && ArrayOfData[1] == 'Performance'
    "
    [from]="ArrayOfData[5]"
    [to]="ArrayOfData[6]"
    [userID]="ArrayOfData[8]"
  ></app-coaches-feedback>

  <app-academy-users-players
    *ngIf="ArrayOfData[1] == 'players'"
    [gender]="ArrayOfData[4]"
    [teamID]="ArrayOfData[5]"
  >
  </app-academy-users-players>

  <app-academy-users-parents
    *ngIf="ArrayOfData[1] == 'Parents'"
    [teamID]="ArrayOfData[5]"
  ></app-academy-users-parents>

  <app-academy-coaches
    *ngIf="
      ArrayOfData[0] == 'Academy report' && ArrayOfData[1] == 'Academy coaches'
    "
    [coachID]="ArrayOfData[6]"
  ></app-academy-coaches>

  <app-academy-teams
    *ngIf="
      ArrayOfData[0] == 'Academy report' && ArrayOfData[1] == 'Academy teams'
    "
    [type]="ArrayOfData[7]"
    [team]="ArrayOfData[5]"
    [tournament]="ArrayOfData[15]"
    [fromTime]="ArrayOfData[16]"
    [toTime]="ArrayOfData[17]"
  >
  </app-academy-teams>

  <app-stats-achivers
    *ngIf="
      ArrayOfData[0] == 'Academy report' &&
      ArrayOfData[1] == 'Achievers' &&
      ArrayOfData[8] == 'players' &&
      ArrayOfData[9] == 'stats'
    "
    [stats]="ArrayOfData[10]"
    [team]="ArrayOfData[5]"
  ></app-stats-achivers>

  <app-rate-achivers
    *ngIf="
      ArrayOfData[0] == 'Academy report' &&
      ArrayOfData[1] == 'Achievers' &&
      ArrayOfData[8] == 'players' &&
      ArrayOfData[9] == 'rating'
    "
    [team]="ArrayOfData[5]"
  ></app-rate-achivers>

  <app-social-achivers
    *ngIf="
      ArrayOfData[0] == 'Academy report' &&
      ArrayOfData[1] == 'Achievers' &&
      ArrayOfData[8] == 'players' &&
      ArrayOfData[9] == 'social'
    "
    [social]="ArrayOfData[11]"
    [team]="ArrayOfData[5]"
  ></app-social-achivers>

  <app-player-attr-achievers
    *ngIf="
      ArrayOfData[0] == 'Academy report' &&
      ArrayOfData[1] == 'Achievers' &&
      ArrayOfData[8] == 'players' &&
      ArrayOfData[9] == 'attributes'
    "
    [catId]="ArrayOfData[13]"
    [attrID]="ArrayOfData[14]"
    [team]="ArrayOfData[5]"
  ></app-player-attr-achievers>

  <app-coaches-achievers
    *ngIf="
      ArrayOfData[0] == 'Academy report' &&
      ArrayOfData[1] == 'Achievers' &&
      ArrayOfData[8] == 'coaches'
    "
    [type]="ArrayOfData[12]"
  ></app-coaches-achievers>
</div>
