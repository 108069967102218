import { Component, Input, OnInit } from '@angular/core';
import { DashBordEndPointsService } from 'src/app/services/dash-bord-end-points.service';

@Component({
  selector: 'app-rate-achivers',
  templateUrl: './rate-achivers.component.html',
  styleUrls: ['./rate-achivers.component.scss'],
})
export class RateAchiversComponent implements OnInit {
  tableData: any;
  @Input() team: any = '';

  constructor(private _DashBordEndPointsService: DashBordEndPointsService) {}

  ngOnInit(): void {
    this._DashBordEndPointsService
      .getStatsAchiversRate(this.team)
      .subscribe((res: any) => {
        this.tableData = res.results;
        console.log(this.tableData);
      });
  }
}
