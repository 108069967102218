<div class="pageSpace">
  <div class="mt-5">
    <div class="row">
      <div class="col-4">
        <div class="py-4 d-flex justify-content-between px-4 stats">
          <div>
            <h6 class="position-number">{{ cardData.total_matches }}</h6>
            <h6 class="matches-played">Matches Played</h6>
          </div>
          <div>
            <img
              src="assets/icons/match played.png"
              alt="match played"
              style="width: 70px"
            />
          </div>
        </div>
      </div>
      <div class="col-4" *ngIf="position != 'GK'">
        <div class="py-4 d-flex justify-content-between px-4 stats">
          <div>
            <h6 class="position-number">{{ cardData.total_gaols }}</h6>
            <h6 class="matches-played">Goals Scored</h6>
          </div>
          <div>
            <img
              src="assets/icons/goals.png"
              alt="Goals Conceded"
              style="width: 70px"
            />
          </div>
        </div>
      </div>
      <div class="col-4" *ngIf="position != 'GK'">
        <div class="py-4 d-flex justify-content-between px-4 stats">
          <div>
            <h6 class="position-number">{{ cardData.total_assets }}</h6>
            <h6 class="matches-played">Assists</h6>
          </div>
          <div>
            <img
              src="assets/icons/assists.png"
              alt="Goals Conceded"
              style="width: 70px"
            />
          </div>
        </div>
      </div>
      <div class="col-4" *ngIf="position == 'GK'">
        <div class="py-4 d-flex justify-content-between px-4 stats">
          <div>
            <h6 class="position-number">{{ cardData.goals_conceded }}</h6>
            <h6 class="matches-played">Goals Conceded</h6>
          </div>
          <div>
            <img
              src="assets/icons/Goals Conceded.png"
              alt="Goals Conceded"
              style="width: 70px"
            />
          </div>
        </div>
      </div>
      <div class="col-4" *ngIf="position == 'GK'">
        <div class="py-4 d-flex justify-content-between px-4 stats">
          <div>
            <h6 class="position-number">{{ cardData.clean_sheets }}</h6>
            <h6 class="matches-played">Clean Sheets</h6>
          </div>
          <div>
            <img
              src="assets/icons/Clean Sheets.png"
              alt="Clean Sheets"
              style="width: 70px"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-5">
    <div class="row">
      <div class="col-3 text-center">
        <div class="total-matches-container">
          <h5>Team Matches</h5>
          <h6>{{ chartData?.total_events }}</h6>
        </div>
      </div>
      <div class="col-4 m-auto">
        <div class="Matches-Participated-container">
          <h5>
            Matches Participated:
            <span>{{ chartData?.total_attendance_events }}</span>
          </h5>
          <div
            class="progress"
            role="progressbar"
            aria-label="Example with label"
            aria-valuenow="97"
            aria-valuemin="0"
            aria-valuemax="100"
            style="background-color: rgba(0, 190, 143, 0.1) !important"
          >
            <div
              class="progress-bar"
              [ngStyle]="{
                width:
                  (chartData?.total_attendance_events /
                    chartData?.total_events) *
                    100 +
                  '%'
              }"
              style="background-color: rgba(0, 190, 143, 1) !important"
            >
              {{
                (
                  (chartData?.total_attendance_events /
                    chartData?.total_events) *
                  100
                ).toFixed(2) + "%"
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-4 m-auto">
        <div class="Matches-Participated-container">
          <h5>
            Matches Missed:
            <span>{{
              chartData?.total_events - chartData?.total_attendance_events
            }}</span>
          </h5>
          <div
            class="progress"
            role="progressbar"
            aria-label="Example with label"
            aria-valuenow="97"
            aria-valuemin="0"
            aria-valuemax="100"
            style="background-color: rgba(255, 0, 0, 0.1)"
          >
            <div
              class="progress-bar"
              [ngStyle]="{
                width:
                  ((chartData?.total_events -
                    chartData?.total_attendance_events) /
                    chartData?.total_events) *
                    100 +
                  '%'
              }"
              style="background-color: rgba(255, 0, 0, 1) !important"
            >
              {{
                (
                  ((chartData?.total_events -
                    chartData?.total_attendance_events) /
                    chartData?.total_events) *
                  100
                ).toFixed(2) + "%"
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-5 pageBreakBefore">
    <h4>Match Statistics</h4>

    <table>
      <tr>
        <th>Date</th>
        <th>Opponent</th>
        <th>Tournament</th>
        <th>Attendance</th>
        <th>Score</th>
        <th *ngIf="position != 'GK'">Assists</th>
        <th *ngIf="position == 'GK'">Clean sheet</th>
        <th *ngIf="position != 'GK'">Goals</th>
        <th>Recorded By</th>
      </tr>
      <tr *ngFor="let record of tableData" class="printDesign">
        <td class="userName">{{ record?.date }}</td>
        <td class="teamName">{{ record?.opponent_team_name || "-" }}</td>
        <td class="teamName">{{ record?.tournament || "-" }}</td>
        <td class="primaryPosition">
          <div
            *ngIf="record?.attended"
            style="
              padding: 10px;
              text-align: center;
              color: white;
              background-color: rgba(113, 216, 117, 1);
              border-radius: 8px;
            "
          >
            Yes
          </div>
          <div
            *ngIf="!record?.attended"
            style="
              padding: 10px;
              text-align: center;
              color: white;
              background-color: rgb(243, 10, 22);
              border-radius: 8px;
            "
          >
            NO
          </div>
        </td>
        <td class="age">
          {{ record?.team_score }} - {{ record?.opponent_team_score }}
        </td>
        <td class="age" *ngIf="position != 'GK'">
          {{ record?.assets }}
        </td>
        <td class="gender">
          <div
            *ngIf="record?.goals_conceded == 0 && position == 'GK'"
            style="
              padding: 10px;
              text-align: center;
              color: white;
              background-color: rgba(113, 216, 117, 1);
              border-radius: 8px;
            "
          >
            YES
          </div>
          <div
            *ngIf="record?.goals_conceded != 0 && position == 'GK'"
            style="
              padding: 10px;
              text-align: center;
              color: white;
              background-color: rgb(243, 10, 22);
              border-radius: 8px;
            "
          >
            NO
          </div>
          <div
            *ngIf="position != 'GK'"
            style="padding: 10px; text-align: center"
          >
            {{ record?.goals }}
          </div>
        </td>
        <td class="attributeScore">{{ record?.recorded_by }}</td>
      </tr>
    </table>
  </div>
</div>
