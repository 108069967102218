<div class="row mb-4">
  <h6>
    <span>
      {{ teamID ? playerData[0]?.team : "Academy Reports / Parents /  " }}</span
    >

    {{ academyName }}
  </h6>
</div>
<div class="row mb-4">
  <div class="card px-4 align-items-center py-4 ml-2 flex-row">
    <div>
      <h1>{{ playerCount }}</h1>
      <h2>Total Parents</h2>
    </div>
    <img
      src="assets/icons/allParents.png"
      style="width: 100px"
      alt="couchIcon"
    />
  </div>
</div>

<div class="row">
  <h2 class="table-header-text mt-5">Academy Parents</h2>

  <table>
    <tr>
      <th>Parent name</th>
      <th>Player name</th>
      <th>Team</th>
      <th>Age</th>
      <th>Gender</th>
      <th>Contact</th>
    </tr>
    <tr *ngFor="let player of playerData">
      <td class="parentName">{{ player.parent_name }}</td>
      <td class="playerName">{{ player.username }}</td>
      <td class="team">{{ player.team }}</td>
      <td class="age">{{ player.age }}</td>
      <td class="gender">{{ player.gender }}</td>
      <td class="phone">{{ player.parent_contact }}</td>
    </tr>
  </table>
</div>
