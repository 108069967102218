<app-breadcrumb
  [breadcrumbItems]="breadcrumb"
  title="Academy Reports - {{
    academyReportType == 'select' ? '' : academyReportType
  }}"
></app-breadcrumb>

<div class="report bg-white">
  <div>
    <h1>Report type*</h1>
  </div>

  <div *ngIf="ReportType == 'Academy report'" class="academyRep mt-4">
    <div>
      <select
        name="academyReportType"
        id="academyReportType"
        [(ngModel)]="academyReportType"
      >
        <option value="select" selected disabled>Select</option>
        <option value="players">Players</option>
        <option value="Parents">Parents</option>
        <option value="Academy coaches">Academy coaches</option>
        <option value="Academy teams">Academy teams</option>
      </select>
    </div>

    <div *ngIf="academyReportType == 'players'" class="academyUsers">
      <div *ngIf="academyReportType == 'players'">
        <h1>Player filter</h1>

        <select
          name="playerFilter"
          id="playerFilter"
          [(ngModel)]="playerFilter"
          class="mt-3"
        >
          <option value="" disabled selected>Select</option>
          <option value="gender">Gender</option>
          <option value="teams">Teams</option>
          <option value="all">All</option>
        </select>
      </div>

      <div *ngIf="playerFilter == 'gender' && userType == 'players'">
        <h1>Select gender</h1>
        <mat-radio-group
          aria-label="Select an option"
          [(ngModel)]="playerGender"
          class="mt-4 d-flex flex-row gap-5"
        >
          <mat-radio-button value="female">Females</mat-radio-button>
          <mat-radio-button value="male">Males</mat-radio-button>
        </mat-radio-group>
      </div>

      <div *ngIf="playerFilter == 'teams' || userType == 'Parents'">
        <div *ngIf=""></div>
        <h1>Select team*</h1>
        <select
          name="Team"
          id="Team"
          [(ngModel)]="selectedTeam"
          (change)="importPlayers()"
        >
          <!-- <option value="" disabled selected>Team name</option> -->
          <option value="">All teams</option>
          <option *ngFor="let team of teams" [value]="team.id">
            {{ team?.name }}
          </option>
        </select>
      </div>
    </div>

    <div *ngIf="academyReportType == 'Academy coaches'" class="mt-5">
      <h1>Select coaches</h1>

      <select
        name="selectCoaches"
        id="selectCoaches"
        [(ngModel)]="selectCoaches"
        class="mt-3"
      >
        <option value="" disabled selected>Select</option>
        <option value="all">All coaches</option>
        <option *ngFor="let coach of allCoaches" [value]="coach.id">
          {{ coach?.username }}
        </option>
      </select>
    </div>

    <div
      *ngIf="
        academyReportType == 'Parents' || academyReportType == 'Academy teams'
      "
      class="academyRep mt-5"
    >
      <div>
        <h1>Select team*</h1>
        <select name="Team" id="Team" [(ngModel)]="selectedTeam" class="mt-3">
          <!-- <option value="" disabled selected>Team name</option> -->
          <option value="">All teams</option>
          <option *ngFor="let team of teams" [value]="team.id">
            {{ team?.name }}
          </option>
        </select>
      </div>
    </div>
    <div *ngIf="academyReportType == 'Academy teams'" class="mt-5">
      <h1>Team activites</h1>

      <select
        name="teamActivites"
        id="teamActivites"
        [(ngModel)]="teamActivites"
        class="mt-3"
      >
        <option value="" disabled selected>Select</option>
        <option value="training">Trainings</option>
        <option value="match">Matches</option>
      </select>
    </div>
    <div *ngIf="academyReportType == 'Academy teams'" class="date mt-5">
      <h1>Select Date*</h1>
      <div class="d-flex w-50 gap-5 mt-4">
        <div
          class="w-50 d-flex justify-content-center h-100 gap-2 align-items-center"
        >
          <p>From</p>
          <input placeholder="from" type="date" [(ngModel)]="fromTime" />
        </div>
        <div
          class="w-50 d-flex justify-content-center h-100 gap-2 align-items-center mt-0"
        >
          <p>To</p>
          <input placeholder="to" type="date" [(ngModel)]="toTime" />
        </div>
      </div>
    </div>
    <div
      *ngIf="academyReportType == 'Academy teams' && teamActivites == 'match'"
      class="mt-5"
    >
      <h1>Select Tournament<span>*</span></h1>

      <select
        name="teamActivites"
        id="teamActivites"
        class="mt-3"
        [(ngModel)]="selectedTournaments"
      >
        <option value="" selected>All Tournament</option>
        <option
          *ngFor="let tournaments of allTournaments"
          [value]="tournaments.id"
        >
          {{ tournaments?.name }}
        </option>
      </select>
    </div>
  </div>

  <div class="mt-5">
    <button (click)="viewReport()">View report</button>
  </div>

  <div class="text-center" id="error">
    <h3>Please fill required fields (*)</h3>
  </div>
</div>
