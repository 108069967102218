import { Component, Input, OnInit } from '@angular/core';
import { ChartData, ChartType } from 'chart.js';

@Component({
  selector: 'app-line-graph',
  templateUrl: './line-graph.component.html',
  styleUrls: ['./line-graph.component.scss'],
})
export class LineGraphComponent implements OnInit {
  @Input() graphData: any;

  public barChartData: ChartData<'bar'> = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    datasets: [
      {
        data: [31, 70, 40, 55],
        label: 'Creativity',
        borderColor: '#ED6425',
        backgroundColor: '#ED6425',
      },
    ],
  };

  public barChartOptions: any = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        ticks: {
          min: 0,
          stepSize: 10,
          max: 150,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public barChartType: ChartType = 'line';

  constructor() {}

  ngOnInit(): void {
    console.log(this.graphData);
    this.barChartData.labels = [];
    this.barChartData.datasets[0].data = [];

    this.graphData.evaluations.map((element: any) => {
      let tempDate = new Date(element.date);
      console.log(tempDate, 'tempDate');

      this.barChartData.labels?.push(tempDate.toLocaleDateString());
      this.barChartData.datasets[0].data?.push(element.value);
    });
  }
}
