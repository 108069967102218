<div class="row">
    <div class="col-12">
        <div class="mb-3">
            <app-period-selection-bar (periodChange)="periodChangeHandler($event)"></app-period-selection-bar>
        </div>
        <div class="d-flex flex-row flex-wrap justify-content-md-start justify-content-center align-items-center">
            <div class="col-md-5">
                <app-info-sticker [number]="160" [description]="'Monthly Subscriptions'" [iconName]="'academy'">
                </app-info-sticker>
            </div>
            <div class="col-md-4">
                <app-info-sticker [number]="20450" [description]="'Cash Collected'" [iconName]="'money'">
                </app-info-sticker>
            </div>
        </div>
    </div>
</div>

<div class="row mt-5 pt-4">
    <div class="col-12 mb-4 pb-2">
        <app-info-sticker class="mb-3" [number]="12070" [description]="'Pending Collection'" [iconName]="'waiting'">
        </app-info-sticker>
    </div>

    <div *ngFor="let card of [1,2,3,4,5]" class="col-12">
        <app-license-report-card [isActive]="card!==3"></app-license-report-card>
    </div>
</div>