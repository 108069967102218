<div class="reportCard d-flex flex-row justify-content-between align-items-stretch">
    <div class="d-flex flex-row align-items-center" [ngClass]="{'m-auto': isMyBill}">
        <div class="d-flex flex-row align-items-center" *ngIf="!isMyBill">
            <img class="clubImg" src="assets/images/pyramids-logo.png" alt="club logo" />
            <h2 class="m-0">Pyramids</h2>
        </div>
        <h2 class="m-0 px-2 text-center" *ngIf="isMyBill">ScoutX License</h2>
    </div>
    <div class="d-flex flex-row align-items-center">
        <div class="d-flex flex-row align-items-center" *ngIf="!isMyBill">
            <button class="btn btn-primary btn-reminder">Send Reminder</button>
            <div class="endDateSection d-flex flex-row align-items-center">
                <h2 class="m-0">Ends in</h2>
                <b>{{endDate|date}}</b>
            </div>
        </div>
        <div class="activityBg text-light h-100 d-flex align-items-center justify-content-center" [ngClass]="isActive? 'activeBg':'inactiveBg'">
            <span>{{isActive? 'Active':'Inactive'}}</span>
        </div>
    </div>
</div>