import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MobileEndPointsService } from 'src/app/services/mobileEndPoints/mobile-end-points.service';

@Component({
  selector: 'app-subscription-payments',
  templateUrl: './subscription-payments.component.html',
  styleUrls: ['./subscription-payments.component.scss'],
})
export class SubscriptionPaymentsComponent implements OnInit {
  // @Input() showReport!: () => void; // Define an input property for the function

  breadcrumb = [
    { label: 'Dashboard', path: '', active: false },
    { label: 'Reports', path: '#', active: false },
    { label: 'View Reports', path: '/staff', active: true },
  ];

  type: any;

  /*##########AllPlayersData##########*/
  AllPlayersMonth: any = ""
  AllPlayersYear: any = ""

  /*##########TeamsData##########*/
  selectedTeam: any;
  TeamsMonth: any = ""
  TeamsYear: any = ""

  /*##########SinglePlayerData##########*/
  selectedPlayer: any = "";
  SinglePlayerFrom: any = ""
  SinglePlayerTo: any = ""


  // selectedMonth: any;
  // selectedYear: any;
  // fromSelected: any;
  // toSelected: any;



  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  years = [2026, 2025, 2024, 2023, 2022, 2021, 2020, 2019];
  teams: any;
  players: any;

  public ReportShown: boolean = false;
  showReport(type: any) {
    this.type = type;
    this.ReportShown = !this.ReportShown;
  }

  print() {
    window.print();
  }

  back() {
    this.ReportShown = !this.ReportShown;
  }
  constructor(private _mobileAPI: MobileEndPointsService) { }

  ngOnInit(): void {
    this._mobileAPI.getTeams().subscribe((res: any) => {
      this.teams = res.results;
    });

    this._mobileAPI.getPlayers().subscribe((res: any) => {
      this.players = res.results;
    });
  }
}
