import { Component, Input, OnInit } from '@angular/core';
import { DashBordEndPointsService } from 'src/app/services/dash-bord-end-points.service';

@Component({
  selector: 'app-social-achivers',
  templateUrl: './social-achivers.component.html',
  styleUrls: ['./social-achivers.component.scss'],
})
export class SocialAchiversComponent implements OnInit {
  tableData: any;
  @Input() social: any = '';
  @Input() team: any = '';
  constructor(private _DashBordEndPointsService: DashBordEndPointsService) {}

  ngOnInit(): void {
    if (this.social == 'posts') {
      this._DashBordEndPointsService
        .getSocialAchiversPosts(this.team)
        .subscribe((res: any) => {
          this.tableData = res.results;
          console.log(this.tableData);
        });
    } else if (this.social == 'made') {
      this._DashBordEndPointsService
        .getSocialAchiversInteractionMade(this.team)
        .subscribe((res: any) => {
          this.tableData = res.results;
          console.log(this.tableData);
        });
    } else if (this.social == 'received') {
      this._DashBordEndPointsService
        .getSocialAchiversInteractionRec(this.team)
        .subscribe((res: any) => {
          this.tableData = res.results;
          console.log(this.tableData);
        });
    }
  }
}
