<div class="row">
  <div class="col-12">
    <div class="mb-3">
      <h1 class="m-0 mt-5">
        <div class="dot"></div>
        Platform Statistics
      </h1>
    </div>
    <div
      class="d-flex flex-row flex-wrap justify-content-md-start justify-content-center align-items-center"
    >
      <div class="col-md-4">
        <app-info-sticker
          [number]="160"
          [description]="'Total videos'"
          [iconName]="'video'"
        >
        </app-info-sticker>
      </div>
      <div class="col-md-4">
        <app-info-sticker
          [number]="70"
          [description]="'Total Views'"
          [iconName]="'eye'"
        >
        </app-info-sticker>
      </div>
    </div>
  </div>
</div>

<div class="row mt-3">
  <!-- <div class="col-xl-4 col-md-6 col-12">
        <app-doughunt-chart [Icon]="'click'" [title]="'Total Interactions'" [data]="[120,32,80]" [doughnutChartLabels]="['Likes', 'Shares', 'Comments']"></app-doughunt-chart>
    </div>
    <div class="col-xl-4 col-md-6 col-12">
        <app-doughunt-chart [Icon]="'ads'" [title]="'Ads Interactions'" [data]="[120,32,80]" [doughnutChartLabels]="['Likes', 'Shares', 'Comments']"></app-doughunt-chart>

    </div>
    <div class="col-xl-4 col-md-6 col-12">
        <app-doughunt-chart [Icon]="'coach'" [title]="'Total Coaches Activities'" [data]="[180,123]" [doughnutChartLabels]="['Instructions', 'Evaluations']"></app-doughunt-chart>
    </div> -->
</div>
