import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {MediaMatcher} from '@angular/cdk/layout';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'scoutX-dashboard';
  isLoginRoute!: boolean;
  mobileQuery: MediaQueryList;
  constructor(private route: Router,private media: MediaMatcher) { 
    this.mobileQuery = media.matchMedia('(max-width: 600px)')
  }

  ngOnInit() {
    this.route.events.subscribe(res => {
      if (res instanceof NavigationEnd) {
        this.isLoginRoute = res.url === "/login";
      }
    })
  }
}
