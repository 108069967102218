<div class="row">
    <div class="col-12">
        <div class="mb-3">
            <app-period-selection-bar (periodChange)="periodChangeHandler($event)"></app-period-selection-bar>
        </div>
        <div class="d-flex flex-row flex-wrap justify-content-md-start justify-content-center align-items-center">
            <div class="col-md-5">
                <app-info-sticker [number]="1600" [description]="'No. Registered Academies'" [iconName]="'academy'">
                </app-info-sticker>
            </div>
            <div class="col-md-5">
                <app-info-sticker [number]="7" [description]="'No. New Academies'" [iconName]="'newAcademy'"
                    [iconWidth]="44">
                </app-info-sticker>
            </div>
        </div>
    </div>
</div>

<div class="row my-5">
    <div class="col-12 d-flex justify-content-center align-items-center">
        <input class="input" type="text" [(ngModel)]="search" placeholder="Serach in academies">
        <button class="btn btn-primary shadow">
            <app-icon [name]="'search'" [isColored]="false" [width]="80"></app-icon>
        </button>
    </div>
</div>

<h1 class="m-0 mt-5"><div class="dot"></div>New Registered Academies</h1>
<div class="row flex-wrap justify-content-between">
    <app-academy-card [academy]="academyTemp"></app-academy-card>
    <app-academy-card [academy]="academyTemp"></app-academy-card>
    <app-academy-card [academy]="academyTemp" [isColored]="true"></app-academy-card>
    <app-academy-card [academy]="academyTemp"></app-academy-card>
    <app-academy-card [academy]="academyTemp"></app-academy-card>
</div>

<h1 class="m-0 mt-5"><div class="dot"></div>Top Active Academies</h1>
<div class="row flex-wrap justify-content-between">
    <app-academy-card [academy]="academyTemp"></app-academy-card>
    <app-academy-card [academy]="academyTemp"></app-academy-card>
    <app-academy-card [academy]="academyTemp"></app-academy-card>
    <app-academy-card [academy]="academyTemp"></app-academy-card>
    <app-academy-card [academy]="academyTemp"></app-academy-card>
</div>

<h1 class="m-0 mt-5"><div class="dot"></div>New Interactive Academies</h1>
<div class="row flex-wrap justify-content-between">
    <app-academy-card [academy]="academyTemp"></app-academy-card>
    <app-academy-card [academy]="academyTemp"></app-academy-card>
    <app-academy-card [academy]="academyTemp"></app-academy-card>
    <app-academy-card [academy]="academyTemp"></app-academy-card>
    <app-academy-card [academy]="academyTemp"></app-academy-card>
</div>

<h1 class="m-0 mt-5"><div class="dot"></div>All Academies</h1>
<div class="row flex-wrap justify-content-between">
    <app-academy-card [academy]="academyTemp"></app-academy-card>
    <app-academy-card [academy]="academyTemp"></app-academy-card>
    <app-academy-card [academy]="academyTemp"></app-academy-card>
    <app-academy-card [academy]="academyTemp"></app-academy-card>
    <app-academy-card [academy]="academyTemp"></app-academy-card>
</div>