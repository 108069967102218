<section class="d-flex mb-md-0 my-2">
    <div class="infoSticker d-flex flex-row justify-content-between align-items-start shadow-sm" [ngClass]="pageClasses">
        <div class="infoData mr-5">
            <b>{{number}}</b>
            <h1>{{description}}</h1>
        </div>
        <ng-container *ngIf="hasImage == false">
            <app-icon [name]="iconName" [isColored]="isColoredIcon" [width]="iconWidth"></app-icon>
        </ng-container>
        <ng-container *ngIf="hasImage == true">
            <img src="{{ImagePath}}" style="width: 60px; height: 60px;">
        </ng-container>
    </div>
</section>