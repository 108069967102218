<div class="row">
  <div class="col-8 py-4">
    <div class="text desktop">
      <span class="number ">{{ number }}.</span>{{ firstName }} <br>{{lastName}}<br>
      <span class="type">({{ type }})</span>
    </div>
    <div class="text mobile">
      <span class="number ">{{ number }}.</span>{{ firstName }} {{lastName}} <br>
      <span class="type">({{ type }})</span>
    </div>
    <div class="views" *ngIf="views != 0">{{ views }} Views</div>
  </div>
  <div class="col-4 p-0">
    <img [src]="userImage" alt="user image" style="object-fit: cover" class="w-100" *ngIf="!pics" />
    <img [src]="pics" alt="user image" style="object-fit: cover" class="w-100" *ngIf="pics" />
  </div>
</div>