<app-breadcrumb [breadcrumbItems]="breadcrumb" title="Teams"></app-breadcrumb>

<div class="m-5">
  <div class="card flexRow align-items-center col-6">
    <img src="assets/icons/tshirt.svg" />
    <h1>
      {{ teamStats?.girls_teams_count + teamStats?.boys_teams_count }}
      <span> Total Teams</span>
    </h1>
  </div>

  <div class="row mt-5 justify-content-between gap d-flex">
    <div class="flex1 p-0">
      <div class="card pt-1 pb-4">
        <div class="d-flex flex-row align-items-center img-container">
          <img src="assets/icons/boy.svg" />
          <h2 class="mb-0 ml-2 mt-4">Boys Teams</h2>
        </div>
        <div class="row mt-4 foot-ball">
          <div class="col-6 p-0">
            <div class="row align-items-center">
              <img src="assets/icons/ball.svg" class="smallIcon mr-2" />

              <div class="col p-0 ml-2">
                <h3>{{ teamStats?.boys_teams_count }}</h3>
                <h4>Teams</h4>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row align-items-center">
              <img src="assets/icons/foot.svg" class="smallIcon mr-2" />

              <div class="col p-0 ml-2">
                <h3>{{ teamStats?.boys_teams_players_count }}</h3>
                <h4>PLayers</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="card bg-white card-shadow mt-3"
        *ngFor="let item of teamStats?.boys_teams_players_count_dict_arr"
      >
        <div class="row justify-content-between card-width">
          <div class="col-6">
            <h5>{{ item?.name }}</h5>
          </div>
          <div class="col-6">
            <h5 class="margin-left">
              <span>{{ item?.players_count }}</span> player
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="flex1 p-0">
      <div class="card pt-1 pb-4">
        <div class="d-flex flex-row align-items-center img-container">
          <img src="assets/icons/girl.svg" />
          <h2 class="mb-0 ml-2 mt-4">Girls Teams</h2>
        </div>
        <div class="row mt-4 foot-ball">
          <div class="col-6 p-0">
            <div class="row align-items-center">
              <img src="assets/icons/ball.svg" class="smallIcon mr-2" />

              <div class="col p-0 ml-2">
                <h3>{{ teamStats?.girls_teams_count }}</h3>
                <h4>Teams</h4>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row align-items-center">
              <img src="assets/icons/foot.svg" class="smallIcon mr-2" />

              <div class="col p-0 ml-2">
                <h3>{{ teamStats?.girls_teams_players_count }}</h3>
                <h4>PLayers</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="card bg-white card-shadow mt-3"
        *ngFor="let item of teamStats?.girls_teams_players_count_dict_arr"
      >
        <div class="row justify-content-between card-width">
          <div class="col-6">
            <h5>{{ item?.name }}</h5>
          </div>
          <div class="col-6">
            <h5 class="margin-left">
              <span>{{ item?.players_count }}</span> player
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
