import { Component, Input, OnInit } from '@angular/core';
import { DashBordEndPointsService } from 'src/app/services/dash-bord-end-points.service';

@Component({
  selector: 'app-match-statistics',
  templateUrl: './match-statistics.component.html',
  styleUrls: ['./match-statistics.component.scss'],
})
export class MatchStatisticsComponent implements OnInit {
  @Input() from: any;
  @Input() to: any;
  @Input() teamId: any;
  @Input() userID: any;
  @Input() position: any;
  tableData: any;
  cardData: any;
  chartData: any;

  constructor(private _DashBordEndPointsService: DashBordEndPointsService) {
    console.log(this.position, 'positionpositionpositionposition');
  }

  ngOnInit(): void {
    this._DashBordEndPointsService
      .getPlayerMatchSummary(this.userID, this.teamId, this.from, this.to, '')
      .subscribe((res: any) => {
        this.cardData = res;
        console.log(res);
      });

    this._DashBordEndPointsService
      .getPlayerMatchAttendancesTable(
        this.userID,
        this.teamId,
        this.from,
        this.to,
        ''
      )
      .subscribe((res: any) => {
        this.tableData = res.results;
        console.log(res);
      });

    this._DashBordEndPointsService
      .getPlayerAttendancesChart(
        this.userID,
        this.teamId,
        this.from,
        this.to,
        'match',
        ''
      )
      .subscribe((res: any) => {
        this.chartData = res;
        console.log(res);
      });
  }
}
