import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-team-activities-card',
  templateUrl: './team-activities-card.component.html',
  styleUrls: ['./team-activities-card.component.scss']
})
export class TeamActivitiesCardComponent implements OnInit {
  @Input() number!: number;
  @Input() text!: string;
  @Input() image!: string;
  constructor() { }

  ngOnInit(): void {
  }

}
