<h2 class="ml-3 mb-5">
  Top achievers / Coaches /
  <span *ngIf="type == 'Instructions'">Instructions</span>
  <span *ngIf="type == 'Evaluation'">Evaluation</span>
  <span *ngIf="type == 'Posts'">Posts created </span>
  <span *ngIf="type == 'Interaction'">Interactions to posts</span>
</h2>
<div
  class="row card flex-row mb-5 overflow"
  *ngFor="let record of tableData; let i = index"
>
  <div class="indexing">{{ i + 1 }}</div>

  <div *ngIf="record.picture" class="col-3 p-0 m-0">
    <img style="height: 400px" [src]="record.picture" />
  </div>

  <div *ngIf="!record.picture" class="col-3 p-0 m-0">
    <img style="height: 400px" src="/assets/images/coachuser.jpg" />
  </div>

  <div class="col-5 mt-2 ml-3">
    <h1>{{ record.username }}</h1>
    <p>{{ record.academy_name }}</p>
    <!-- <h2>{{ record.academy_name }}</h2> -->
    <h5 class="ml-1">{{ record.age ? record.age : "38" }} Years</h5>
    <div class="d-flex flex-row gap-3 mt-1 align-items-center">
      <img
        *ngIf="type == 'Instructions'"
        style="width: 41px; height: 41px"
        src="assets/icons/Training Instructions.png"
        alt="Training Instructions"
      />
      <img
        *ngIf="type == 'Evaluation'"
        style="width: 41px; height: 41px"
        src="assets/icons/Training Instructions.png"
        alt="Training Instructions"
      />
      <img
        *ngIf="type == 'Posts'"
        style="width: 41px; height: 41px"
        src="assets/icons/Posts Created.png"
        alt="Posts Created"
      />
      <img
        *ngIf="type == 'Interaction'"
        style="width: 41px; height: 41px"
        src="assets/icons/Interactions to posts.png"
        alt="Posts Created"
      />
      <h6 *ngIf="type == 'Posts'">
        <span>{{ record.posts_count ? record.posts_count : "0" }}</span>

        Posts created
      </h6>

      <h6 *ngIf="type == 'Interaction'">
        <span>{{
          record.posts_interactions_made_count
            ? record.posts_interactions_made_count
            : "0"
        }}</span>
        Interactions to posts
      </h6>
      <h6 *ngIf="type == 'Instructions'">
        <span>{{ record.training_count ? record.training_count : "0" }}</span>

        Training Instructions
      </h6>
      <h6 *ngIf="type == 'Evaluation'">
        <span
          >{{
            record.training_evaluation_count
              ? record.training_evaluation_count
              : "0"
          }}
        </span>
        Training Evaluation
      </h6>
    </div>

    <div class="d-flex flex-row gap-3 mt-2 align-items-center">
      <img
        *ngIf="type == 'Instructions'"
        style="width: 41px; height: 41px"
        src="assets/icons/Match Instructions.png"
        alt="Match Instructions"
      />
      <img
        *ngIf="type == 'Evaluation'"
        style="width: 41px; height: 41px"
        src="assets/icons/Match Instructions.png"
        alt="Match Instructions"
      />

      <h6 *ngIf="type == 'Evaluation'">
        <span
          >{{
            record.match_evaluation_count ? record.match_evaluation_count : "0"
          }}
        </span>
        Match Evaluation
      </h6>
      <h6 *ngIf="type == 'Instructions'">
        <span>{{
          record.pre_match_instructions_count
            ? record.pre_match_instructions_count
            : "0"
        }}</span>
        Match Instructions
      </h6>
    </div>

    <div class="line"></div>

    <div
      class="d-flex flex-row gap-2 align-items-center mb-3"
      *ngFor="let team of record.managed_teams"
    >
      <img
        style="width: 16px; height: 16px"
        src="assets/icons/academyLogo.png"
        alt="academyLogo"
      />
      <h2 class="academy-text">{{ team.name }}</h2>
    </div>

    <!-- <div class="d-flex flex-row gap-2 align-items-center mb-3">
      <img
        style="width: 16px; height: 16px"
        src="assets/icons/academyLogo.png"
        alt="academyLogo"
      />
      <h2 class="academy-text">{{ record.academy_name }}</h2>
    </div>
    <div class="d-flex flex-row gap-2 align-items-center mb-3">
      <img
        style="width: 16px; height: 16px"
        src="assets/icons/academyLogo.png"
        alt="academyLogo"
      />
      <h2 class="academy-text">{{ record.academy_name }}</h2>
    </div> -->
  </div>
  <div class="col-4"></div>
</div>
